import React from 'react'
import { RangeColors } from '../../utils'

const { red, yellow, green } = RangeColors

const COLORS = {
  ENERGY_LINE: red,
  EATING_PERIOD: yellow,
  TEXT: '#4c6072',
  GRID: '#4c6072',
  BACKGROUND: 'white'
}

// Add icons for eating/wake/sleep
const ICONS = {
  WAKE: {
    path:
      'M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zM2 13h2c.55 0 1-.45 1-1s-.45-1-1-1H2c-.55 0-1 .45-1 1s.45 1 1 1zm18 0h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1zM11 2v2c0 .55.45 1 1 1s1-.45 1-1V2c0-.55-.45-1-1-1s-1 .45-1 1zm0 18v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1zM5.99 4.58c-.39-.39-1.03-.39-1.41 0-.39.39-.39 1.03 0 1.41l1.06 1.06c.39.39 1.03.39 1.41 0s.39-1.03 0-1.41L5.99 4.58zm12.37 12.37c-.39-.39-1.03-.39-1.41 0-.39.39-.39 1.03 0 1.41l1.06 1.06c.39.39 1.03.39 1.41 0 .39-.39.39-1.03 0-1.41l-1.06-1.06zm1.06-10.96c.39-.39.39-1.03 0-1.41-.39-.39-1.03-.39-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0l1.06-1.06zM7.05 18.36c.39-.39.39-1.03 0-1.41-.39-.39-1.03-.39-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0l1.06-1.06z',
    viewBox: '0 0 24 24'
  },
  SLEEP: {
    path:
      'M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9c0-.46-.04-.92-.1-1.36-.98 1.37-2.58 2.26-4.4 2.26-3.03 0-5.5-2.47-5.5-5.5 0-1.82.89-3.42 2.26-4.4-.44-.06-.9-.1-1.36-.1z',
    viewBox: '0 0 24 24'
  },
  MEAL: {
    path:
      'M11 9H9V2H7v7H5V2H3v7c0 2.12 1.66 3.84 3.75 3.97V22h2.5v-9.03C11.34 12.84 13 11.12 13 9V2h-2v7zm5-3v8h2.5v8H21V2c-2.76 0-5 2.24-5 4z',
    viewBox: '0 0 24 24'
  },
  LARGEST_MEAL: {
    path:
      'M8.1 13.34l2.83-2.83L3.91 3.5c-1.56 1.56-1.56 4.09 0 5.66l4.19 4.18zm6.78-1.81c1.53.71 3.68.21 5.27-1.38 1.91-1.91 2.28-4.65.81-6.12-1.46-1.46-4.2-1.1-6.12.81-1.59 1.59-2.09 3.74-1.38 5.27L3.7 19.87l1.41 1.41L12 14.41l6.88 6.88 1.41-1.41L13.41 13l1.47-1.47z',
    viewBox: '0 0 24 24'
  }
}

export const EnergyViz = ({ energyData }) => {
  // Basic validation for energyData object
  if (!energyData || typeof energyData !== 'object') {
    console.error('Invalid energyData:', energyData)
    return null
  }

  // Check if required values are present
  const requiredFields = [
    'wake_time',
    'sleep_time',
    'start_eating',
    'stop_eating',
    'largest_meal',
    'morning_energy',
    'energy_level',
    'midday_energy'
  ]

  const hasValidData = requiredFields.every(
    (field) =>
      energyData[field] !== null &&
      energyData[field] !== undefined &&
      energyData[field] !== ''
  )

  // Return empty graph if any required value is missing
  if (!hasValidData) {
    const width = 500
    const height = 400
    const padding = {
      left: 80,
      right: 60,
      top: 60,
      bottom: 60
    }

    // Create hour labels for x-axis
    const hourLabels = Array.from({ length: 7 }, (_, i) => {
      const hour = 4 + i * 3 // Start at 4AM, increment by 3 hours
      const period = hour >= 12 ? 'PM' : 'AM'
      const displayHour = hour > 12 ? hour - 12 : hour
      return `${displayHour}${period}`
    })

    // Create energy level labels for y-axis
    const energyLabels = ['None', 'Low', 'Medium', 'High']

    return (
      <svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="xMinYMid meet"
        style={{
          fontFamily: 'inherit'
        }}
      >
        {/* Background */}
        <rect
          x="0"
          y="0"
          width={width}
          height={height}
          fill={COLORS.BACKGROUND}
          rx="5"
          ry="5"
        />

        {/* Grid lines */}
        {hourLabels.map((_, i) => (
          <line
            key={`grid-x-${i}`}
            x1={
              padding.left +
              (i * (width - padding.left - padding.right)) /
                (hourLabels.length - 1)
            }
            y1={padding.top}
            x2={
              padding.left +
              (i * (width - padding.left - padding.right)) /
                (hourLabels.length - 1)
            }
            y2={height - padding.bottom}
            stroke={COLORS.GRID}
            strokeWidth={1}
            strokeDasharray="3 3"
            opacity={0.5}
          />
        ))}
        {energyLabels.map((_, i) => (
          <line
            key={`grid-y-${i}`}
            x1={padding.left}
            y1={padding.top + (i * (height - padding.top - padding.bottom)) / 3}
            x2={width - padding.right}
            y2={padding.top + (i * (height - padding.top - padding.bottom)) / 3}
            stroke={COLORS.GRID}
            strokeWidth={1}
            strokeDasharray="3 3"
            opacity={0.5}
          />
        ))}

        {/* Axes */}
        <line
          x1={padding.left}
          y1={height - padding.bottom}
          x2={width - padding.right}
          y2={height - padding.bottom}
          stroke={COLORS.TEXT}
          strokeWidth={1}
        />
        <line
          x1={padding.left}
          y1={padding.top}
          x2={padding.left}
          y2={height - padding.bottom}
          stroke={COLORS.TEXT}
          strokeWidth={1}
        />

        {/* X-axis labels */}
        {hourLabels.map((label, i) => (
          <text
            key={`x-${i}`}
            x={
              padding.left +
              (i * (width - padding.left - padding.right)) /
                (hourLabels.length - 1)
            }
            y={height - padding.bottom + 20}
            textAnchor="middle"
            fill={COLORS.TEXT}
            fontSize="12"
          >
            {label}
          </text>
        ))}

        {/* Y-axis labels */}
        {energyLabels.map((label, i) => (
          <text
            key={`y-${i}`}
            x={padding.left - 10}
            y={
              height -
              padding.bottom -
              (i * (height - padding.top - padding.bottom)) / 3
            }
            textAnchor="end"
            alignmentBaseline="middle"
            fill={COLORS.TEXT}
            fontSize="12"
          >
            {label}
          </text>
        ))}

        {/* Title */}
        <text
          x={width / 2}
          y={padding.top / 2}
          textAnchor="middle"
          fill={COLORS.TEXT}
          fontSize="16"
          fontWeight="500"
          style={{ fontFamily: 'inherit' }}
        >
          Daily Energy
        </text>
      </svg>
    )
  }

  const width = 500
  const height = 400
  const padding = {
    left: 80,
    right: 60,
    top: 60,
    bottom: 60
  }

  // Convert time string to hours (decimal)
  const timeToHours = (timeStr) => {
    if (!timeStr) return 0
    timeStr = String(timeStr)

    let parts = timeStr.split(' ')
    if (parts.length !== 2) return 0

    let time = parts[0]
    let period = parts[1]

    let [hours, minutes] = time.split(':')
    hours = parseInt(hours, 10)
    minutes = parseInt(minutes, 10)

    if (isNaN(hours) || isNaN(minutes)) return 0

    // Convert to 24-hour format first
    let totalHours = hours + minutes / 60

    if (period === 'PM' && hours !== 12) {
      totalHours += 12
    } else if (period === 'AM' && hours === 12) {
      totalHours = minutes / 60
    }

    // Handle wrapping for early morning hours
    if (totalHours < 4) {
      totalHours += 24
    }

    return totalHours
  }

  // First, create a consistent time normalization function
  const normalizeHour = (hour) => {
    // Normalize to 4AM-4AM window
    // If hour is before 4AM, add 24 to put it in the next day
    return hour < 4 ? hour + 24 : hour
  }

  // Single source of truth for time scaling
  const scaleTime = (timeStr) => {
    const hour = timeToHours(timeStr)

    // Scale from 4AM (4) to 10PM (22)
    const startHour = 4 // 4AM
    const endHour = 22 // 10PM
    const timeRange = endHour - startHour

    return (
      padding.left +
      ((hour - startHour) / timeRange) * (width - padding.left - padding.right)
    )
  }

  // Update getKeyPoints to use the same normalization
  const getKeyPoints = () => {
    const wakeHour = normalizeHour(timeToHours(energyData.wake_time))
    const startEatingHour = normalizeHour(timeToHours(energyData.start_eating))
    const largestMealHour = normalizeHour(timeToHours(energyData.largest_meal))
    const stopEatingHour = normalizeHour(timeToHours(energyData.stop_eating))
    const sleepHour = normalizeHour(timeToHours(energyData.sleep_time))

    const points = [
      { hour: Math.min(4, wakeHour), energy: 0 },
      { hour: wakeHour, energy: 0 },
      { hour: wakeHour + 1.5, energy: Number(energyData.morning_energy) },
      {
        hour: startEatingHour,
        energy: Number(energyData.morning_energy) * 0.9
      },
      { hour: startEatingHour + 1, energy: Number(energyData.energy_level) },
      { hour: largestMealHour, energy: Number(energyData.energy_level) },
      { hour: largestMealHour + 1, energy: Number(energyData.midday_energy) },
      { hour: stopEatingHour, energy: Number(energyData.energy_level) * 0.8 },
      { hour: sleepHour - 1, energy: Number(energyData.energy_level) * 0.5 },
      { hour: sleepHour, energy: 0 },
      { hour: Math.min(sleepHour + 1, 28), energy: 0 }
    ]

    return points.sort((a, b) => a.hour - b.hour)
  }

  // Replace the createPath function with this version
  const createPath = () => {
    // Find the earliest and latest hours in the data
    const points = getKeyPoints()
    const earliestHour = Math.min(...points.map((p) => p.hour))
    const latestHour = Math.max(...points.map((p) => p.hour))
    const timeRange = latestHour - earliestHour

    const scaleX = (hour) => {
      // Scale based on the actual time range rather than fixed 24 hours
      return (
        padding.left +
        ((hour - earliestHour) / timeRange) *
          (width - padding.left - padding.right)
      )
    }

    const scaleY = (energy) =>
      height -
      padding.bottom -
      (energy / 3) * (height - padding.top - padding.bottom)

    // Create path using the sorted points
    return points.reduce((path, point, i) => {
      if (i === 0) return `M ${scaleX(point.hour)},${scaleY(point.energy)}`

      const prev = points[i - 1]
      const curr = point

      // Calculate control points for smooth curve
      const cp1x = scaleX(prev.hour + (curr.hour - prev.hour) / 3)
      const cp1y = scaleY(prev.energy)
      const cp2x = scaleX(curr.hour - (curr.hour - prev.hour) / 3)
      const cp2y = scaleY(curr.energy)

      return `${path} C ${cp1x},${cp1y} ${cp2x},${cp2y} ${scaleX(
        curr.hour
      )},${scaleY(curr.energy)}`
    }, '')
  }

  // Update title component
  const title = (
    <text
      x={width / 2}
      y={padding.top / 2}
      textAnchor="middle"
      fill={COLORS.TEXT}
      fontSize="16"
      fontWeight="500"
      style={{ fontFamily: 'inherit' }}
    >
      Daily Energy
    </text>
  )

  // Create hour labels for x-axis
  const hourLabels = Array.from({ length: 7 }, (_, i) => {
    const hour = 4 + i * 3 // Start at 4AM, increment by 3 hours
    const period = hour >= 12 ? 'PM' : 'AM'
    const displayHour = hour > 12 ? hour - 12 : hour
    return `${displayHour}${period}`
  })

  // Create energy level labels for y-axis
  const energyLabels = ['None', 'Low', 'Medium', 'High']

  // Add helper function for icon rendering
  const renderIcon = (icon, x, y, size = 24) => (
    <g transform={`translate(${x - size / 2},${y - size / 2})`}>
      <path
        d={icon.path}
        fill={COLORS.TEXT}
        transform={`scale(${size / 24})`}
      />
    </g>
  )

  return (
    <svg
      width="100%"
      height="100%"
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="xMinYMid meet"
      style={{
        fontFamily: 'inherit'
      }}
    >
      {/* Update background */}
      <rect
        x="0"
        y="0"
        width={width}
        height={height}
        fill={COLORS.BACKGROUND}
        rx="5"
        ry="5"
      />

      {/* Grid lines */}
      {hourLabels.map((_, i) => (
        <line
          key={`grid-x-${i}`}
          x1={
            padding.left +
            (i * (width - padding.left - padding.right)) /
              (hourLabels.length - 1)
          }
          y1={padding.top}
          x2={
            padding.left +
            (i * (width - padding.left - padding.right)) /
              (hourLabels.length - 1)
          }
          y2={height - padding.bottom}
          stroke={COLORS.GRID}
          strokeWidth={1}
          strokeDasharray="3 3"
          opacity={0.5}
        />
      ))}
      {energyLabels.map((_, i) => (
        <line
          key={`grid-y-${i}`}
          x1={padding.left}
          y1={padding.top + (i * (height - padding.top - padding.bottom)) / 3}
          x2={width - padding.right}
          y2={padding.top + (i * (height - padding.top - padding.bottom)) / 3}
          stroke={COLORS.GRID}
          strokeWidth={1}
          strokeDasharray="3 3"
          opacity={0.5}
        />
      ))}

      {/* Axes */}
      <line
        x1={padding.left}
        y1={height - padding.bottom}
        x2={width - padding.right}
        y2={height - padding.bottom}
        stroke={COLORS.TEXT}
        strokeWidth={1}
      />
      <line
        x1={padding.left}
        y1={padding.top}
        x2={padding.left}
        y2={height - padding.bottom}
        stroke={COLORS.TEXT}
        strokeWidth={1}
      />

      {/* X-axis labels */}
      {hourLabels.map((label, i) => (
        <text
          key={`x-${i}`}
          x={
            padding.left +
            (i * (width - padding.left - padding.right)) /
              (hourLabels.length - 1)
          }
          y={height - padding.bottom + 20}
          textAnchor="middle"
          fill={COLORS.TEXT}
          fontSize="12"
        >
          {label}
        </text>
      ))}

      {/* Y-axis labels */}
      {energyLabels.map((label, i) => (
        <text
          key={`y-${i}`}
          x={padding.left - 10}
          y={
            height -
            padding.bottom -
            (i * (height - padding.top - padding.bottom)) / 3
          }
          textAnchor="end"
          alignmentBaseline="middle"
          fill={COLORS.TEXT}
          fontSize="12"
        >
          {label}
        </text>
      ))}

      {/* Eating period */}
      <rect
        x={scaleTime(energyData.start_eating)}
        y={padding.top}
        width={
          scaleTime(energyData.stop_eating) - scaleTime(energyData.start_eating)
        }
        height={height - padding.top - padding.bottom}
        fill={COLORS.EATING_PERIOD}
        fillOpacity={0.1}
      />

      {/* Largest meal line */}
      <line
        x1={scaleTime(energyData.largest_meal)}
        y1={padding.top}
        x2={scaleTime(energyData.largest_meal)}
        y2={height - padding.bottom}
        stroke={COLORS.EATING_PERIOD}
        strokeWidth={1}
        strokeDasharray="3 3"
      />

      {/* Energy line */}
      <path
        d={createPath()}
        fill="none"
        stroke={COLORS.ENERGY_LINE}
        strokeWidth={2}
      />

      {/* Icon positioning logic - now rendered after the energy line */}
      {/* Wake time */}
      <g
        transform={`translate(
        ${scaleTime(energyData.wake_time)},
        ${height - padding.bottom - 80}
      )`}
      >
        {renderIcon(ICONS.WAKE, 0, 0)}
        <text
          x={0}
          y={-20}
          textAnchor="middle"
          fill={COLORS.TEXT}
          fontSize="12"
        >
          {energyData.wake_time}
        </text>
      </g>

      {/* Sleep time */}
      <g
        transform={`translate(
        ${scaleTime(energyData.sleep_time)},
        ${height - padding.bottom - 80}
      )`}
      >
        {renderIcon(ICONS.SLEEP, 0, 0)}
        <text
          x={0}
          y={-20}
          textAnchor="middle"
          fill={COLORS.TEXT}
          fontSize="12"
        >
          {energyData.sleep_time}
        </text>
      </g>

      {/* Start eating */}
      <g
        transform={`translate(
        ${scaleTime(energyData.start_eating)},
        ${padding.top + 40}
      )`}
      >
        {renderIcon(ICONS.MEAL, 0, 0)}
        <text x={0} y={30} textAnchor="middle" fill={COLORS.TEXT} fontSize="12">
          {energyData.start_eating}
        </text>
      </g>

      {/* Stop eating */}
      <g
        transform={`translate(
        ${scaleTime(energyData.stop_eating)},
        ${padding.top + 80}
      )`}
      >
        {renderIcon(ICONS.MEAL, 0, 0)}
        <text x={0} y={30} textAnchor="middle" fill={COLORS.TEXT} fontSize="12">
          {energyData.stop_eating}
        </text>
      </g>

      {/* Largest meal */}
      <g
        transform={`translate(
        ${scaleTime(energyData.largest_meal)},
        ${
          Math.abs(
            timeToHours(energyData.largest_meal) -
              timeToHours(energyData.stop_eating)
          ) < 2
            ? height - padding.bottom - 80
            : padding.top + 40
        }
      )`}
      >
        {renderIcon(ICONS.LARGEST_MEAL, 0, 0)}
        <text
          x={0}
          y={
            Math.abs(
              timeToHours(energyData.largest_meal) -
                timeToHours(energyData.stop_eating)
            ) < 2
              ? -20
              : 30
          }
          textAnchor="middle"
          fill={COLORS.TEXT}
          fontSize="12"
        >
          {energyData.largest_meal}
        </text>
      </g>

      {/* Add title */}
      {title}
    </svg>
  )
}
