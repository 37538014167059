import React from 'react'
import { RangeColors } from '../../utils'

const COLORS = {
  BAR_BACKGROUND: '#f0f0f0', // Light grey for bars
  MARKER_IN_RANGE: RangeColors.green,
  MARKER_CLOSE: RangeColors.yellow,
  MARKER_FAR: RangeColors.red,
  TEXT: '#4c6072', // Consistent text color
  BACKGROUND: 'white'
}

export const NutritionViz = ({
  type,
  nutrition,
  nutrition_quality,
  nutrition_quality_ideal,
  beverages,
  beverages_ideal,
  height
}) => {
  const width = type === 'food' ? 800 : 500

  // Adjust default heights
  const defaultHeight = type === 'food' ? 800 : 350 // Reduced heights
  const svgHeight = height || defaultHeight

  const padding = {
    top: 60,
    right: type === 'quality' ? 85 : 60,
    bottom: 40,
    left: type === 'food' ? 120 : 80 // Increased left padding for food type to accommodate longer labels
  }

  // Adjust bar spacing for food type
  const barHeight = type === 'food' ? 20 : 15 // Increased bar height for food
  const barSpacing = type === 'food' ? 45 : 55 // Adjusted spacing
  const graphWidth = width - padding.left - padding.right

  let items = []
  let scale
  let title = ''

  switch (type) {
    case 'food':
      items = Object.entries(nutrition.servings).sort((a, b) => b[1] - a[1])
      const maxFoodValue = Math.max(
        ...items.map(([key, value]) => {
          const ideal = nutrition.servings_ideal[key]
          return Math.max(value, ideal[1])
        })
      )
      scale = (value) => (graphWidth * value) / maxFoodValue
      title = 'Food Servings'
      break

    case 'beverages':
      items = Object.entries(beverages || {}).sort((a, b) => b[1] - a[1])
      const maxBeverageValue = Math.max(
        ...items.map(([key, value]) => {
          const idealValues = beverages_ideal && beverages_ideal[key] ? beverages_ideal[key] : [0, value];
          const [_, idealMax] = idealValues;
          return Math.max(value, idealMax);
        }),
        1  // Fallback minimum to prevent errors with empty arrays
      )
      scale = (value) => (graphWidth * value) / maxBeverageValue
      title = 'Beverages'
      break

    case 'quality':
      items = Object.entries(nutrition_quality).sort((a, b) => b[1] - a[1])
      scale = (value) => (graphWidth * value) / 100 // Quality metrics are percentages
      title = 'Quality Metrics'
      break
  }

  // Helper function to convert to title case
  const toTitleCase = (str) => {
    return str
      .split(/[_\s]+/) // Split on both underscores and spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
      .replace(/\s+%$/, '%') // Preserve % symbol without space
  }

  // Update legend items to show status colors
  const renderLegend = (items) => (
    <g transform={`translate(${width / 2}, ${padding.top - 30})`}>
      {[
        { color: COLORS.MARKER_FAR, label: 'Out of Range' },
        { color: COLORS.MARKER_CLOSE, label: 'Worrisome' },
        { color: COLORS.MARKER_IN_RANGE, label: 'Optimal' }
      ].map((item, i) => {
        const totalWidth = 3 * 100 // 3 items, 100px each
        const startX = -totalWidth / 2
        const xOffset = startX + i * 100

        return (
          <g key={item.label} transform={`translate(${xOffset}, 0)`}>
            <rect width="10" height="10" fill={item.color} rx="2" />
            <text
              x="15"
              y="8"
              fill={COLORS.TEXT}
              fontSize="10"
              style={{ fontFamily: 'inherit' }}
            >
              {item.label}
            </text>
          </g>
        )
      })}
    </g>
  )

  const MIN_BAR_WIDTH = 2 // Width for zero-value bars

  // Helper function to wrap text based on character length
  const wrapText = (text) => {
    const MAX_CHARS_PER_LINE = 12 // Adjust this value as needed
    const words = text.split(' ')
    let currentLine = words[0]
    const lines = []

    for (let i = 1; i < words.length; i++) {
      const word = words[i]
      if ((currentLine + ' ' + word).length <= MAX_CHARS_PER_LINE) {
        currentLine += ' ' + word
      } else {
        lines.push(currentLine)
        currentLine = word
      }
    }
    lines.push(currentLine)

    return lines
  }

  // Modified scale function to handle zero values
  const getBarWidth = (value) => {
    if (value === 0) return MIN_BAR_WIDTH
    return scale(value)
  }

  // Modified getCurrentRange function to standardize thickness across all types
  const getCurrentRange = (value, type) => {
    switch (type) {
      case 'food':
        return [Math.max(0, value - 0.1), value + 0.1]
      case 'beverages':
        return [Math.max(0, value - 0.1), value + 0.1] // Changed from 0.5
      case 'quality':
        return [Math.max(0, value - 1), value + 1]
    }
  }

  // Update text rendering to use marker color
  const renderValueText = (value, idealMin, idealMax, type, yPosition) => {
    const markerColor = getMarkerColor(value, idealMin, idealMax, type)
    return (
      <text
        x={padding.left + scale(idealMax) + 5}
        y={yPosition + barHeight / 2}
        fill={markerColor}
        fontSize="10"
        alignmentBaseline="middle"
        style={{
          fontFamily: 'inherit',
          fontWeight: '600'
        }}
      >
        <tspan fill={markerColor}>{value}</tspan>
        <tspan fill={COLORS.TEXT}>
          {' '}
          ({idealMin}-{idealMax})
        </tspan>
      </text>
    )
  }

  // 2. Let's improve the marker color logic:
  const getMarkerColor = (value, idealMin, idealMax, type) => {
    // Special handling for zero values
    if (value === 0 && idealMin > 0) {
      return COLORS.MARKER_FAR // If they should be doing something but aren't
    }

    // Check if value is within range
    if (value >= idealMin && value <= idealMax) {
      return COLORS.MARKER_IN_RANGE
    }

    // Calculate how far the value is from the nearest boundary
    const distanceFromRange =
      value < idealMin ? idealMin - value : value - idealMax

    // For percentage-based metrics (quality)
    if (type === 'quality') {
      // Within 10% of either boundary
      if (
        (value < idealMin && value >= idealMin * 0.9) ||
        (value > idealMax && value <= idealMax * 1.1)
      ) {
        return COLORS.MARKER_CLOSE
      }
      return COLORS.MARKER_FAR
    }

    // For food servings and beverages (absolute numbers)
    const rangeSize = idealMax - idealMin

    // If value is below range
    if (value < idealMin) {
      // Within 25% below the minimum
      if (value >= idealMin - rangeSize * 0.25) {
        return COLORS.MARKER_CLOSE
      }
      return COLORS.MARKER_FAR
    }

    // If value is above range
    if (value > idealMax) {
      // Within 25% above the maximum
      if (value <= idealMax + rangeSize * 0.25) {
        return COLORS.MARKER_CLOSE
      }
      return COLORS.MARKER_FAR
    }

    return COLORS.MARKER_FAR // Fallback
  }

  return (
    <div
      style={{
        background: COLORS.BACKGROUND,
        borderRadius: '5px',
        padding: '8px',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        fontFamily: 'inherit',
        position: 'relative',
        minHeight: type === 'food' ? '650px' : '350px'
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${width} ${svgHeight}`}
        preserveAspectRatio="xMinYMin meet"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }}
      >
        {/* Title and Legend */}
        <text
          x={width / 2}
          y={padding.top - 45}
          textAnchor="middle"
          fill={COLORS.TEXT}
          fontSize="16"
          style={{ fontFamily: 'inherit' }}
        >
          {title}
        </text>

        {/* Update legend text color */}
        {renderLegend([
          {
            color:
              type === 'food'
                ? RangeColors.green
                : type === 'beverages'
                ? RangeColors.yellow
                : RangeColors.red,
            label: 'Current'
          },
          {
            color:
              type === 'food'
                ? RangeColors.green_dark
                : type === 'beverages'
                ? RangeColors.yellow_dark
                : RangeColors.red_dark,
            label: 'Ideal Range'
          }
        ])}

        {/* Bars */}
        {items.map(([key, value], index) => {
          const y = padding.top + index * barSpacing
          const wrappedLabel = wrapText(
            toTitleCase(key.replace('percentage', ' %'))
          )
          const [currentMin, currentMax] = getCurrentRange(value, type)

          if (type === 'food') {
            const [idealMin, idealMax] = nutrition.servings_ideal[key]
            const markerColor = getMarkerColor(
              value,
              idealMin,
              idealMax,
              'food'
            )
            return (
              <g key={`food-${key}`}>
                {wrappedLabel.map((line, i) => (
                  <text
                    key={i}
                    x={padding.left - 15}
                    y={
                      y + barHeight / 2 - (wrappedLabel.length - 1) * 6 + i * 12
                    }
                    textAnchor="end"
                    fill="black"
                    fontSize="13"
                    alignmentBaseline="middle"
                    style={{
                      fontFamily: 'inherit',
                      fontWeight: '500'
                    }}
                  >
                    {line}
                  </text>
                ))}
                <rect
                  x={padding.left + scale(idealMin)}
                  y={y}
                  width={getBarWidth(idealMax - idealMin)}
                  height={barHeight}
                  fill={COLORS.BAR_BACKGROUND}
                  rx="2"
                />
                <rect
                  x={padding.left + scale(currentMin)}
                  y={y}
                  width={getBarWidth(currentMax - currentMin)}
                  height={barHeight}
                  fill={markerColor}
                  opacity="1.0"
                  rx="2"
                />
                {renderValueText(value, idealMin, idealMax, 'food', y)}
              </g>
            )
          } else if (type === 'beverages') {
            const idealValues = beverages_ideal && beverages_ideal[key] ? beverages_ideal[key] : [0, value];
            const [idealMin, idealMax] = idealValues;
            const markerColor = getMarkerColor(
              value,
              idealMin,
              idealMax,
              'beverages'
            )
            return (
              <g key={`beverage-${key}`}>
                {wrappedLabel.map((line, i) => (
                  <text
                    key={i}
                    x={padding.left - 15}
                    y={
                      y + barHeight / 2 - (wrappedLabel.length - 1) * 6 + i * 12
                    }
                    textAnchor="end"
                    fill="black"
                    fontSize="12"
                    alignmentBaseline="middle"
                    style={{
                      fontFamily: 'inherit',
                      fontWeight: '500'
                    }}
                  >
                    {line}
                  </text>
                ))}
                <rect
                  x={padding.left + scale(idealMin)}
                  y={y}
                  width={getBarWidth(idealMax - idealMin)}
                  height={barHeight}
                  fill={COLORS.BAR_BACKGROUND}
                  rx="2"
                />
                <rect
                  x={padding.left + scale(currentMin)}
                  y={y}
                  width={getBarWidth(currentMax - currentMin)}
                  height={barHeight}
                  fill={markerColor}
                  opacity="1.0"
                  rx="2"
                />
                {renderValueText(value, idealMin, idealMax, 'beverages', y)}
              </g>
            )
          } else {
            const [idealMin, idealMax] = nutrition_quality_ideal[key]
            const markerColor = getMarkerColor(
              value,
              idealMin,
              idealMax,
              'quality'
            )
            return (
              <g key={`quality-${key}`}>
                {wrappedLabel.map((line, i) => (
                  <text
                    key={i}
                    x={padding.left - 15}
                    y={
                      y + barHeight / 2 - (wrappedLabel.length - 1) * 6 + i * 12
                    }
                    textAnchor="end"
                    fill="black"
                    fontSize="12"
                    alignmentBaseline="middle"
                    style={{
                      fontFamily: 'inherit',
                      fontWeight: '500'
                    }}
                  >
                    {line}
                  </text>
                ))}
                <rect
                  x={padding.left + scale(idealMin)}
                  y={y}
                  width={getBarWidth(idealMax - idealMin)}
                  height={barHeight}
                  fill={COLORS.BAR_BACKGROUND}
                  rx="2"
                />
                <rect
                  x={padding.left + scale(currentMin)}
                  y={y}
                  width={getBarWidth(currentMax - currentMin)}
                  height={barHeight}
                  fill={markerColor}
                  opacity="1.0"
                  rx="2"
                />
                {renderValueText(value, idealMin, idealMax, 'quality', y)}
              </g>
            )
          }
        })}
      </svg>
    </div>
  )
}
