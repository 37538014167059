import React from 'react'
import { PHSHeader } from '../../components/UI/header'
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min'
import NoMatch from '../noMatch'
import { HealthySignalsBooster } from './healthy-signals-booster'

export default function HealthySignalsBoosterView() {
    return (
        <div>
            <PHSHeader
                hideBreadCrumb={true}
                hideSubHeader={true}
            />
            <div className="client-content">
                <Switch>
                    <Route
                        exact
                        path={''}
                        render={(props) => (
                            <HealthySignalsBooster />
                        )}
                    />
                    <Route component={NoMatch} />
                </Switch>
            </div>
        </div>
    )
}
