import React, { useState, useEffect } from 'react'

// Move CalendarCarousel outside of ExerciseScheduler
export const CalendarCarousel = ({ children, onIntensityChange }) => {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [slideDirection, setSlideDirection] = useState('')
    const intensityLevels = ['Light', 'Moderate', 'Vigorous']

    useEffect(() => {
        if (slideDirection) {
            const timer = setTimeout(() => {
                setSlideDirection('')
            }, 300)
            return () => clearTimeout(timer)
        }
    }, [slideDirection])

    const handlePrevious = () => {
        setSlideDirection('slide-right')
        setCurrentIndex(current => {
            const newIndex = current === 0 ? intensityLevels.length - 1 : current - 1
            onIntensityChange(intensityLevels[newIndex].toLowerCase())
            return newIndex
        })
    }

    const handleNext = () => {
        setSlideDirection('slide-left')
        setCurrentIndex(current => {
            const newIndex = current === intensityLevels.length - 1 ? 0 : current + 1
            onIntensityChange(intensityLevels[newIndex].toLowerCase())
            return newIndex
        })
    }

    return (
        <div className="calendar-carousel">
            <button
                className="carousel-button previous"
                onClick={handlePrevious}
            >
                ←
            </button>

            <div className="carousel-content-wrapper">
                <div className={`carousel-content ${slideDirection}`}>
                    <h3 className="intensity-title">{intensityLevels[currentIndex]}</h3>
                    {children[currentIndex]}
                </div>
            </div>

            <button
                className="carousel-button next"
                onClick={handleNext}
            >
                →
            </button>
        </div>
    )
}
