import axios from './axios'

export const getExerciseSignals = async (personId, start_date, end_date, response_type) => {
  let url = `/hsd-service/exercise_signals_data?person_id=${personId}&start_date=${start_date}&end_date=${end_date}&response_type=${response_type}`
  if (personId) {
    const response = await axios.get(url)
    return response_type === 'csv' ? response : response.exercise_signals_data
  }
  return []
}

export const getSleepSignals = async (personId, start_date, end_date, response_type, source) => {
  let url = `/hsd-service/sleep_data?person_id=${personId}&start_date=${start_date}&end_date=${end_date}&response_type=${response_type}&source=${source}`
  if (personId) {
    const response = await axios.get(url)
    return response_type === 'csv' ? response : response.sleep_data
  }
  return []
}

export const getHealthySignals = async (personId, start_date, end_date, response_type, source) => {
  let url = `/hsd-service/healthy_signals_data?person_id=${personId}&start_date=${start_date}&end_date=${end_date}&response_type=${response_type}&source=${source}`
  if (personId) {
    const response = await axios.get(url)
    return response_type === 'csv' ? response : response.healthy_signals_data
  }
  return []
}
