import React from 'react'
import { StyledSection } from '../StyledSections'

const GoalsSection = ({ goals }) => {
    return (
        <StyledSection title="Goals" className="half-height">
            {goals && goals.primary_goal && (
                <div className="goal-item">
                    <strong>Primary:</strong> {goals.primary_goal}
                </div>
            )}
            {goals && goals.secondary_goal && (
                <div className="goal-item">
                    <strong>Secondary:</strong> {goals.secondary_goal}
                </div>
            )}
            {goals && goals.tertiary_goal && (
                <div className="goal-item">
                    <strong>Tertiary:</strong> {goals.tertiary_goal}
                </div>
            )}
        </StyledSection>
    )
}

export default GoalsSection 
