import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { CaretDownOutlined } from '@ant-design/icons'
import { Modal, Button } from 'antd'
import BasicForm from '../../formV4'
import { updateAdaptiveRecommendation } from '../../../api/adaptiveCoach'
import style from '../style/programEditor.module.sass'

// Modified form specifically for AI workouts
const formData = [
  {
    type: 'textarea',
    label: 'description',
    dbField: 'description',
    extraAttr: {
      row: 8
    }
  },
  {
    type: 'select',
    label: 'category',
    dbField: 'category',
    colSpan: 12,
    rules: [{ required: true, message: 'This field is required' }],
    options: [
      { label: 'Aerobic', value: 'Aerobic' },
      { label: 'Resistance', value: 'Resistance' },
      { label: 'Activity', value: 'Activity' },
      { label: 'Sleep', value: 'Sleep' }
    ],
    extraAttr: {
      suffixIcon: (
        <CaretDownOutlined
          style={{ color: '#3F3F3F', pointerEvents: 'none' }}
        />
      )
    }
  },
  {
    type: 'input',
    label: 'workout type',
    dbField: 'workout_type',
    colSpan: 12,
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'select',
    label: 'duration',
    dbField: 'duration',
    suffix: <span style={{ color: '#8D99A5' }}>min</span>,
    colSpan: 12,
    rules: [{ required: true, message: 'This field is required' }],
    extraAttr: {
      suffixIcon: (
        <CaretDownOutlined
          style={{ color: '#3F3F3F', pointerEvents: 'none' }}
        />
      )
    }
  },
  {
    type: 'select',
    label: 'intensity',
    dbField: 'intensity',
    options: [
      { label: 'Low', value: 'Low' },
      { label: 'Moderate', value: 'Moderate' },
      { label: 'High', value: 'High' },
      { label: 'Very High', value: 'Very High' }
    ],
    colSpan: 12,
    rules: [{ required: true, message: 'This field is required' }],
    extraAttr: {
      suffixIcon: (
        <CaretDownOutlined
          style={{ color: '#3F3F3F', pointerEvents: 'none' }}
        />
      )
    }
  },
  {
    type: 'checkbox',
    label: 'program item',
    dbField: 'isProgramItem',
    colSpan: 24
  }
]

class AiWorkoutEditor extends Component {
  static propTypes = {
    confirmCallback: PropTypes.func,
    exercise: PropTypes.object,
    personId: PropTypes.number,
    position: PropTypes.string,
    aiWorkoutsDate: PropTypes.string,
    cancel: PropTypes.func
  }

  state = { isSaving: false, formData }

  handleCancel = () => {
    const { cancel } = this.props
    cancel()
  }

  saveItem = async () => {
    const { personId, confirmCallback, aiWorkoutsDate } = this.props
    const { exercise, originalRec } = this.state
    this.setState({ isSaving: true })

    try {
      // Get the current form values
      const formValues = await this.basicFormRef.handleSubmit()
      
      // Always make sure isSleepActivity matches the category
      formValues.isSleepActivity = formValues.category === 'Sleep'
      
      // If category is Sleep and intensity is not set, default to Low
      if (formValues.category === 'Sleep' && !formValues.intensity) {
        formValues.intensity = 'Low'
      }
      
      // For AI recommendations, map it back to the backend format
      const updatedAiRec = {
        ...originalRec,
        workout_type: formValues.workout_type,
        category: formValues.category,
        duration: formValues.duration,
        intensity: formValues.intensity,
        description: formValues.description,
        isSleepActivity: formValues.isSleepActivity,
        isProgramItem: formValues.isProgramItem
      }

      const result = await updateAdaptiveRecommendation(
        personId,
        aiWorkoutsDate,
        originalRec.id, 
        updatedAiRec
      )
      
      // Create a proper merged object that maintains all fields needed by ItemDetail
      const updatedItem = result.recommendation || {
        ...originalRec,
        category: formValues.category,
        workout_type: formValues.workout_type,
        duration: formValues.duration,
        intensity: formValues.intensity,
        description: formValues.description,
        isSleepActivity: formValues.isSleepActivity,
        isProgramItem: formValues.isProgramItem,
        isAiRecommendation: true
      }
      
      this.setState({ isSaving: false })
      // Pass the updated item back to the parent component
      confirmCallback && confirmCallback(updatedItem)
    } catch (err) {
      console.error(err)
      this.setState({ isSaving: false })
    }
  }

  onFormChange = (changedValues, allValues) => {
    // If category changes to Sleep, set intensity to Low if not already set
    if ('category' in changedValues && changedValues.category === 'Sleep' && !allValues.intensity) {
      allValues.intensity = 'Low'
      
      // Update the form field
      if (this.basicFormRef) {
        this.basicFormRef.setFieldsValue({ 
          intensity: 'Low' 
        })
      }
    }
    
    this.setState({ exercise: allValues })
  }

  componentDidMount() {
    const { itemDurationMapping } = this.props
    let exercise = this.props.exercise ? this.props.exercise : {}
    exercise = _.cloneDeep(exercise)
    const originalRec = _.cloneDeep(exercise)
    
    // Convert workout_type for AI recommendations
    if (exercise.isAiRecommendation) {
      // Map fields based on the AI recommendation format
      exercise.description = exercise.description || exercise.rationale
    }
    
    if (itemDurationMapping) {
      formData.find(
        (item) => item.label === 'duration'
      ).options = itemDurationMapping
    }
    
    // Check if the category is valid
    const validCategories = ['Aerobic', 'Resistance', 'Activity', 'Sleep']
    if (exercise.category && !validCategories.includes(exercise.category)) {
      exercise.category = 'Activity' // Default to Activity if not valid
    }
    
    // If category is Sleep and intensity is not set, default to Low
    if (exercise.category === 'Sleep' && !exercise.intensity) {
      exercise.intensity = 'Low'
    }
    this.setState({ exercise, originalRec })
  }

  render() {
    const { exercise } = this.state || this.props
    const { formData, isSaving } = this.state
    const valueForm = exercise
    
    // Dynamically update the intensity field rules based on category
    const updatedFormData = formData.map(field => {
      if (field.dbField === 'intensity') {
        // Create a copy of the field
        const updatedField = { ...field }
        
        // If category is Sleep, make intensity not required
        if (exercise && exercise.category === 'Sleep') {
          updatedField.rules = []
        } else {
          updatedField.rules = [{ required: true, message: 'This field is required' }]
        }
        
        return updatedField
      }
      return field
    })

    return (
      <Modal
        open={true}
        width={890}
        bodyStyle={{ padding: '36px 45px' }}
        onCancel={this.handleCancel}
        footer={null}
      >
        <h1 className={style.title}>
          Edit AI Recommendation
        </h1>
        {valueForm && (
          <BasicForm
            formData={updatedFormData}
            initialValues={valueForm}
            onChange={this.onFormChange}
            wrappedComponentRef={(inst) => (this.basicFormRef = inst)}
          />
        )}

        <footer className={style.footer}>
          <div />
          <div>
            <Button ghost onClick={this.handleCancel}>
              CANCEL
            </Button>
            <Button
              type="primary"
              onClick={this.saveItem}
              loading={isSaving}
            >
              CONFIRM
            </Button>
          </div>
        </footer>
      </Modal>
    )
  }
}

export default AiWorkoutEditor
