import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import moment from 'moment'
import { Divider, Row, Col } from 'antd'
import { VictoryChart, VictoryAxis, VictoryLine, VictoryScatter } from 'victory'
import { axisStyles } from './aerobic'
import { PolyFit, getY } from '../comp/polynomial'
import { RangeBar } from '../comp/strength'
import { LargePointer } from './aerobic'
import HealthCard from '../../healthScore/card'
import Information from '../../UI/extraInformation'
import { RangeColors } from '../../../utils/constant'
import HideWrap from '../comp/hideWrap'
import ConditionalHideWrap from './ConditionalHidewrap'
import LowlevelMetrics from './LowleveMetrics'
import { formatDisplayName, getRangeByAgeAndGender } from './LowLevelMetricsHelpers'
import { getRangeAgeScopes } from '../../../api'
import { getMetricById } from '../../../api'
import { IGNORED_METRICS_FOR_LIFESTYLE_RECOMMENDATIONS } from '../../../utils/constant'

const CognitiveHealthContainer = styled.div`
  .summary-list {
    padding-bottom: 50px;
    font-size: 15px;
    color: #4c6072;
    display: flex;
    justify-content: flex-start;
    .name {
      white-space: pre-wrap;
    }
    .level {
      height: 25px;
    }
    .value {
      font-size: 24px;
      font-family: Gilroy;
      font-weight: 700;
      padding-bottom: 10px;
      .unit {
        font-weight: 500;
        font-size: 15px;
      }
    }
    .previous {
    }
    & > div {
      width: 188px;
      margin-right: 24px;
      border-right: 1px solid #e3e6ea;
      &:last-child {
        border: none;
        margin-right: 0;
      }
    }
  }
  .range-list {
    text-align: right;
    margin-bottom: -10px;
    font-weight: 600;
    margin-right: 10px;
    li {
      padding-right: 16px;
      display: inline-block;
      color: rgba(50, 58, 70, 0.5);
      font-size: 12px;
      text-transform: uppercase;
      span {
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 8px;
        margin-right: 8px;
      }
    }
    &.tip {
      margin-top: 24px;
      margin-bottom: 48px;
      li span {
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 0;
        margin-right: 8px;
      }
    }
  }
  .chart {
    margin-right: -50px;
  }
  .metrics-container {
    .name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;
      svg {
        vertical-align: middle;
        margin-right: 12px;
      }
      span {
        display: inline-block;
        color: #4c6072;
        font-family: Gilroy;
        font-weight: 700;
        .unit {
          font-weight: 500;
        }
      }
    }
  }
`

const ReactNeuroSummaryList = [
  {
    name: 'Cognitive Score',
    key: 'cognitive_score'
  },
  {
    name: 'TMT-A',
    key: 'tmt_a'
  },
  {
    name: 'TMT-B',
    key: 'tmt_b'
  },
  {
    name: 'Category Fluency',
    key: 'category_fluency'
  },
  {
    name: 'Letter Fluency',
    key: 'letter_fluency'
  }
]

const BrainCheckSummaryList = [
  {
    name: 'Braincheck Combined',
    secondName: 'Score',
    key: 'brainCheck_combined_score',
    unit: 'Score'
  },
  {
    name: 'Trails A',
    secondName: '(Age Ranked)',
    key: 'trails_a_ranked',
    unit: 'Percentile'
  },
  {
    name: 'Trails B',
    secondName: '(Age Ranked)',
    key: 'trails_b_ranked',
    unit: 'Percentile'
  },
  {
    name: 'Digit Symbol Substitution',
    secondName: '(Age Ranked)',
    key: 'digit_symbol_substitution_ranked',
    unit: 'Percentile'
  }
]

const metrics = [
  {
    name: 'Stroop',
    key: 'stroop_ranked',
    icon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="16" fill="#479FEC" />
        <path
          d="M13 7.75C12.025 7.75 11.2 8.35 10.9 9.25C10.825 9.25 10.825 9.25 10.75 9.25C8.65 9.25 7 10.9 7 13C7 13.975 7.375 14.95 8.05 15.625C7.45 16 7 16.675 7 17.5C7 18.25 7.375 18.925 7.975 19.3C7.825 19.675 7.75 20.125 7.75 20.5C7.75 22.6 9.4 24.25 11.5 24.25C13.6 24.25 15.25 22.6 15.25 20.5V10C15.25 8.725 14.275 7.75 13 7.75Z"
          fill="white"
        />
        <path
          d="M23.95 15.625C24.625 14.95 25 13.975 25 13C25 10.9 23.35 9.25 21.25 9.25C21.175 9.25 21.175 9.25 21.1 9.25C20.8 8.35 19.975 7.75 19 7.75C17.725 7.75 16.75 8.725 16.75 10V20.5C16.75 22.6 18.4 24.25 20.5 24.25C22.6 24.25 24.25 22.6 24.25 20.5C24.25 20.125 24.175 19.675 24.025 19.3C24.625 18.925 25 18.25 25 17.5C25 16.675 24.55 16 23.95 15.625Z"
          fill="white"
        />
      </svg>
    )
  },
  {
    name: 'Immediate Recognition',
    key: 'immediate_recognition_ranked',
    icon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="16" fill="#479FEC" />
        <path
          d="M17.3427 18.4288C16.2474 17.9747 14.9616 17.7441 13.5213 17.7441C12.0809 17.7441 10.7951 17.9747 9.70055 18.4288C8.66738 18.856 8 19.8574 8 20.9796V23.2654H19.0425V20.9796C19.0425 19.8574 18.3752 18.856 17.3427 18.4288Z"
          fill="white"
        />
        <path
          d="M13.5213 17.0549C15.4271 17.0549 16.9721 15.5099 16.9721 13.6041C16.9721 11.6983 15.4271 10.1533 13.5213 10.1533C11.6155 10.1533 10.0705 11.6983 10.0705 13.6041C10.0705 15.5099 11.6155 17.0549 13.5213 17.0549Z"
          fill="white"
        />
        <path
          d="M19.7933 16.403L18.7588 15.4899L19.215 14.9723C19.5483 14.5941 19.7326 14.1075 19.7326 13.603C19.7326 13.0985 19.549 12.6119 19.2157 12.2344L18.7595 11.7168L19.794 10.8037L20.2509 11.3213C20.8072 11.9508 21.1129 12.7617 21.1129 13.603C21.1129 14.4443 20.8065 15.2552 20.2502 15.8854L19.7933 16.403Z"
          fill="white"
        />
        <path
          d="M21.75 19.2075L20.8293 18.1792L21.3435 17.7188C22.5126 16.6712 23.1834 15.1714 23.1834 13.6034C23.1834 12.0354 22.5126 10.5356 21.3435 9.48867L20.8293 9.02834L21.75 8L22.2641 8.46034C23.7252 9.76819 24.5637 11.6433 24.5637 13.6034C24.5637 15.5635 23.7252 17.4386 22.2641 18.7472L21.75 19.2075Z"
          fill="white"
        />
      </svg>
    )
  },
  {
    name: 'Delayed Recognition',
    key: 'delayed_recognition_ranked',
    icon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="16" fill="#479FEC" />
        <path
          d="M16 8C11.5887 8 8 11.5887 8 16C8 20.4113 11.5887 24 16 24C20.4113 24 24 20.4113 24 16C24 11.5887 20.4113 8 16 8ZM20.6667 16.6667H16C15.6313 16.6667 15.3333 16.368 15.3333 16V11.3333C15.3333 10.9653 15.6313 10.6667 16 10.6667C16.3687 10.6667 16.6667 10.9653 16.6667 11.3333V15.3333H20.6667C21.0353 15.3333 21.3333 15.632 21.3333 16C21.3333 16.368 21.0353 16.6667 20.6667 16.6667Z"
          fill="white"
        />
      </svg>
    )
  }
]

export default function CognitiveHealth(props) {
  const {
    hideSectionHandle,
    hideSection,
    save,
    loginRole,
    view,
    dataList,
    compareData,
    onScoreChangeHandle,
    cancelEdit,
    arDetail: { notes, status },
    handleCommendSave,
    updateEditingCount,
    person,
    ageScopes,
    dynamicData: { cognitiveHealth }
  } = props

  const [valueResults, setValueResults] = useState([])

  useEffect(() => {
    fetchBodyCompMetricsDetails()
  }, [])

  const fetchBodyCompMetricsDetails = async () => {
    const cognitiveMetrics = props.dynamicData.cognitiveHealth

    const metricKeys = Object.keys(cognitiveMetrics)
    const ageScopes = (await getRangeAgeScopes()).age_scope

    const biomarkerDetailsPromises = metricKeys.map(async (metricKey) => {
      const metric =
        props &&
        props.dynamicData &&
        props.dynamicData.cognitiveHealth[metricKey]

      if (!metric) {
        return null
      }

      if (['orange', 'red', 'yellow'].includes(metric.color) && !IGNORED_METRICS_FOR_LIFESTYLE_RECOMMENDATIONS.includes(metricKey)) {
        const conventionalRanges = metric.ranges
          ? getRangeByAgeAndGender(metric.ranges, person, ageScopes)
          : []

        if (metric && metric.metricId) {
          const biomarkerDetail = await getMetricById(
            metric.metricId,
            null,
            'cognitive-health'
          )

          const introduction =
            biomarkerDetail && biomarkerDetail.cognitive_health.introduction
              ? biomarkerDetail.cognitive_health.introduction
              : 'No introduction available'

          return {
            displayName: formatDisplayName(metricKey),
            value: metric.value,
            color: metric.color,
            level: metric.level,
            conventionalRanges,
            biomarkerDetail,
            introduction,
            sectionName: 'Cognitive Health'
          }
        }
      }

      return null
    })

    const results = (await Promise.all(biomarkerDetailsPromises)).filter(
      (result) => result !== null
    )
    setValueResults(results)
  }

  const data = dataList.find((item) => item.name === 'Cognitive Health') || {}
  const compare =
    compareData.find((item) => item.name === 'Cognitive Health') || {}

  cognitiveHealth &&
    _.assign(cognitiveHealth, {
      cognitive_score: {
        previous:
          _.get(compare, 'score_manual') || _.get(compare, 'score_auto'),
        value: _.get(data, 'score_manual') || _.get(data, 'score_auto')
      }
    })
  const cognitive = notes.find((item) => item.pillar === 'cognitive health')

  const { targetsPerMinSeries } = cognitiveHealth //targetsPerMinHistory
  return (
    <>
      <ConditionalHideWrap
        name="Cognitive Health"
        isSection={true}
        syncStatus={hideSectionHandle}
        status={hideSection}
        view={view}
        condition={
          view === 'Draft' || (view === 'Preview' && !hideSection.includes('Cognitive Health'))
        }
      >
        <section>
          <h1 className="section-title">Cognitive Health</h1>
          <ConditionalHideWrap
            name="Cognitive Health - summary"
            title="summary"
            syncStatus={hideSectionHandle}
            status={hideSection}
            view={view}
            condition={
              view === 'Draft' || (view === 'Preview' && !hideSection.includes('Cognitive Health - summary'))
            }
          >
            <div className="sub-title">Summary</div>
            <HealthCard
              {...data}
              compare={compare}
              save={save}
              loginRole={loginRole}
              status={view === 'Draft' ? 'Draft' : 'Opened'}
              onChangeHandle={onScoreChangeHandle}
              disEdit={status === 1 || view === 'Preview'}
              cancel={cancelEdit}
            />
            <Information
              title="COMMENT/NOTE"
              disMarkDone={true}
              initialValue={cognitive ? cognitive.memo : ''}
              status={view === 'Draft' ? 'Draft' : 'Opened'}
              updateCommend={(value, status) =>
                handleCommendSave(value, 'cognitive health', status)
              }
              updateEditingCount={updateEditingCount}
              
            />
            <Divider />
          </ConditionalHideWrap>
          <ConditionalHideWrap
            name="Cognitive Health - React Neuro"
            title="React Neuro"
            syncStatus={hideSectionHandle}
            status={hideSection}
            view={view}
            condition={
              view === 'Draft' || (view === 'Preview' && !hideSection.includes('Cognitive Health - React Neuro'))
            }
          >
            <div className="sub-title">React Neuro</div>
            <CognitiveHealthContainer>
              <div className="summary-list">
                {ReactNeuroSummaryList.map((item, index) => {
                  const metric = item.key && cognitiveHealth[item.key]
                  const { level, color } = metric || {} // unit, level, color
                  const value =
                    _.get(metric, 'value.value') || _.get(metric, 'value')
                  const previous =
                    _.get(metric, 'previous.value') || _.get(metric, 'previous')
                  return (
                    <div key={index}>
                      <div className="name">{item.name}</div>
                      <div
                        className="level"
                        style={color ? { color: RangeColors[color] } : {}}
                      >
                        {level ? `(${level})` : ' '}
                      </div>
                      <div
                        className="value"
                        // style={color ? { color: RangeColors[color] } : {}}
                      >
                        {_.isNumber(value) ? value : '--'}
                      </div>
                      <div className="previous">
                        Previous – {_.isNumber(previous) ? previous : ''}
                      </div>
                    </div>
                  )
                })}
              </div>
            </CognitiveHealthContainer>
            <CognitiveHealthContainer>
              <ConditionalHideWrap
                name="Cognitive Health - React Neuro Graph"
                title="React Neuro Graph"
                syncStatus={hideSectionHandle}
                status={hideSection}
                view={view}
                condition={
                  view === 'Draft' || (view === 'Preview' && !hideSection.includes('Cognitive Health - React Neuro Graph'))
                }
              >
              <Logarithmic
                targetsPerMinSeries={targetsPerMinSeries}
                histories={
                  cognitiveHealth.targetsPerMinHistory ||
                  cognitiveHealth.tmtBHistory
                }
              />
              </ConditionalHideWrap>
            </CognitiveHealthContainer>
          </ConditionalHideWrap>
          <ConditionalHideWrap
            name="Cognitive Health - BrainCheck"
            title="BrainCheck"
            syncStatus={hideSectionHandle}
            status={hideSection}
            view={view}
            condition={
              view === 'Draft' || (view === 'Preview' && !hideSection.includes('Cognitive Health - BrainCheck'))
            }
          >
            <CognitiveHealthContainer>
              <div className="sub-title">BrainCheck</div>
              <div className="summary-list">
                {BrainCheckSummaryList.map((item, index) => {
                  const metric = item.key && cognitiveHealth[item.key]
                  const { level, color } = metric || {} // unit, level, color
                  const value =
                    _.get(metric, 'value.value') || _.get(metric, 'value')
                  return (
                    <div key={index}>
                      <div className="name">{item.name}</div>
                      <div className="name">{item.secondName}</div>
                      <div className="value">
                        {_.isNumber(value) ? (
                          <>
                            {value} <span className="unit">{item.unit}</span>
                          </>
                        ) : (
                          '--'
                        )}
                      </div>
                      <div
                        className="level"
                        style={
                          color ? { backgroundColor: RangeColors[color] } : {}
                        }
                      >
                        {level || ' '}
                      </div>
                    </div>
                  )
                })}
              </div>
              <Row className="metrics-container" gutter={28}>
                {metrics.map((item, index) => {
                  const metric = item.key && cognitiveHealth[item.key]
                  const { value, unit } = metric || {}
                  return (
                    <Col span={8} key={index}>
                      <div className="name">
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {item.icon}
                          <div>
                            {item.name} <div>(Age Ranked)</div>{' '}
                          </div>
                        </div>
                        <span
                          style={{
                            textAlign: 'end'
                          }}
                        >
                          {_.isNumber(value) ? (
                            <>
                              {value} <div className="unit">{unit}</div>{' '}
                            </>
                          ) : (
                            '--'
                          )}
                        </span>
                      </div>
                      {metric && (
                        <div className="range">
                          <RangeBar
                            {...metric}
                            person={person}
                            ageScopes={ageScopes}
                            hideValue
                          />
                        </div>
                      )}
                    </Col>
                  )
                })}
              </Row>
            </CognitiveHealthContainer>
          </ConditionalHideWrap>
          <LowlevelMetrics metricValues={valueResults} view={view} hideSection={hideSection} hideSectionHandle={hideSectionHandle} />
        </section>
      </ConditionalHideWrap>
    </>
  )
}

function Logarithmic(props) {
  const { targetsPerMinSeries, histories } = props
  const axisX = [20, 30, 40, 50, 60, 70, 80, 90],
    axisY = [0, 10, 20, 30, 40]

  const data = []
  for (const { level, color, type, series } of targetsPerMinSeries) {
    const len = series.y.length
    const m = 3
    const a = new Array(series.x.length)
    const aa = PolyFit(series.x, series.y, len, a, m)
    data.push({
      aa,
      level,
      color,
      type,
      arrX: series.x,
      m
    })
  }

  const targetValue = _.cloneDeep(histories).pop()
  const scatter = targetValue
    ? [
        {
          data: [{ x: targetValue[0], y: targetValue[1] }],
          date: moment(targetValue[2]).format('MM/DD/YYYY'),
          dataComponent: <LargePointer />
        }
      ]
    : []

  while (targetValue && targetValue[1] > axisY[axisY.length - 1]) {
    axisY.push(axisY[axisY.length - 1] + 10)
  }

  // const scatter = _.cloneDeep(histories)
  //   .reverse()
  //   .map((item, index) => {
  //     const result = {
  //       data: [{ x: item[0], y: item[1] }],
  //       date: moment(item[2]).format('MM/DD/YYYY'),
  //       color: dotColors[index - 1] || '#BFBFBF'
  //     }
  //     if (index === 0) {
  //       result.dataComponent = <LargePointer />
  //     } else {
  //       result.style = { data: { fill: dotColors[index - 1] || '#BFBFBF' } }
  //       result.size = 5
  //     }
  //     return result
  //   })

  return (
    <>
      <ul className="range-list">
        {targetsPerMinSeries.map((item, index) => (
          <li key={index}>
            <span style={{ background: item.color }} />
            {item.level}
          </li>
        ))}
      </ul>
      {/* <ul className="range-list" style={{ marginTop: 20 }}>
        {_.cloneDeep(scatter)
          .reverse()
          .map((item, index) => (
            <li key={index}>
              {item.dataComponent ? (
                <span>
                  <SmallPointer />
                </span>
              ) : (
                <span
                  className="tip-circle"
                  style={{ background: item.color }}
                />
              )}
              {item.date}
            </li>
          ))}
      </ul> */}
      <div className="chart">
        <VictoryChart
          width={985}
          minDomain={{ x: 20, y: 0 }}
          maxDomain={{ x: 90, y: axisY[axisY.length - 1] }}
        >
          <VictoryAxis tickValues={axisX} label="AGE" style={axisStyles} />
          <VictoryAxis
            tickValues={axisY}
            dependentAxis
            style={axisStyles}
            label="TARGETS/MIN"
          />
          {data.map(({ arrX, aa, m, color, type }, index) => (
            <VictoryLine
              key={index}
              y={(d) => {
                return d.x >= 20 ? getY(d.x, arrX, aa, m) : null
              }}
              style={{
                data: {
                  stroke: color,
                  strokeDasharray: type === 'dotted' ? '3,3' : null
                }
              }}
            />
          ))}

          {scatter.map((item, index) => (
            <VictoryScatter key={index} {...item} />
          ))}
        </VictoryChart>
      </div>
    </>
  )
}
