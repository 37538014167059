import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { SectionStyle } from '../questionnaire/styles/sectionStyle'
import { ExerciseViz } from './ExerciseGraph'
import { ExerciseGauge } from './ExerciseGauge'
import { TrainingGauge } from './TrainingGauge'
import { SleepViz } from './SleepGraph'
import { EnergyViz } from './EnergyGraph'
import { NutritionViz } from './NutritionGraph'
import StepItem from './sections/StepItem'
import { ReactComponent as ExerciseIcon } from '../../asserts/icon/client/ic_exercise.svg'
import { ReactComponent as NutritionIcon } from '../../asserts/icon/client/ic_nutrition.svg'
import { ReactComponent as SleepIcon } from '../../asserts/icon/client/ic_rejuvenation.svg'

const baseUrl = process.env.REACT_APP_ML_API_ENDPOINT

const Container = styled.section`
  ${SectionStyle}
  .single-leg-stance-status-list {
    padding-left: 22.57%;
    color: #4c6072;
    & > div {
      border-bottom: 1px solid #e6e9f2;
      height: 64px;
      &:last-child {
        border-bottom: none;
      }
    }
    .status {
      padding: 5px 12px;
      border-radius: 6px;
      color: white;
      font-weight: 600;
    }
    .optimal {
      background-color: green;
    }
  }
`

const VisualizationWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 8px;
  width: 100%;
  padding: 4px 16px;
  margin-bottom: 0;
  background: #ffffff;
  .bar-group rect {
    transition: all 0.3s ease-in-out;
  }
  .bar-group:hover rect {
    transform: scale(1.02);
  }
`

const GaugeContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 25% 25%;
  align-items: center;
  padding: 4px 16px;
  gap: 8px;
  width: 100%;
  background: #ffffff;
  > div {
    width: 100%;
    min-width: 0;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 5px;
    svg {
      max-height: 200px;
      width: auto;
    }
  }
`

const NutritionContainer = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: 300px 350px;
  align-items: start;
  align-content: start;
  padding: 4px 16px;
  gap: 0 4px;
  width: 100%;
  background: #ffffff;
  margin-top: 8px; /* Added margin-top */
  margin-bottom: 8px;
  > div {
    width: 100%;
    min-width: 0;
    background: transparent;
    border-radius: 5px;
    position: relative;
    overflow: visible;
  }
  > div:first-child {
    grid-row: span 2;
    height: 650px !important;
  }
  > div:not(:first-child) {
    height: 350px !important;
    margin: 0;
  }
`

// Add this helper function to format protocol data
const formatProtocolData = (protocol, type) => {
  protocol = protocol[type]
  console.info('protocol', protocol)
  if (!protocol || !Array.isArray(protocol)) return ''

  const recommendations = [...protocol] // Create a copy to avoid mutating original
  const phases = ['Phase 1:', 'Phase 2:', 'Phase 3:']
  let formattedText = ''

  // Process 2 recommendations per phase
  for (let i = 0; i < phases.length; i++) {
    const phaseRecs = recommendations.slice(i * 2, i * 2 + 2)
    if (phaseRecs.length === 0) continue

    formattedText += `\n${phases[i]}\n\n`

    phaseRecs.forEach((rec) => {
      formattedText += `**${rec.title}**\n\n`
      formattedText += `${rec.why_this_matters}\n\n`
      if (rec.action_choices && rec.action_choices.length > 0) {
        formattedText += 'Action Steps:\n'
        rec.action_choices.forEach((action) => {
          formattedText += `- ${action}\n`
        })
        formattedText += '\n'
      }
    })
  }

  return formattedText.trim()
}

// Add helper function for markdown conversion
const convertMarkdownToBold = (text) => {
  if (!text) return ''
  return text
    .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
    .replace(/\*(.*?)\*/g, '<b>$1</b>')
}

// Add configs similar to RecommendationSection
const recommendationConfigs = [
  {
    field: 'exerciseProtocol',
    title: 'Exercise Recommendations',
    placeholder: 'Please enter exercise recommendations',
    icon: <ExerciseIcon />,
    color: 'rgb(255, 107, 0)'
  },
  {
    field: 'sleepProtocol',
    title: 'Sleep Recommendations',
    placeholder: 'Please enter sleep recommendations',
    icon: <SleepIcon />,
    color: 'rgb(69, 30, 146)'
  },
  {
    field: 'nutritionProtocol',
    title: 'Nutrition Recommendations',
    placeholder: 'Please enter nutrition recommendations',
    icon: <NutritionIcon />,
    color: 'rgb(123, 173, 45)'
  }
]

// Update getData function to handle both notes and detail
const getData = (notes, detail, field) => {
  // First check notes
  const note = notes && notes.find((n) => n.pillar === field)
  if (note && note.memo) {
    return convertMarkdownToBold(note.memo)
  }

  // If no note exists or is empty, fall back to detail
  if (field === 'exerciseProtocol' && detail && detail.exerciseProtocol) {
    return convertMarkdownToBold(
      formatProtocolData(detail.exerciseProtocol, 'exercise_recommendations')
    )
  }
  if (field === 'nutritionProtocol' && detail && detail.nutritionProtocol) {
    return convertMarkdownToBold(
      formatProtocolData(detail.nutritionProtocol, 'nutrition_recommendations')
    )
  }
  if (field === 'sleepProtocol' && detail && detail.sleepProtocol) {
    return convertMarkdownToBold(
      formatProtocolData(detail.sleepProtocol, 'sleep_recommendations')
    )
  }

  return ''
}

const isExerciseDetailsEmpty = (exerciseDetails) => {
  if (!exerciseDetails) return true
  return ['activity', 'resistance', 'aerobic'].every(
    (key) => !exerciseDetails[key] || exerciseDetails[key].length === 0
  )
}

// Replace the hasValidData function with more specific validators
const isValidNumber = (value) => {
  return value !== null && !isNaN(value) && value !== 0;
}

const hasValidExerciseLoad = (data) => {
  if (!data) return false;
  return isValidNumber(data.load_index) || isValidNumber(data.rest_index);
}

// More thorough check for exercise data
const hasValidExerciseData = (data) => {
  if (!data) return false;
  
  // Check if any of the exercise categories have meaningful data
  const hasActivity = data.activity && data.activity.length > 0 && 
    data.activity.some(item => item[0] > 0 && item[1] > 0);
  
  const hasResistance = data.resistance && data.resistance.length > 0 && 
    data.resistance.some(item => item[0] > 0 && item[1] > 0);
  
  const hasAerobic = data.aerobic && data.aerobic.length > 0 && 
    data.aerobic.some(item => item[0] > 0 && item[1] > 0);
  
  return hasActivity || hasResistance || hasAerobic;
}

// More thorough check for sleep data
const hasValidSleepData = (data) => {
  if (!data) return false;
  
  // Check key sleep metrics that would show in visualization
  const hasValidFields = [
    'overall_satisfaction', 'stay_asleep', 'get_sleep', 
    'sleep_time', 'wake_time'
  ].some(field => isValidNumber(data[field]));
  
  return hasValidFields;
}

// More thorough check for energy data
const hasValidEnergyData = (data) => {
  if (!data) return false;
  
  // Check key energy metrics that would show in visualization
  const hasValidFields = [
    'morning_energy', 'midday_energy', 'energy_level'
  ].some(field => isValidNumber(data[field]));
  
  return hasValidFields;
}

// More thorough check for nutrition data
const hasValidNutritionData = (data) => {
  if (!data) return false;
  
  // Check if servings property exists and is an object
  if (!data.servings || typeof data.servings !== 'object') return false;
  
  try {
    // Check if any servings have non-zero values
    // Handle both array and object formats
    if (Array.isArray(data.servings)) {
      return data.servings.some(item => 
        item && typeof item === 'object' && 
        Object.values(item).some(val => val > 0)
      );
    } else {
      // Handle object format
      return Object.values(data.servings).some(value => 
        value !== null && value !== undefined && value > 0
      );
    }
  } catch (error) {
    console.error("Error validating nutrition data:", error);
    return false;
  }
}

export default function LifeStylePresentation(props) {
  const { arDetail, person, lsd, editabled, handleCommendSave } = props

  const [exerciseData, setExerciseData] = useState(null)
  const [exerciseLoad, setExerciseLoad] = useState(null)
  const [exerciseIdealData, setExerciseIdealData] = useState(null)
  const [sleepData, setSleepData] = useState(null)
  const [sleepDataProcessed, setSleepDataProcessed] = useState(null)
  const [energyData, setEnergyData] = useState(null)
  const [nutritionData, setNutritionData] = useState(null)
  const [nutritionQualityData, setNutritionQualityData] = useState(null)
  const [nutritionQualityIdealData, setNutritionQualityIdealData] = useState(
    null
  )
  const [beveragesData, setBeveragesData] = useState(null)
  const [beveragesIdealData, setBeveragesIdealData] = useState(null)
  const [cte, setCte] = useState(
    lsd && lsd.lifestyleScore && lsd.lifestyleScore.exercise && lsd.lifestyleScore.exercise.score
      ? lsd.lifestyleScore.exercise.score.total
      : null
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ML_API_ENDPOINT}/api/ml/gap_analysis_plots/${person.id}/${arDetail.id}`
        )
        setExerciseData(
          isExerciseDetailsEmpty(response.data.exercise_details_processed)
            ? response.data.exercise
            : response.data.exercise_details_processed
        )
        setExerciseIdealData(response.data.exercise_ideal)
        setExerciseLoad(response.data.exercise_load)
        // check if cte is null
        if (cte === null) {
          console.info('cte is null, set cte to capacity score')
          console.info('response.data.exercise_load', response.data.exercise_load)
          setCte(response.data.exercise_load.capacity_score)
        }
        setSleepData(response.data.sleep)
        setSleepDataProcessed(response.data.sleep_levels_processed)
        setEnergyData(response.data.energy)
        setNutritionData(response.data.nutrition)
        setNutritionQualityData(response.data.nutrition_quality)
        setNutritionQualityIdealData(response.data.nutrition_quality_ideal)
        setBeveragesData(response.data.beverages)
        setBeveragesIdealData(response.data.beverages_ideal)
      } catch (error) {
        console.error('Error fetching exercise data:', error)
      }
    }

    fetchData()
  }, [person.id, arDetail.id])

  // Calculate max duration across both datasets
  const getMaxDuration = (exerciseData, exerciseIdealData) => {
    if (!exerciseData || !exerciseIdealData) return 0

    const allDurations = [
      ...exerciseData.activity.map(([d]) => d),
      ...exerciseData.resistance.map(([d]) => d),
      ...exerciseData.aerobic.map(([d]) => d),
      ...exerciseIdealData.activity.map(([d]) => d),
      ...exerciseIdealData.resistance.map(([d]) => d),
      ...exerciseIdealData.aerobic.map(([d]) => d)
    ]

    return Math.max(...allDurations)
  }

  const maxDuration = getMaxDuration(exerciseData, exerciseIdealData)

  if (!exerciseIdealData) {
    return (
      <Container className="section-card">
        <div>Loading Lifestyle Presentation...</div>
      </Container>
    )
  }

  return (
    <Container className="section-card">
      {exerciseLoad && hasValidExerciseLoad(exerciseLoad) && (
        <GaugeContainer>
          <TrainingGauge
            value={exerciseLoad.load_index}
            label="Training Load"
            subtitle="Cumulative Physical Exertion"
            min={100}
            max={400}
          />
          <ExerciseGauge
            value={cte}
            label="Capacity to Exercise"
            subtitle="Current fitness level"
            min={0}
            max={100}
          />
          <ExerciseGauge
            value={exerciseLoad.rest_index}
            label="Rest Index"
            subtitle="Recovery status"
            min={0}
            max={0.2}
          />
        </GaugeContainer>
      )}
      
      {/* Exercise visualization - only show if there's valid data */}
      {exerciseData && exerciseIdealData && hasValidExerciseData(exerciseData) && (
        <VisualizationWrapper>
          <ExerciseViz
            data={exerciseData}
            maxDuration={maxDuration}
            title="Current"
          />
          <ExerciseViz
            data={exerciseIdealData}
            maxDuration={maxDuration}
            title="Ideal"
          />
        </VisualizationWrapper>
      )}
      
      {/* Always show exercise recommendations */}
      <StepItem
        field="exerciseProtocol"
        title="Exercise Recommendations"
        placeholder="Please enter exercise recommendations"
        icon={<ExerciseIcon />}
        color={
          recommendationConfigs.find(
            (c) => c.field === 'exerciseProtocol'
          ).color
        }
        editabled={editabled}
        value={getData(
          arDetail.notes,
          arDetail.detail,
          'exerciseProtocol'
        )}
        onChange={handleCommendSave}
        isAIGenerated={true}
      />

      {/* Sleep and Energy visualizations - more specific validation */}
      {sleepData && energyData && 
        hasValidSleepData(sleepData) && hasValidEnergyData(energyData) && (
        <VisualizationWrapper>
          <SleepViz
            sleepData={sleepData}
            sleepLevelsProcessed={sleepDataProcessed}
          />
          <EnergyViz energyData={energyData} />
        </VisualizationWrapper>
      )}
      
      {/* Always show sleep recommendations */}
      <StepItem
        field="sleepProtocol"
        title="Sleep Recommendations"
        placeholder="Please enter sleep recommendations"
        icon={<SleepIcon />}
        color={
          recommendationConfigs.find((c) => c.field === 'sleepProtocol')
            .color
        }
        editabled={editabled}
        value={getData(arDetail.notes, arDetail.detail, 'sleepProtocol')}
        onChange={handleCommendSave}
        isAIGenerated={true}
      />

      {/* Nutrition visualization - more specific validation */}
      {nutritionData && nutritionQualityData && beveragesData &&
        hasValidNutritionData(nutritionData) && (
        <NutritionContainer>
          <NutritionViz
            type="food"
            nutrition={nutritionData}
            height={700}
          />
          <NutritionViz
            type="beverages"
            beverages={beveragesData}
            beverages_ideal={beveragesIdealData}
            height={350}
          />
          <NutritionViz
            type="quality"
            nutrition_quality={nutritionQualityData}
            nutrition_quality_ideal={nutritionQualityIdealData}
            height={350}
          />
        </NutritionContainer>
      )}
      
      {/* Always show nutrition recommendations */}
      <StepItem
        field="nutritionProtocol"
        title="Nutrition Recommendations"
        placeholder="Please enter nutrition recommendations"
        icon={<NutritionIcon />}
        color={
          recommendationConfigs.find(
            (c) => c.field === 'nutritionProtocol'
          ).color
        }
        editabled={editabled}
        value={getData(
          arDetail.notes,
          arDetail.detail,
          'nutritionProtocol'
        )}
        onChange={handleCommendSave}
        isAIGenerated={true}
      />
    </Container>
  )
}
