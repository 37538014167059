import React, { useEffect, useState } from 'react'
import { Divider } from 'antd'
import HealthCard from '../../healthScore/card'
import Information from '../../UI/extraInformation'
import HideWrap from '../comp/hideWrap'
import BodyCompResult from '../comp/bodyComp'
import ConditionalHideWrap from './ConditionalHidewrap'
import LowlevelMetrics from './LowleveMetrics'
import {
  formatDisplayName,
  getRangeByAgeAndGender
} from './LowLevelMetricsHelpers'
import { getRangeAgeScopes } from '../../../api'
import { getMetricById } from '../../../api'
import { IGNORED_METRICS_FOR_LIFESTYLE_RECOMMENDATIONS } from '../../../utils/constant'

export default function BodyCompResultSection(props) {
  const {
    person,
    hideSectionHandle,
    hideSection,
    // handleSave,
    compareData,
    dataList,
    view,
    infinityLoadCount,
    loginRole,
    save,
    onScoreChangeHandle,
    cancelEdit,
    handleCommendSave,
    updateEditingCount,
    ageScopes,
    arDetail: { notes, status },
    dynamicData: { ui_version, bodyCompResults }
  } = props

  const [valueResults, setValueResults] = useState([])

  useEffect(() => {
    fetchBodyCompMetricsDetails()
  }, [])

  const fetchBodyCompMetricsDetails = async () => {
    const bodyCompMetrics = props.dynamicData.bodyCompResults
    const metricKeys = Object.keys(bodyCompMetrics)
    const ageScopes = (await getRangeAgeScopes()).age_scope

    const biomarkerDetailsPromises = metricKeys.map(async (metricKey) => {
      const metric =
        props &&
        props.dynamicData &&
        props.dynamicData.bodyCompResults[metricKey]

      if (!metric) {
        return null
      }

      if (['orange', 'red', 'yellow'].includes(metric.color) && !IGNORED_METRICS_FOR_LIFESTYLE_RECOMMENDATIONS.includes(metricKey)) {
        const conventionalRanges = metric.ranges
          ? getRangeByAgeAndGender(metric.ranges, person, ageScopes)
          : []

        if (metric && metric.metricId) {
          const biomarkerDetail = await getMetricById(
            metric.metricId,
            null,
            'body-comp'
          )

          const introduction =
            biomarkerDetail && biomarkerDetail.body_comp.introduction
              ? biomarkerDetail.body_comp.introduction
              : 'No introduction available'

          return {
            displayName: formatDisplayName(metricKey),
            value: metric.value,
            color: metric.color,
            level: metric.level,
            conventionalRanges,
            biomarkerDetail,
            introduction,
            sectionName: 'Body Composition'
          }
        }
      }

      return null
    })

    const results = (await Promise.all(biomarkerDetailsPromises)).filter(
      (result) => result !== null
    )
    setValueResults(results)
  }

  const bodyCompData =
    dataList.find((item) => item.type === 'body_composition') || {}

  const bodyCompCompare =
    compareData.find((item) => item.type === 'body_composition') || {}

  const bodyComp = notes.find((item) => item.pillar === 'body comp')
  const footerNote = notes.find(
    (item) => item.pillar === 'body_comp_footer_note'
  )

  return (
    <>
      {(infinityLoadCount > 2 || status === 1) && (
        <ConditionalHideWrap
          name="Body Composition"
          isSection={true}
          syncStatus={hideSectionHandle}
          status={hideSection}
          view={view}
          condition={
            view === 'Draft' ||
            (view === 'Preview' && !hideSection.includes('Body Composition'))
          }
        >
          <section>
            <h1 className="section-title">Body Composition</h1>
            <ConditionalHideWrap
              name="Body Composition - summary"
              title="summary"
              syncStatus={hideSectionHandle}
              status={hideSection}
              view={view}
              condition={
                view === 'Draft' ||
                (view === 'Preview' &&
                  !hideSection.includes('Body Composition - summary'))
              }
            >
              <div className="sub-title">Summary</div>
              <HealthCard
                {...bodyCompData}
                compare={bodyCompCompare}
                save={save}
                loginRole={loginRole}
                status={view === 'Draft' ? 'Draft' : 'Opened'}
                onChangeHandle={onScoreChangeHandle}
                disEdit={status === 1 || view === 'Preview'}
                cancel={cancelEdit}
              />
              <Information
                title="COMMENT/NOTE"
                disMarkDone={true}
                initialValue={bodyComp ? bodyComp.memo : ''}
                status={view === 'Draft' ? 'Draft' : 'Opened'}
                updateCommend={(value, status) =>
                  handleCommendSave(value, 'body comp', status)
                }
                updateEditingCount={updateEditingCount}
              />
              <Divider />
            </ConditionalHideWrap>
            {bodyCompResults && (
              <>
                <ConditionalHideWrap
                  name="Body Composition - Result"
                  title="Body Composition"
                  syncStatus={hideSectionHandle}
                  status={hideSection}
                  view={view}
                  condition={
                    view === 'Draft' ||
                    (view === 'Preview' &&
                      !hideSection.includes('Body Composition - Result'))
                  }
                >
                  <div className="sub-title">Body Composition</div>
                  <BodyCompResult
                    {...{
                      ui_version,
                      bodyCompResults,
                      compareData,
                      dataList,
                      person,
                      ageScopes,
                      notes,
                      handleCommendSave,
                      updateEditingCount
                    }}
                  />
                  <Information
                    title="COMMENT/NOTE"
                    disMarkDone={true}
                    initialValue={footerNote ? footerNote.memo : ''}
                    status={view === 'Draft' ? 'Draft' : 'Opened'}
                    updateCommend={(value, status) =>
                      handleCommendSave(value, 'body_comp_footer_note', status)
                    }
                    updateEditingCount={updateEditingCount}
                  />
                </ConditionalHideWrap>{' '}
              </>
            )}
            <LowlevelMetrics metricValues={valueResults} view={view} hideSection={hideSection} hideSectionHandle={hideSectionHandle} />
          </section>
        </ConditionalHideWrap>
      )}
    </>
  )
}
