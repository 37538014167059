export const getColor = (value) => {
    // Handle edge cases
    if (value <= 50) return 'rgb(255, 255, 0)' // Yellow
    if (value >= 300) return 'rgb(255, 0, 0)'  // Red

    // Calculate how far we are between yellow and red (0 to 1)
    const ratio = (value - 50) / (300 - 50)

    // Keep red at 255, reduce green from 255 to 0
    const red = 255
    const green = Math.round(255 * (1 - ratio))

    // Return the color (blue stays at 0)
    return `rgb(${red}, ${green}, 0)`
}

export const getTypeColor = (type) => {
    switch (type) {
        case 'resistance':
            return '#FFD580' // Pastel Orange
        case 'aerobic':
            return '#ADD8E6' // Pastel Blue
        case 'activity':
            return '#90EE90' // Pastel Green
        default:
            return '#F0F0F0' // Light gray
    }
}

export const getCapacityColor = (value) => {
    // Convert value to number between 0 and 1
    const normalizedValue = value / 100

    // Define base colors (darker/muted variants)
    const red = [165, 42, 42]   // darker red
    const green = [34, 139, 34] // darker green

    // Interpolate between the colors
    const r = Math.round(red[0] + (green[0] - red[0]) * normalizedValue)
    const g = Math.round(red[1] + (green[1] - red[1]) * normalizedValue)
    const b = Math.round(red[2] + (green[2] - red[2]) * normalizedValue)

    return `rgb(${r}, ${g}, ${b})`
}

// Add this helper function to get intensity display
export const getIntensityDisplay = (activity) => {
    if (activity.heartRateZone) return `HRZ: ${activity.heartRateZone}`
    if (activity.rpe) return `RPE: ${activity.rpe}`
    if (activity.difficultyLevel) return `${activity.difficultyLevel}`
    return ''
}

// Add this helper function near the top of the file
export const calculateDayIntensity = (activities) => {
    return activities.reduce((sum, activity) => {
        if (activity.heartRateZone) {
            return sum + (activity.heartRateZone * 2)
        } else if (activity.rpe) {
            return sum + activity.rpe
        } else if (activity.difficultyLevel) {
            switch (activity.difficultyLevel.toLowerCase()) {
                case 'light': return sum + 3
                case 'moderate': return sum + 5
                case 'intense': return sum + 8
                default: return sum
            }
        }
        return sum
    }, 0)
}

export const getIntensityBarColor = (intensity) => {
    // Normalize intensity to a 0-1 scale (assuming max intensity of 30)
    const normalized = Math.min(intensity / 20, 1)

    // Green to Yellow to Red gradient
    if (normalized <= 0.5) {
        // Green to Yellow
        const r = Math.round(normalized * 2 * 255)
        const g = 255
        const b = 0
        return `rgb(${r}, ${g}, ${b})`
    } else {
        // Yellow to Red
        const r = 255
        const g = Math.round((1 - (normalized - 0.5) * 2) * 255)
        const b = 0
        return `rgb(${r}, ${g}, ${b})`
    }
}

// Add this helper function near the top with other utility functions
export const calculateTotalTime = (activities) => {
    return Object.values(activities).reduce((total, dayActivities) => {
        return total + dayActivities.reduce((dayTotal, activity) => {
            return dayTotal + (activity.time || 0)
        }, 0)
    }, 0)
}
