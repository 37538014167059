import { useState, useEffect } from 'react'

// Constants
const HSD_BASELINE = {
    aerobic: 635,
    bone: 992,
    body_composition: 840,
    muscle_fitness: 554,
    movement_quality: 409,
    balance: 691,
    cognitive_health: 954
}

// Utility Functions
const calcSlopeYintByMaxhr = (maxhr) => {
    const x = [maxhr, maxhr * 0.9, maxhr * 0.8, maxhr * 0.7, maxhr * 0.6, maxhr * 0.5]
    const y = [10, 8, 6, 4, 2, 1]
    const x_avg = x.reduce((a, b) => a + b, 0) / x.length
    const y_avg = y.reduce((a, b) => a + b, 0) / y.length
    let numerator = 0
    let denominator = 0
    for (let i = 0; i < x.length; i++) {
        numerator += (x[i] - x_avg) * (y[i] - y_avg)
        denominator += (x[i] - x_avg) ** 2
    }
    let slope = numerator / denominator
    let y_int = y_avg - slope * x_avg
    slope = Number(slope.toFixed(3))
    y_int = Number(y_int.toFixed(3))
    return { slope, y_int }
}

const calcRpeByMaxhr = (maxhr, avghr) => {
    const { slope, y_int } = calcSlopeYintByMaxhr(maxhr)
    let rpe = Number((avghr * slope + y_int).toFixed(3))
    if (isNaN(rpe) || rpe < 1) {
        rpe = 1
    }
    if (rpe > 10) {
        rpe = 10
    }
    return rpe
}

const calcRpeForResistance = (maxhr, avghr, peakhr) => {
    if (maxhr - avghr === 0) {
        return 1
    }
    let rpe = ((peakhr - avghr) / (maxhr - avghr)) * 10
    if (isNaN(rpe) || rpe < 1) {
        rpe = 1
    }
    if (rpe > 10) {
        rpe = 10
    }
    return rpe
}

export const calculateRPE = (maxHR, avgHR, peakHR, exerciseType) => {
    let rpe

    if (exerciseType.toLowerCase() === 'resistance') {
        rpe = calcRpeForResistance(maxHR, avgHR, peakHR)
    } else {
        rpe = calcRpeByMaxhr(maxHR, avgHR)
    }

    // Clamp between 1 and 10
    return Math.min(Math.max(Math.round(rpe), 1), 10)
}

export const calculateHSD = (
    duration,
    weighting,
    rpe
) => {
    const effectiveRPE = rpe
    const result = {}

    Object.keys(HSD_BASELINE).forEach(category => {
        if (weighting[category] !== null) {
            let hsd

            if (['bone', 'balance', 'cognitive_health'].includes(category)) {
                hsd = (0.0077 * Math.pow(effectiveRPE, 3) - 0.274 * Math.pow(effectiveRPE, 2) +
                    3.1744 * effectiveRPE - 2.0733) * weighting[category] * duration
            } else if (['aerobic', 'body_composition', 'movement_quality'].includes(category)) {
                hsd = weighting[category] * effectiveRPE * duration
            } else if (category === 'muscle_fitness') {
                hsd = (0.0029 * Math.pow(effectiveRPE, 3) + 0.0777 * Math.pow(effectiveRPE, 2) +
                    1.1967) * weighting[category] * duration
            } else {
                hsd = 0
            }

            result[category] = Math.round(hsd / HSD_BASELINE[category])
        } else {
            result[category] = 0
        }
    })

    return result
}

// Hook for HSD Calculations
export const useHSDCalculations = (weeklySchedule, currentIntensity, hrZones, maxHR) => {
    const [hsdValues, setHsdValues] = useState({
        cardiometabolic: 0,
        aerobic: 0,
        bone: 0,
        body_composition: 0,
        muscle_fitness: 0,
        movement_quality: 0,
        balance: 0,
        cognitive_health: 0
    })

    useEffect(() => {
        const calculateTotalHSD = () => {
            const totalHSD = Object.entries(weeklySchedule[currentIntensity] || {}).reduce((acc, [_, activities]) => {
                activities.forEach(activity => {
                    const activityHSD = calculateHSD(
                        activity.type,
                        activity.duration,
                        activity.weighting,
                        // maxHR,
                        // hrZones,
                        // activity.rpe,
                        // activity.hrzone,
                        // activity.difficulty
                    )

                    Object.keys(activityHSD).forEach(key => {
                        acc[key] = (acc[key] || 0) + activityHSD[key]
                    })
                })

                return acc
            }, { ...hsdValues })

            setHsdValues(totalHSD)
        }

        calculateTotalHSD()
    }, [weeklySchedule, currentIntensity, hrZones, maxHR])

    return hsdValues
}

export const calculateTotalHSD = (weeklySchedule, assessmentData) => {
    // Add debug logging
    console.info('calculateTotalHSD called with:', {
        weeklySchedule,
        assessmentData
    })

    const newHsdByIntensity = {
        light: {
            aerobic_fitness: 0,
            balance: 0,
            bone: 0,
            movement_quality: 0,
            body_composition: 0,
            cognitive_health: 0,
            muscular_fitness: 0
        },
        moderate: {
            aerobic_fitness: 0,
            balance: 0,
            bone: 0,
            movement_quality: 0,
            body_composition: 0,
            cognitive_health: 0,
            muscular_fitness: 0
        },
        vigorous: {
            aerobic_fitness: 0,
            balance: 0,
            bone: 0,
            movement_quality: 0,
            body_composition: 0,
            cognitive_health: 0,
            muscular_fitness: 0
        }
    }

    try {
        // Iterate through all intensities and days
        Object.entries(weeklySchedule).forEach(([intensity, days]) => {
            Object.values(days).forEach(activities => {
                if (!Array.isArray(activities)) {
                    console.warn('Activities is not an array:', activities)
                    return
                }

                activities.forEach(activity => {
                    // Debug logging for each activity
                    console.info('Processing activity:', activity)

                    let intensityValue
                    // Get intensity value from activity
                    if (activity.rpe) {
                        intensityValue = parseFloat(activity.rpe)
                    }
                    else if (activity.difficultyLevel) {
                        switch (activity.difficultyLevel.toLowerCase()) {
                            case 'light': intensityValue = 3; break
                            case 'moderate': intensityValue = 5; break
                            case 'intense': intensityValue = 8; break
                            default: intensityValue = 0
                        }
                    }
                    else if (activity.heartRateZones && assessmentData &&
                        assessmentData.heart_rate_zones &&
                        assessmentData.heart_rate_zones.zones) {
                        const zoneKey = `zone${activity.heartRateZones}`
                        const zoneData = assessmentData.heart_rate_zones.zones[zoneKey]

                        if (zoneData) {
                            const avgHR = (zoneData.range[0] + zoneData.range[1]) / 2
                            const maxHR = assessmentData.heart_rate_zones.max_heart_rate
                            const peakHR = Math.min(zoneData.range[1] + 10, maxHR)
                            intensityValue = calculateRPE(maxHR, avgHR, peakHR, activity.type)
                        } else {
                            intensityValue = activity.heartRateZones * 2
                        }
                    }

                    // Debug logging for calculation inputs
                    console.info('Calculation inputs:', {
                        time: parseFloat(activity.time),
                        hsd: activity.hsd,
                        intensityValue
                    })

                    if (intensityValue && activity.time && activity.hsd) {
                        const activityHSD = calculateHSD(
                            parseFloat(activity.time),
                            activity.hsd,
                            intensityValue
                        )

                        // Debug logging for HSD results
                        console.info('Activity HSD calculated:', activityHSD)

                        // Map HSD categories to intensity-specific totals
                        Object.entries(activityHSD).forEach(([key, value]) => {
                            if (typeof value !== 'number') {
                                console.warn(`Invalid HSD value for ${key}:`, value)
                                return
                            }

                            switch (key) {
                                case 'aerobic':
                                    newHsdByIntensity[intensity].aerobic_fitness += value
                                    break
                                case 'balance':
                                    newHsdByIntensity[intensity].balance += value
                                    break
                                case 'body_composition':
                                    newHsdByIntensity[intensity].body_composition += value
                                    break
                                case 'cognitive_health':
                                    newHsdByIntensity[intensity].cognitive_health += value
                                    break
                                case 'movement_quality':
                                    newHsdByIntensity[intensity].movement_quality += value
                                    break
                                case 'muscle_fitness':
                                    newHsdByIntensity[intensity].muscular_fitness += value
                                    break
                                case 'bone':
                                    newHsdByIntensity[intensity].bone += value
                                    break
                            }
                        })
                    }
                })
            })
        })

        // Debug logging for final results
        console.info('Final HSD calculations:', newHsdByIntensity)
        return newHsdByIntensity

    } catch (error) {
        console.error('Error in calculateTotalHSD:', error)
        return newHsdByIntensity
    }
}
