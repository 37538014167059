import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Select } from 'antd'
import { Option } from 'antd/lib/mentions'

import { clientStyle } from './index.sass'
import DataTable from '../../components/UI/table'
import { getExerciseSignals, getSleepSignals, getHealthySignals } from '../../api/healthy_signals_booster'
import { getClients } from '../../api'
import { healthySignalsDataTypes, sleepDataSources } from '../../utils'
import moment from 'moment'
import { DownloadIcon } from '../../components/icons'

export const HealthySignalsBooster = () => {
    const [records, setRecords] = useState([])
    const [columns, setColumns] = useState([])
    const [clientList, setClientList] = useState([])
    const [typeOfData, setTypeOfData] = useState(null)
    const [selectedClientId, setSelectedClientId] = useState(null)
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [loading, setLoading] = useState()
    const [source, setSource] = useState()

    const getAllClients = async () => {
        const result = await getClients()
        setClientList(result.clients)
    }

    useEffect(async () => {
        const today = new Date()
        const sevenDaysAgo = new Date()
        sevenDaysAgo.setDate(today.getDate() - 7)
        setStartDate(sevenDaysAgo.toISOString().split('T')[0])
        setEndDate(today.toISOString().split('T')[0])
        setTypeOfData(healthySignalsDataTypes[0])
        setSource(sleepDataSources[0])
        getAllClients()
    }, [])

    useEffect(() => {
        if (typeOfData && selectedClientId && startDate && endDate) handleFilterChange()
    }, [typeOfData, selectedClientId, startDate, endDate, source])

    const updateColumns = (data) => {
        const cols = []
        if (data.length > 0) Object.keys(data[0]).forEach((col) => {
            cols.push({
                title: col.toUpperCase().replaceAll('_', ' '),
                key: col,
                render: (text, record) => (
                    <div style={{ width: col === 'record_date' ? '100px' : '' }}>{record[col]}</div>
                )
            })
        })
        setColumns(cols)
    }

    const handleFilterChange = async () => {
        let data = []
        setLoading(true)
        data = await getData()
        updateColumns(data)
        setRecords(data)
        setLoading(false)
    }

    const getData = async (responseType = 'json') => {
        let data = []
        switch (typeOfData) {
            case healthySignalsDataTypes[0]:
                data = await getExerciseSignals(selectedClientId, startDate, endDate, responseType)
                break
            case healthySignalsDataTypes[1]:
                data = await getSleepSignals(selectedClientId, startDate, endDate, responseType, source)
                break
            default:
                data = await getHealthySignals(selectedClientId, startDate, endDate, responseType, source)
                break
        }
        return (typeof data === 'string' && responseType !== 'csv') ? [] : data
    }

    const handleChangeTypeOfData = (val) => {
        setTypeOfData(val)
    }

    const handleChangeClient = (id) => {
        setSelectedClientId(id)
    }

    const handleChangeDate = (dates) => {
        if (!dates) return
        const [startDate, endDate] = dates
        setStartDate(startDate.format('YYYY-MM-DD'))
        setEndDate(endDate.format('YYYY-MM-DD'))
    }

    const handleChangeSourceOfData = (val) => {
        setSource(val)
    }

    const downloadFile = (content, filename, mimeType) => {
        const blob = new Blob([content], { type: mimeType })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.href = url
        a.download = filename
        a.click()
        URL.revokeObjectURL(url)
    }

    const handleClickDownloadCSV = async () => {
        if (!startDate || !endDate || !selectedClientId || !typeOfData) return
        const data = await getData('csv')
        downloadFile(data, `${typeOfData} (#${selectedClientId}) (${startDate}_to_${endDate})`, 'text/csv')
    }

    return (
        <div className={clientStyle}>
            <div className='wrapper'>
                <h2>Healthy Signals Data</h2>
                <div className="container">
                    <div className='filters'>
                        <div className='filter-dropdowns'>
                            <div>
                                <div>CLIENT</div>
                                <Select
                                    showSearch
                                    defaultValue=""
                                    onChange={handleChangeClient}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {clientList.map((client) => {
                                        return (
                                            <Option value={client.id}>{client.full_name}</Option>
                                        )
                                    })}
                                </Select>
                            </div>
                            <div>
                                <div>SELECT DATE RANGE</div>
                                <DatePicker.RangePicker
                                    placeholder={['Start Date', 'End Date']}
                                    value={[
                                        startDate ? moment(startDate) : null,
                                        endDate ? moment(endDate) : null
                                    ]}
                                    onChange={handleChangeDate}
                                />
                            </div>
                            <div>
                                <div>TYPE OF DATA</div>
                                <Select
                                    showSearch
                                    defaultValue={healthySignalsDataTypes[0]}
                                    onChange={handleChangeTypeOfData}
                                >
                                    {healthySignalsDataTypes.map((option, index) => {
                                        return (
                                            <Option value={option}>{option}</Option>
                                        )
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className='filter-dropdowns pt-18'>
                            <div>
                                <div>SOURCE</div>
                                <Select
                                    showSearch
                                    defaultValue={sleepDataSources[0]}
                                    onChange={handleChangeSourceOfData}
                                >
                                    {sleepDataSources.map((option, index) => {
                                        return (
                                            <Option value={option}>{option}</Option>
                                        )
                                    })}
                                </Select>
                            </div>
                            <div></div>
                            <div>
                                <div className='download-csv'>
                                    <Button
                                        type="primary"
                                        onClick={handleClickDownloadCSV}
                                    >
                                        <DownloadIcon />
                                        DOWNLOAD CSV
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='table-container'>
                        <DataTable
                            columns={columns || []}
                            dataSource={records}
                            loading={loading}
                            hidePagination={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
