import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import _, { get } from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Button } from 'antd'
import { SimpleUploadIcon } from '../icons' // BellIcon
import Wrapper from './wrapper'
import Banner from './comp/banner'
// import HealthProfile from './comp/health'
import Cardiometabolic from './sections/cardiometabolic'
import Aerobic from './sections/aerobic'
import PageLoading from '../UI/status/loading'
import { Footer, DraftContainer } from './draft'
import MovementQuality from './sections/movementQuality'
import CognitiveHealth from './sections/cognitiveHealth'
import MuscleSection from './sections/MuscleSection'
import IntroductionSection from './sections/IntroductionSection'
import PersonalInfoSection from './sections/PersonalInfoSection'
import HealthCompassSection from './sections/HealthCompassSection'
import BoneHealth from './sections/BoneHealth'
import BodyCompResultSection from './sections/BodyCompResultSection'
import Balance from './sections/balance'
import RecommendationSection from './sections/RecommendationSection'
import LifeStylePresentation from './LifeStylePresentation'
import RecommendationSectionV1 from './sections/RecommendationSectionv1'

export default Wrapper(PreviewReport)

function PreviewReport(props) {
  const {
    list,
    person,
    loginRole,
    arDetail,
    latest_bmi,
    latest_height,
    latest_weight,
    dynamicData,
    routeTypes,
    jumpToDataset,
    handleSave,
    handlePublish,
    showActivity,
    ageScopes,
    infinityLoadCount,
    maxLoadCount,
    next,
    hideSectionHandle,
    hideSection,
    trendMetrics,
    device_info,
    residence,
    exerciseRecommendationPlots,
    copilotDashboard
  } = props

  const previous = get(dynamicData, 'healthSpanDomainSummary.1', {})
  const latest = get(dynamicData, 'healthSpanDomainSummary.0', {})

  const getDataList = (snapshot, graph_type) => {
    return list.map((item) => {
      const detail =
        (snapshot[item.type] && _.cloneDeep(snapshot[item.type])) || {}
      detail.isDefaultValue = true
      if (detail.score_manual && detail.score_manual.value) {
        detail.isDefaultValue = false
      }
      return {
        ...item,
        ...detail
      }
    })
  }

  const compareData = getDataList(previous.snapshot || {}, 'previous')
  const dataList = getDataList(latest.snapshot || {}, 'latest')
  const hasMore = infinityLoadCount < maxLoadCount
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const isPublished = arDetail && arDetail.status === 1
  const reportId = arDetail && arDetail.id
  let useAIRecommendations = false

  if (
    (exerciseRecommendationPlots && Object.values(exerciseRecommendationPlots).some(v => v !== undefined)) ||
    (copilotDashboard && Object.values(copilotDashboard).some(v => v !== undefined))
  ) {
    useAIRecommendations = true
  }

  useEffect(() => {
    // Scroll to the top after first loaded
    window.scrollTo(0, 0)
  }, [])

  const commonProps = {
    hideSectionHandle,
    hideSection,
    arDetail,
    person,
    dynamicData,
    loginRole,
    view: 'Preview'
  }

  return arDetail ? (
    <InfiniteScroll
      dataLength={infinityLoadCount}
      next={next}
      hasMore={infinityLoadCount < maxLoadCount}
      loader={
        <h4
          style={{
            paddingBottom: 75,
            fontSize: 24,
            textAlign: 'center',
            color: '#4C6072'
          }}
        >
          Loading...
        </h4>
      }
      endMessage={
        <p style={{ textAlign: 'center' }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
    >
      <DraftContainer
        id="PDFAssessment"
        style={
          loginRole === 'User' ? { marginLeft: -25, marginRight: -25 } : {}
        }
      >
        <Banner
          person={person}
          period={arDetail.time_period}
          showPDF={true}
          isPublished={isPublished}
          reportId={reportId}
        />
        <IntroductionSection notes={arDetail.notes} {...commonProps} />
        <PersonalInfoSection
          {...{
            person,
            latest_bmi,
            latest_height,
            latest_weight,
            device_info,
            residence
          }}
        />
        <HealthCompassSection
          {...commonProps}
          {...{
            compareData,
            dataList,
            person,
            dynamicData
          }}
        />

        {dynamicData.overviewBodySystems && (
          <Cardiometabolic
            {...commonProps}
            {...{
              previous,
              latest,
              handleSave,
              trendMetrics
            }}
          />
        )}

        {(dynamicData.bodyCompResults || dynamicData.dxaSummary) && (
          <>
            <BoneHealth
              {...commonProps}
              {...{
                ageScopes,
                compareData,
                dataList,
                handleSave,
                infinityLoadCount
              }}
            />
            <BodyCompResultSection
              {...commonProps}
              {...{
                ageScopes,
                compareData,
                dataList,
                handleSave,
                infinityLoadCount
              }}
            />
          </>
        )}

        {dynamicData.cognitiveHealth && (
          <CognitiveHealth
            {...commonProps}
            {...{
              dataList,
              compareData,
              ageScopes
            }}
          />
        )}

        {dynamicData.movementBalance && (
          <Balance
            {...commonProps}
            {...{
              ageScopes,
              compareData,
              dataList,
              handleSave,
              infinityLoadCount
            }}
          />
        )}

        {dynamicData.movementQuality && (
          <>
            <MovementQuality
              {...commonProps}
              {...{
                ageScopes,
                compareData,
                dataList,
                handleSave
              }}
            />
          </>
        )}

        {(dynamicData.upperBodyAndCoreStrength ||
          dynamicData.lowerBodyStrength) && (
          <>
            <MuscleSection
              {...commonProps}
              {...{
                ageScopes,
                compareData,
                dataList,
                handleSave
              }}
            />
          </>
        )}

        {dynamicData.vo2Peak && (
          <Aerobic
            {...commonProps}
            {...{
              ageScopes,
              compareData,
              dataList,
              handleSave,
              infinityLoadCount
            }}
          />
        )}

        {!hasMore && (
          <>
            {useAIRecommendations ? (
              <>
                <LifeStylePresentation
                  {...commonProps}
                  arDetail={arDetail}
                  person={person}
                  lsd={_.get(dynamicData, 'lifeStyleSummary', {})}
                  editabled={false}
                />
                <RecommendationSection
                  {...commonProps}
                  person={person}
                  lsd={_.get(dynamicData, 'lifeStyleSummary', {})}
                  hsd={_.get(dynamicData, 'healthSpanDomainSummary', {})}
                  editabled={false}
                  arDetail={arDetail}
                  exerciseRecommendationPlots={exerciseRecommendationPlots}
                  copilotDashboard={copilotDashboard}
                />
              </>
            ) : (
              <RecommendationSectionV1
                {...commonProps}
                editabled={false}
                arDetail={arDetail}
              />
            )}
          </>
        )}
        {/* {(arDetail.status === 1 || infinityLoadCount > 1) && (
          <Aerobic
            {...commonProps}
            {...{
              ageScopes,
              compareData,
              dataList,
              routeTypes,
              jumpToDataset,
              infinityLoadCount
            }}
          />
        )}

        {dynamicData.movementQuality && (
          <MovementQuality
            {...commonProps}
            {...{
              ageScopes,
              compareData,
              dataList
            }}
          />
        )}
        {dynamicData.cognitiveHealth && (
          <CognitiveHealth
            {...commonProps}
            {...{
              compareData,
              dataList,
              ageScopes
            }}
          />
        )} */}

        {(loginRole === 'Expert' || loginRole === 'InternalAdmin') && (
          <Footer id="footer-preview">
            <div>
              {arDetail.status === 0 && (
                <Button
                  ghost
                  type="primary"
                  onClick={() => handleSave(null, 'edit')}
                >
                  BACK TO EDIT
                </Button>
              )}
              <Button ghost type="primary" onClick={() => showActivity()}>
                ACTIVITY
              </Button>
            </div>
            <div>
              {/* <Button ghost type="primary" onClick={handlePreview}>
            <ViewIcon />
            PREVIEW
          </Button> */}
              {arDetail.status === 0 && (
                <Button type="primary" onClick={handlePublish}>
                  <SimpleUploadIcon />
                  PUBLISH
                </Button>
              )}
              {/* <Button
              type="primary"
              onClick={handleNotify}
              disabled={!arDetail.published_at}
            >
              <BellIcon />
              NOTIFY CLIENT
            </Button> */}
            </div>
          </Footer>
        )}
      </DraftContainer>
    </InfiniteScroll>
  ) : (
    <PageLoading />
  )
}
