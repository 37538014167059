import React from 'react'
import { RangeColors } from '../../utils'

export const TrainingGauge = ({
  value,
  label = 'Training Load',
  subtitle = 'Cumulative Physical Exertion',
  min = 100,
  max = 300
}) => {
  const radius = 800
  const strokeWidth = 15
  const mainStrokeWidth = 5 // Reduced from 15 for main arc
  const secondaryStrokeWidth = 10 // Original width for light blue arc
  const valueStrokeWidth = 10 // Increased width for value arc
  // Reduced angle range to 60 degrees (240° to 300° for bottom arc)
  const startAngle = 240 // Changed from 210
  const endAngle = 300 // Changed from 330
  const angleRange = Math.abs(endAngle - startAngle)

  // Convert polar coordinates to cartesian
  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = (angleInDegrees * Math.PI) / 180.0
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians)
    }
  }

  // Calculate value angle
  const getValueAngle = (val) => {
    const normalizedValue = Math.min(Math.max(val, min), max)
    const valuePercentage = (normalizedValue - min) / (max - min)
    const angle = startAngle + angleRange * valuePercentage
    return angle
  }

  // Generate tick marks
  const generateTicks = () => {
    const ticks = []
    const majorTickValues = [min, (min + max) / 2, max]

    majorTickValues.forEach((tickValue) => {
      const percentage = (tickValue - min) / (max - min)
      const angle = startAngle + angleRange * percentage
      const outerPoint = polarToCartesian(
        radius,
        radius,
        radius - strokeWidth / 2,
        angle
      )
      const innerPoint = polarToCartesian(
        radius,
        radius,
        radius - strokeWidth - 10,
        angle
      )
      const labelPoint = polarToCartesian(
        radius,
        radius,
        radius - strokeWidth - 25,
        angle
      )

      ticks.push({
        line: {
          x1: outerPoint.x,
          y1: outerPoint.y,
          x2: innerPoint.x,
          y2: innerPoint.y
        },
        label: {
          x: labelPoint.x,
          y: labelPoint.y,
          value: tickValue
        }
      })
    })

    return ticks
  }

  // Calculate the Y position of the bottom of the arc
  const arcBottomY = polarToCartesian(
    radius,
    radius,
    radius - strokeWidth / 2,
    270
  ).y

  // Calculate text positions relative to the arc's bottom
  const valueY = arcBottomY + 100 // 60px below the arc
  const labelY = arcBottomY + 180 // 80px below the arc
  const subtitleY = arcBottomY + 210 // 100px below the arc

  // Calculate the actual dimensions we need based on the arc
  const startPoint = polarToCartesian(radius, radius, radius, startAngle)
  const endPoint = polarToCartesian(radius, radius, radius, endAngle)

  // Calculate the actual bounds of our arc
  const minX = Math.min(startPoint.x, endPoint.x)
  const maxX = Math.max(startPoint.x, endPoint.x)
  const minY = Math.min(startPoint.y, endPoint.y)
  const maxY = Math.max(startPoint.y, endPoint.y)

  const width = maxX - minX
  const height = maxY - minY

  // Calculate radii to make arcs flush
  const mainArcRadius = radius
  const secondaryArcRadius =
    radius - (mainStrokeWidth / 2 + secondaryStrokeWidth / 2)
  const valueArcRadius = secondaryArcRadius // Changed to match secondary arc radius

  // Update viewBox calculation to include space for the largest stroke width
  const padding = Math.max(strokeWidth, valueStrokeWidth) * 2 // Changed to account for valueStrokeWidth
  const viewBoxHeight = height + padding

  // Generate SVG arc path
  const describeArc = (x, y, radius, startAngle, endAngle) => {
    // Add a small padding angle to account for rounded caps
    const paddingAngle = 0.5 // degrees
    const adjustedStartAngle = startAngle + paddingAngle
    const adjustedEndAngle = endAngle - paddingAngle

    const start = polarToCartesian(x, y, radius, adjustedEndAngle)
    const end = polarToCartesian(x, y, radius, adjustedStartAngle)
    const largeArcFlag = (endAngle - startAngle + 360) % 360 <= 180 ? '0' : '1'

    return [
      'M',
      start.x,
      start.y,
      'A',
      radius,
      radius,
      0,
      largeArcFlag,
      0,
      end.x,
      end.y
    ].join(' ')
  }

  const describeArcWithoutPadding = (x, y, radius, startAngle, endAngle) => {
    const start = polarToCartesian(x, y, radius, startAngle)
    const end = polarToCartesian(x, y, radius, endAngle)
    const largeArcFlag = (endAngle - startAngle + 360) % 360 <= 180 ? '0' : '1'
    const sweepFlag = 1 // Changed from 0 to 1 to reverse the arc direction

    return [
      'M',
      start.x,
      start.y,
      'A',
      radius,
      radius,
      0,
      largeArcFlag,
      sweepFlag,
      end.x,
      end.y
    ].join(' ')
  }

  // Get the angle corresponding to the current value
  const valueAngle = getValueAngle(value)

  return (
    <div
      style={{
        background: 'white', // Changed from gradient to white
        borderRadius: '5px', // Changed from 20px to 5px
        padding: '16px',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'inherit'
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox={`${minX - padding / 2} ${minY - padding / 2} ${width +
          padding} ${viewBoxHeight}`}
        preserveAspectRatio="xMidYMid meet"
      >
        {/* Main Arc - Outermost */}
        <path
          d={describeArc(radius, radius, mainArcRadius, startAngle, endAngle)}
          fill="none"
          stroke="#e6e9f2" // Changed to light grey
          strokeWidth={mainStrokeWidth}
          strokeLinecap="butt"
        />

        {/* Secondary Arc - Middle */}
        <path
          d={describeArc(
            radius,
            radius,
            secondaryArcRadius,
            startAngle,
            endAngle
          )}
          fill="none"
          stroke="#f4f6f8" // Changed to lighter grey
          strokeWidth={secondaryStrokeWidth}
          strokeLinecap="butt"
        />

        {/* Value Indicator - Multiple stacked arcs */}
        {(() => {
          const arcRange = 3
          const numberOfArcs = 5
          const individualStrokeWidth = 5
          const radiusStep = 4
          const baseRadiusOffset = -10

          return Array.from({ length: numberOfArcs }).map((_, index) => {
            const startValueAngle = valueAngle - arcRange
            const endValueAngle = valueAngle + arcRange
            const indicatorRadius =
              secondaryArcRadius + baseRadiusOffset + index * radiusStep

            return (
              <path
                key={`value-indicator-${index}`}
                d={describeArcWithoutPadding(
                  radius,
                  radius,
                  indicatorRadius,
                  startValueAngle,
                  endValueAngle
                )}
                fill={RangeColors.green} // Changed to standard green
                stroke={RangeColors.green} // Changed to standard green
                strokeWidth={individualStrokeWidth}
                strokeLinecap="butt"
                filter={index < 3 ? 'url(#glow)' : undefined}
              />
            )
          })
        })()}

        {/* Tick Marks and Labels */}
        {generateTicks().map((tick, i) => (
          <g key={i}>
            <line
              {...tick.line}
              stroke="#4c6072" // Changed to standard text color
              strokeWidth="2"
              strokeLinecap="butt"
            />
            <text
              x={tick.label.x}
              y={tick.label.y}
              textAnchor="middle"
              dominantBaseline="middle"
              fill="#4c6072" // Changed to standard text color
              fontSize="14"
              style={{ fontFamily: 'inherit' }}
            >
              {tick.label.value}
            </text>
          </g>
        ))}

        {/* Center Value */}
        <text
          x={radius}
          y={valueY}
          textAnchor="middle"
          dominantBaseline="middle"
          fill="#4c6072" // Changed to standard text color
          style={{
            fontSize: '48px',
            fontWeight: '500',
            fontFamily: 'inherit'
          }}
        >
          {Math.round(value)}
        </text>

        {/* Title */}
        <text
          x={radius}
          y={labelY}
          textAnchor="middle"
          fill="#4c6072" // Changed to standard text color
          style={{
            fontSize: '30px',
            fontWeight: '500',
            fontFamily: 'inherit',
            letterSpacing: '0.05em'
          }}
        >
          {label}
        </text>

        {/* Subtitle */}
        <text
          x={radius}
          y={subtitleY}
          textAnchor="middle"
          fill="#4c6072" // Changed to standard text color
          style={{
            fontSize: '20px',
            fontWeight: '400',
            fontFamily: 'inherit'
          }}
        >
          {subtitle}
        </text>
      </svg>
    </div>
  )
}
