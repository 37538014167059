import React, {useState, useEffect} from 'react'
import { Divider } from 'antd'
import HealthCard from '../../healthScore/card'
import Information from '../../UI/extraInformation'
import HideWrap from '../comp/hideWrap'
import DxaSummary from '../comp/dxaSummary'
import ConditionalHideWrap from './ConditionalHidewrap'
import LowlevelMetrics from './LowleveMetrics'
import { formatDisplayName, getRangeByAgeAndGender } from './LowLevelMetricsHelpers'
import { getBiomarkerById, getRangeAgeScopes } from '../../../api'
import { getMetricById } from '../../../api'
import { IGNORED_METRICS_FOR_LIFESTYLE_RECOMMENDATIONS } from '../../../utils/constant'

export default function BoneHealth(props) {
  const {
    person,
    hideSectionHandle,
    hideSection,
    handleSave,
    compareData,
    dataList,
    view,
    loginRole,
    save,
    onScoreChangeHandle,
    cancelEdit,
    handleCommendSave,
    updateEditingCount,
    ageScopes,
    arDetail: { notes, status, detail },
    dynamicData: { ui_version, dxaSummary }
  } = props

    const [valueResults, setValueResults] = useState([])
  
    useEffect(() => {
      fetchBodyCompMetricsDetails()
    }, [])
  
    const fetchBodyCompMetricsDetails = async () => {
      const bodyCompMetrics = props.dynamicData.dxaSummary
      const metricKeys = Object.keys(bodyCompMetrics)
      const ageScopes = (await getRangeAgeScopes()).age_scope
  
      const biomarkerDetailsPromises = metricKeys.map(async (metricKey) => {
        const metric =
          props &&
          props.dynamicData &&
          props.dynamicData.dxaSummary[metricKey]

        if (!metric) {
          return null
        }
        if (metric.value && metric.value.color !== null && ['orange', 'red', 'yellow'].includes(metric.value.color) && !IGNORED_METRICS_FOR_LIFESTYLE_RECOMMENDATIONS.includes(metric.label)) {
          const conventionalRanges = metric.value.ranges
            ? getRangeByAgeAndGender(metric.value.ranges, person, ageScopes)
            : []
  
          if (metric && metric.value.metricId) {
            const biomarkerDetail = await getMetricById(
              metric.value.metricId,
              null,
              'bone-density'
            )
  
            const introduction =
              biomarkerDetail && biomarkerDetail.body_comp.introduction
                ? biomarkerDetail.body_comp.introduction
                : 'No introduction available'
  
            return {
              displayName: formatDisplayName(metric.label),
              value: metric.value.value,
              color: metric.value.color,
              level: metric.value.level,
              conventionalRanges,
              biomarkerDetail,
              introduction,
              sectionName: 'Bone Health'
            }
          }
        }
  
        return null
      })
  
      const results = (await Promise.all(biomarkerDetailsPromises)).filter(
        (result) => result !== null
      )
      setValueResults(results)
    }
  
  const boneData = dataList.find((item) => item.type === 'bone_health') || {}

  const boneCompare =
    compareData.find((item) => item.type === 'bone_health') || {}

  const bodyComp = notes.find((item) => item.pillar === 'bone health')

  return (
    <ConditionalHideWrap
      name="Bone Health"
      isSection={true}
      syncStatus={hideSectionHandle}
      status={hideSection}
      view={view}
      condition={
        view === 'Draft' || (view === 'Preview' && !hideSection.includes('Bone Health'))
      }
    >
      <section>
        <h1 className="section-title">Bone Health</h1>
        <ConditionalHideWrap
          name="Bone Health - summary"
          title="summary"
          syncStatus={hideSectionHandle}
          status={hideSection}
          view={view}
          condition={
            view === 'Draft' || (view === 'Preview' && !hideSection.includes('Bone Health - summary'))
          }
        >
          <div className="sub-title">Summary</div>
          <HealthCard
            {...boneData}
            compare={boneCompare}
            save={save}
            loginRole={loginRole}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            onChangeHandle={onScoreChangeHandle}
            disEdit={status === 1 || view === 'Preview'}
            cancel={cancelEdit}
          />
          <Information
            title="COMMENT/NOTE"
            disMarkDone={true}
            initialValue={bodyComp ? bodyComp.memo : ''}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            updateCommend={(value, status) =>
              handleCommendSave(value, 'bone health', status)
            }
            updateEditingCount={updateEditingCount}
          />
          <Divider />
        </ConditionalHideWrap>
        {dxaSummary && (
          <>
            <ConditionalHideWrap
              name="Body Composition - DXA"
              title="DXA Results Summary"
              syncStatus={hideSectionHandle}
              status={hideSection}
              view={view}
              condition={
                view === 'Draft' || (view === 'Preview' && !hideSection.includes('Body Composition - DXA'))
              }
            >
              <div className="sub-title">DXA Results Summary</div>
              <DxaSummary
                {...{
                  view,
                  person,
                  detail,
                  handleSave,
                  dxaSummary,
                  ui_version,
                  ageScopes,
                  notes,
                  handleCommendSave,
                  updateEditingCount
                }}
              />
            </ConditionalHideWrap>
          </>
        )}
        <LowlevelMetrics metricValues={valueResults} view={view} hideSection={hideSection} hideSectionHandle={hideSectionHandle} />
      </section>
    </ConditionalHideWrap>
  )
}
