import React from 'react'
import { getColor } from './utils'
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
    ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis,
    PolarRadiusAxis, Radar, BarChart, Bar, ComposedChart, Label, Cell, LabelList
} from 'recharts'

export const HealthDomainChart = ({ data, height = 250, y_axis = 350 }) => (
    <ResponsiveContainer width="100%" height={height}>
        <BarChart
            data={data.labels.map((label, i) => ({
                name: label.replace(/_/g, ' '),
                value: data.values[i]
            }))}
            layout="horizontal"
            margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
                dataKey="name"
                type="category"
                tick={{ fontSize: 10, angle: -20, dy: 20 }}
                interval={0}
            />
            <YAxis
                type="number"
                domain={[0, y_axis]}  // Set fixed domain
            />
            <Tooltip />
            <Bar
                dataKey="value"
                barSize={30}
            >
                {data.values.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={getColor(entry)} />
                ))}
                <LabelList
                    dataKey="value"
                    position="top"
                    formatter={(value) => value.toFixed(1)}
                    style={{ fill: '#666', fontSize: 12 }}
                />
            </Bar>
        </BarChart>
    </ResponsiveContainer>
)

export const WeeklyAllocationChart = ({ data }) => (
    <ResponsiveContainer width="100%" height={200}>
        <BarChart
            data={data.labels.map((label, i) => ({
                name: label,
                value: data.values[i]
            }))}
            layout="vertical"
            margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
                type="number"
                domain={[0, 'auto']}
            />
            <YAxis
                dataKey="name"
                type="category"
                width={140}
                tick={{ fontSize: 12 }}
            />
            <Tooltip />
            <Bar dataKey="value" fill="#8884d8" barSize={20} />
        </BarChart>
    </ResponsiveContainer>
)
