import React from 'react'
import { StyledSection } from '../StyledSections'
import {
    ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis,
    PolarRadiusAxis, Radar
} from 'recharts'

const HealthSpanSection = ({ hsd }) => {
    // Extract latest values from the nested structure
    const getLatestValue = (domain) => {
        if (!hsd || !hsd['0'] || !hsd['0'].snapshot || !hsd['0'].snapshot[domain]) {
            return 0
        }
        return hsd['0'].snapshot[domain].score_auto || 0
    }

    return (
        <StyledSection title="Health Span Domains" style={{ overflow: 'hidden' }}>
            <div className="hsd-chart-wrapper">
                <ResponsiveContainer width="100%" height={250}>
                    <RadarChart data={[
                        {
                            subject: 'Aerobic Fitness',
                            value: getLatestValue('aerobic_fitness')
                        },
                        {
                            subject: 'Balance',
                            value: getLatestValue('balance')
                        },
                        {
                            subject: 'Body Composition',
                            value: getLatestValue('body_composition')
                        },
                        {
                            subject: 'Cognitive Health',
                            value: getLatestValue('cognitive_health')
                        },
                        {
                            subject: 'Joint Fitness',
                            value: getLatestValue('joint_fitness')
                        },
                        {
                            subject: 'Muscular Fitness',
                            value: getLatestValue('muscular_fitness')
                        }
                    ]}>
                        <PolarGrid />
                        <PolarAngleAxis
                            dataKey="subject"
                            tick={{ fontSize: 10 }}
                        />
                        <PolarRadiusAxis
                            domain={[0, 100]}
                            tickCount={6}
                        />
                        <Radar
                            name="HSD"
                            dataKey="value"
                            stroke="#8884d8"
                            fill="#8884d8"
                            fillOpacity={0.6}
                        />
                    </RadarChart>
                </ResponsiveContainer>
            </div>
        </StyledSection>
    )
}

export default HealthSpanSection 
