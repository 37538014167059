import React, { useMemo } from 'react'
import { RangeColors } from '../../utils'

// Define colors as constants at the top
const COLORS = {
  SLEEP_GOOD: RangeColors.green, // Good sleep
  SLEEP_BAD: RangeColors.yellow, // Poor sleep
  TRANSITION: '#B6BFDE', // Keep light blue for transitions
  INTERRUPTION: RangeColors.red, // Use red for interruptions
  TEXT: '#4c6072', // Updated from white to match other graphs
  ICON: '#4c6072' // Added for icon consistency
}

// Update SLEEP_COLORS at the top with the file's other constants
const SLEEP_COLORS = {
  deep: '#0000FF', // Bright blue
  light: '#9C27B0', // Vivid purple
  rem: '#673AB7', // Deep violet
  awake: '#E91E63' // Bright pink
}

// Replace emoji constants with SVG paths
const ICONS = {
  EXERCISE: {
    path:
      'M13.49 5.48c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm-3.6 13.9l1-4.4 2.1 2v6h2v-7.5l-2.1-2 .6-3c1.3 1.5 3.3 2.5 5.5 2.5v-2c-1.9 0-3.5-1-4.3-2.4l-1-1.6c-.4-.6-1-1-1.7-1-.3 0-.5.1-.8.1l-5.2 2.2v4.7h2v-3.4l1.8-.7-1.6 8.1-4.9-1-.4 2 7 1.4z',
    viewBox: '0 0 24 24'
  },
  BEDTIME: {
    path:
      'M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9c0-.46-.04-.92-.1-1.36-.98 1.37-2.58 2.26-4.4 2.26-3.03 0-5.5-2.47-5.5-5.5 0-1.82.89-3.42 2.26-4.4-.44-.06-.9-.1-1.36-.1z',
    viewBox: '0 0 24 24'
  },
  TECH: {
    path:
      'M17 1.01L7 1c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99zM17 19H7V5h10v14z',
    viewBox: '0 0 24 24'
  },
  MEDICAL: {
    path:
      'M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z',
    viewBox: '0 0 24 24' // Medical clipboard with checkmark
  },
  PRESCRIPTION: {
    path:
      'M20 12c0-2.21-1.79-4-4-4H8c-2.21 0-4 1.79-4 4s1.79 4 4 4h8c2.21 0 4-1.79 4-4zm-12 0c0-1.1.9-2 2-2h4v4h-4c-1.1 0-2-.9-2-2z',
    viewBox: '0 0 24 24' // Horizontal oval pill, half filled
  }
}

const mapFrequencyToNights = (frequency) => {
  if (!frequency) return [0, 0] // Handle undefined/null case

  switch (frequency) {
    case 'Rarely/never':
      return [0, 0]
    case '3 or fewer nights per week':
      return [0, 3]
    case '4-5 nights per week':
      return [4, 5]
    case '6-7 nights per week':
      return [6, 7]
    default:
      return [0, 0]
  }
}

const mapAlcoholUsage = (usage) => {
  switch (usage) {
    case '0 alcoholic drinks':
      return [0, 0]
    case '1-2 alcoholic drinks':
      return [1, 2]
    case '3 or more alcoholic drinks':
      return [3, 7]
    default:
      return 0
  }
}

const mapTechUsage = (usage) => {
  switch (usage) {
    case '0 nights per week or Never':
      return [0, 0]
    case '1-3 nights per week':
      return [1, 3]
    case '4 or more nights per week':
      return [4, 6]
    default:
      return [0, 0]
  }
}

const mapSleepAidsUsage = (usage) => {
  switch (usage) {
    case '0 nights per week':
      return [0, 0]
    case '1-2 nights per week':
      return [1, 2]
    case '3 or more nights per week':
      return [3, 6]
    case 'Rarely or only when traveling':
      return [0, 0]
    default:
      return [0, 0]
  }
}

// Map sleep transition string to range [min, max]
const mapTransition = (transitionStr) => {
  if (transitionStr === '0-5 minutes') {
    return [0, 5]
  } else if (transitionStr === '5-20 minutes') {
    return [5, 20]
  } else if (transitionStr === '21-40 minutes') {
    return [21, 40]
  } else if (transitionStr === 'more than 40 minutes') {
    return [41, 60]
  } else {
    return [0, 0]
  }
}

const mapResleep = (resleepStr) => {
  if (resleepStr === '10 or less minutes') {
    return [0, 10]
  } else if (resleepStr === '11-40') {
    return [11, 40]
  } else if (resleepStr === 'More than 40') {
    return [41, 60]
  } else {
    return [0, 0]
  }
}

const mapStayAsleep = (stayAsleepStr) => {
  if (stayAsleepStr === '3 or fewer nights per week') {
    return [0, 3]
  } else if (stayAsleepStr === '4-5 nights per week') {
    return [4, 5]
  } else if (stayAsleepStr === '6-7 nights per week') {
    return [6, 7]
  } else {
    return [0, 0]
  }
}

// Map sleep interruptions string to range [min, max]
const mapInterruptionsRange = (countStr) => {
  if (countStr === '0-2 times') {
    return [0, 2]
  } else if (countStr === '3 times') {
    return [3, 3]
  } else if (countStr === '4 or more times') {
    return [4, 6]
  } else {
    return [0, 0]
  }
}

// Add mapping function for worry frequency
const mapWorryToOpacity = (frequency) => {
  switch (frequency) {
    case 'Always/Often':
      return 1.0
    case 'Sometimes':
      return 0.6
    case 'Rarely/Never':
      return 0.2
    default:
      return 0
  }
}

const mapSnoringToOpacity = (frequency) => {
  switch (frequency) {
    case 'Always':
      return 1.0
    case 'Sometimes':
      return 0.6
    case 'Never':
      return 0.2
    default:
      return 0
  }
}

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

// Add GlobalIndicators component
const GlobalIndicators = ({ sleepData, padding }) => {
  const spacing = 25
  const width = 600
  const xPosition = width - 30
  const verticalOffset = 50
  const tagWidth = 80 // Fixed width for all tags
  const tagHeight = 22 // Fixed height for all tags
  const tagColor = '#FFFFFF'

  const Tag = ({ text, y, opacity = 1 }) => (
    <g>
      <rect
        x={xPosition - tagWidth - 10}
        y={y - tagHeight / 2}
        width={tagWidth}
        height={tagHeight}
        fill="none"
        stroke={COLORS.TEXT} // Updated from white to COLORS.TEXT
        strokeWidth={1}
        rx={4}
        opacity={opacity}
      />
      <text
        x={xPosition - tagWidth / 2 - 10}
        y={y}
        textAnchor="middle"
        fontSize="12"
        fill={COLORS.TEXT} // Updated from white to COLORS.TEXT
        dominantBaseline="middle"
        opacity={opacity}
      >
        {text}
      </text>
    </g>
  )

  return (
    <g>
      {/* Worry Frequency - only show if not Rarely/Never */}
      {sleepData.worry_frequency !== 'Rarely/Never' && (
        <Tag
          text="Worry"
          y={padding + spacing - verticalOffset}
          opacity={mapWorryToOpacity(sleepData.worry_frequency)}
        />
      )}

      {/* Snoring - only show if not Never */}
      {sleepData.snoring !== 'Never' && (
        <Tag
          text="Snoring"
          y={padding + spacing * 2 - verticalOffset}
          opacity={mapSnoringToOpacity(sleepData.snoring)}
        />
      )}

      {/* Medical Diagnosis - only show if true */}
      {sleepData.medical_diagnosis && (
        <Tag text="Diagnosis" y={padding + spacing * 3 - verticalOffset} />
      )}

      {/* Medical Device - only show if true */}
      {sleepData.medical_device && (
        <Tag text="Device" y={padding + spacing * 4 - verticalOffset} />
      )}
    </g>
  )
}

export const SleepViz = ({ sleepData, sleepLevelsProcessed }) => {
  // Add validation for sleepData
  if (!sleepData) {
    console.warn('No sleep data provided to SleepViz')
    return null // Or return a placeholder/loading state
  }

  const width = 600
  const height = 400
  const barWidth = 20
  const padding = 60
  const leftMargin = 30

  // Update time labels for y-axis - now from 9 PM to 9 AM
  const timeLabels = ['9 PM', '11 PM', '1 AM', '3 AM', '5 AM', '7 AM', '9 AM']

  // Helper function to convert time string to minutes since midnight
  const timeToMinutes = (timeStr) => {
    if (!timeStr || typeof timeStr !== 'string') {
      console.warn('Invalid time string provided to timeToMinutes:', timeStr)
      return 0
    }

    try {
      const [time, period] = timeStr.split(' ')
      const [hours, minutes] = time.split(':').map(Number)

      if (isNaN(hours) || isNaN(minutes)) {
        console.warn('Invalid time format in timeToMinutes:', timeStr)
        return 0
      }

      let totalMinutes = hours * 60 + minutes

      // Adjust for PM times
      if (period === 'PM' && hours !== 12) {
        totalMinutes += 12 * 60
      }
      // Adjust for AM times - 12 AM should be 0
      if (period === 'AM' && hours === 12) {
        totalMinutes = minutes
      }

      return totalMinutes
    } catch (error) {
      console.warn('Error processing time in timeToMinutes:', timeStr, error)
      return 0
    }
  }

  // Helper function to normalize wake time based on sleep time
  const normalizeWakeTime = (wakeTimeStr, sleepTimeMinutes) => {
    if (!wakeTimeStr || typeof wakeTimeStr !== 'string') {
      console.warn(
        'Invalid wake time string provided to normalizeWakeTime:',
        wakeTimeStr
      )
      return sleepTimeMinutes + 8 * 60 // Default to 8 hours after sleep time
    }

    try {
      const [time, period] = wakeTimeStr.split(' ')
      const [hours, minutes] = time.split(':').map(Number)

      if (isNaN(hours) || isNaN(minutes)) {
        console.warn('Invalid time format in normalizeWakeTime:', wakeTimeStr)
        return sleepTimeMinutes + 8 * 60
      }

      let wakeTimeMinutes = hours * 60 + minutes

      // Adjust for PM times
      if (period === 'PM' && hours !== 12) {
        wakeTimeMinutes += 12 * 60
      }
      // Adjust for AM times - 12 AM should be 0
      if (period === 'AM' && hours === 12) {
        wakeTimeMinutes = minutes
      }

      // If wake time is earlier than sleep time, add 24 hours
      if (wakeTimeMinutes < sleepTimeMinutes) {
        wakeTimeMinutes += 24 * 60
      }

      return wakeTimeMinutes
    } catch (error) {
      console.warn(
        'Error processing time in normalizeWakeTime:',
        wakeTimeStr,
        error
      )
      return sleepTimeMinutes + 8 * 60 // Default to 8 hours after sleep time
    }
  }

  // Update normalizeMinutesForDisplay to use 9 PM (21:00) as base
  const normalizeMinutesForDisplay = (minutes) => {
    // Convert all times to be relative to 9 PM (21:00)
    let normalizedMinutes = minutes
    if (normalizedMinutes < 21 * 60) {
      // If time is AM or before 9 PM
      normalizedMinutes += 24 * 60 // Add 24 hours
    }
    return normalizedMinutes - 21 * 60 // Subtract 21 hours to start at 0
  }

  // Update minutesToY to use 12-hour range
  const minutesToY = (minutes) => {
    const displayMinutes = normalizeMinutesForDisplay(minutes)
    const totalDisplayMinutes = 12 * 60 // 12 hours from 9 PM to 9 AM
    return (
      padding + (displayMinutes / totalDisplayMinutes) * (height - 2 * padding)
    )
  }

  // Helper function to get random integer between min and max (inclusive)
  const getRandomInt = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  // Generate 7 days of sleep data
  const generateWeekData = () => {
    // Add safety checks for required data
    if (!sleepData || !sleepData.sleep_time || !sleepData.wake_time) {
      console.warn('Missing required sleep data')
      return Array(7).fill({
        day: 0,
        wakeTime: 0,
        sleepTime: 0,
        hasConsistentSleep: false,
        hasConsistentWake: false,
        staysAsleep: false,
        interruptions: [],
        transitionTime: 0,
        behaviors: {
          exercise: false,
          bedtime: false,
          tech: false,
          medicalAids: false,
          prescription: false
        }
      })
    }

    const baseSleepTime = timeToMinutes(sleepData.sleep_time)
    const baseWakeTime = normalizeWakeTime(sleepData.wake_time, baseSleepTime)
    const [minInterruptions, maxInterruptions] = mapInterruptionsRange(
      sleepData.sleep_interruptions || '0-2 times'
    )
    const [minTransition, maxTransition] = mapTransition(
      sleepData.sleep_transition || '0-5 minutes'
    )

    // Get number of consistent days with safety checks
    const sleepConsistentDays = parseInt(sleepData.sleep_consistency) || 0
    const wakeConsistentDays = parseInt(sleepData.wake_consistency) || 0

    // Create array of indices and shuffle them
    const indices = Array.from({ length: 7 }, (_, i) => i)
    const shuffledIndices = [...indices].sort(() => Math.random() - 0.5)

    // Get consistent day indices for sleep and wake
    const consistentSleepDays = new Set(
      shuffledIndices.slice(0, sleepConsistentDays)
    )
    const consistentWakeDays = new Set(
      shuffledIndices.slice(0, wakeConsistentDays)
    )

    const [minResleep, maxResleep] = mapResleep(sleepData.reinterruption_time)

    // Generate random days for each behavior
    const [minExercise, maxExercise] = mapFrequencyToNights(
      sleepData.exercise_after_7pm
    )
    const exerciseDays = new Set(
      shuffleArray([...Array(7).keys()]).slice(
        0,
        getRandomInt(minExercise, maxExercise)
      )
    )

    const [minBedtime, maxBedtime] = mapFrequencyToNights(
      sleepData.bedtime_routine
    )
    const bedtimeDays = new Set(
      shuffleArray([...Array(7).keys()]).slice(
        0,
        getRandomInt(minBedtime, maxBedtime)
      )
    )

    const [minTech, maxTech] = mapTechUsage(sleepData.tech_usage)
    const techDays = new Set(
      shuffleArray([...Array(7).keys()]).slice(
        0,
        getRandomInt(minTech, maxTech)
      )
    )

    const [minMedicalAids, maxMedicalAids] = mapSleepAidsUsage(
      sleepData.medical_aids
    )
    const medicalAidsDays = new Set(
      shuffleArray([...Array(7).keys()]).slice(
        0,
        getRandomInt(minMedicalAids, maxMedicalAids)
      )
    )

    const [minPrescription, maxPrescription] = mapSleepAidsUsage(
      sleepData.prescription_sleep
    )
    const prescriptionDays = new Set(
      shuffleArray([...Array(7).keys()]).slice(
        0,
        getRandomInt(minPrescription, maxPrescription)
      )
    )

    return Array.from({ length: 7 }, (_, i) => {
      // Determine if this day should have consistent sleep and/or wake times
      const hasConsistentSleep = consistentSleepDays.has(i)
      const hasConsistentWake = consistentWakeDays.has(i)
      const [minStayAsleep, maxStayAsleep] = mapStayAsleep(
        sleepData.stay_asleep
      )
      const staysAsleep = i < minStayAsleep || i > maxStayAsleep

      // Generate sleep time variation
      const sleepVariation = hasConsistentSleep
        ? (Math.random() - 0.5) * 30 // ±15 minutes
        : (Math.random() - 0.5) * 120 // ±60 minutes

      // Generate wake time variation independently
      const wakeVariation = hasConsistentWake
        ? (Math.random() - 0.5) * 30 // ±15 minutes
        : (Math.random() - 0.5) * 120 // ±60 minutes

      const daySleepTime = baseSleepTime + sleepVariation
      const dayWakeTime = baseWakeTime + wakeVariation

      // Generate interruption time relative to sleep period
      const generateInterruptionTime = () => {
        // Calculate sleep duration considering wraparound
        const sleepDuration =
          dayWakeTime < daySleepTime
            ? dayWakeTime + 24 * 60 - daySleepTime
            : dayWakeTime - daySleepTime

        // Add buffer zones (30 minutes at start and end)
        const bufferTime = 30
        const usableDuration = sleepDuration - 2 * bufferTime

        // Generate random time within sleep period, excluding buffer zones
        const randomOffset = bufferTime + Math.random() * usableDuration
        let interruptionTime = daySleepTime + randomOffset

        // Normalize to 24-hour period if needed
        if (interruptionTime >= 24 * 60) {
          interruptionTime -= 24 * 60
        }

        return interruptionTime
      }

      return {
        day: i,
        wakeTime: dayWakeTime,
        sleepTime: daySleepTime,
        hasConsistentSleep,
        hasConsistentWake,
        staysAsleep,
        interruptions: Array.from(
          { length: getRandomInt(minInterruptions, maxInterruptions) },
          () => ({
            time: generateInterruptionTime(daySleepTime, dayWakeTime),
            resleepTime: getRandomInt(minResleep, maxResleep)
          })
        ),
        transitionTime: getRandomInt(minTransition, maxTransition),
        behaviors: {
          exercise: exerciseDays.has(i),
          bedtime: bedtimeDays.has(i),
          tech: techDays.has(i),
          medicalAids: medicalAidsDays.has(i),
          prescription: prescriptionDays.has(i)
        }
      }
    })
  }

  // Memoize the generated week data
  const weekData = useMemo(() => {
    if (!sleepData || !sleepData.sleep_time || !sleepData.wake_time) {
      console.warn('Missing required sleep data')
      return Array(7).fill({
        day: 0,
        wakeTime: 0,
        sleepTime: 0,
        hasConsistentSleep: false,
        hasConsistentWake: false,
        staysAsleep: false,
        interruptions: [],
        transitionTime: 0,
        behaviors: {
          exercise: false,
          bedtime: false,
          tech: false,
          medicalAids: false,
          prescription: false
        }
      })
    }

    const generatedData = generateWeekData()
    return [...generatedData].sort(() => Math.random() - 0.5)
  }, [sleepData]) // Only regenerate if sleepData changes

  // Add validation before time conversions
  const baseSleepTime = timeToMinutes(sleepData.sleep_time)
  const baseWakeTime = normalizeWakeTime(sleepData.wake_time, baseSleepTime)

  // Update renderWearableSleepBars to include day labels
  const renderWearableSleepBars = () => {
    return sleepLevelsProcessed.map((day) => (
      <g key={day.day_of_week}>
        {Object.entries(day.segments).map(([sleepState, segments]) =>
          segments.map((segment, i) => (
            <rect
              key={`${sleepState}-${i}`}
              x={padding + leftMargin + day.day_of_week * (barWidth + 20)}
              y={minutesToY(segment.start)}
              width={barWidth}
              height={Math.max(
                1,
                Math.abs(minutesToY(segment.end) - minutesToY(segment.start))
              )}
              fill={SLEEP_COLORS[sleepState]}
              opacity={0.8}
            />
          ))
        )}
        {/* Day labels */}
        <text
          x={
            padding +
            leftMargin +
            day.day_of_week * (barWidth + 20) +
            barWidth / 2
          }
          y={height - padding + 20}
          textAnchor="middle"
          fontSize="12"
          fill={COLORS.TEXT}
        >
          {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][day.day_of_week]}
        </text>
      </g>
    ))
  }

  // New function to render traditional sleep bars
  const renderTraditionalSleepBars = () => {
    return weekData.map((day, i) => {
      const sleepY = minutesToY(day.sleepTime)
      const wakeY = minutesToY(day.wakeTime)
      const barY = Math.min(sleepY, wakeY)
      const barHeight = Math.abs(wakeY - sleepY)
      const transitionStartTime = day.sleepTime + day.transitionTime
      const transitionY = minutesToY(transitionStartTime)
      const transitionHeight = Math.abs(sleepY - transitionY)

      return (
        <g key={i}>
          {/* Sleep bar with rounded corners */}
          <rect
            x={padding + leftMargin + i * (barWidth + 20)}
            y={barY}
            width={barWidth}
            height={barHeight}
            fill={day.staysAsleep ? COLORS.SLEEP_GOOD : COLORS.SLEEP_BAD}
            opacity={1.0}
            rx="2"
            ry="2"
          />

          {/* Only render transition bars if transition time exists */}
          {day.transitionTime > 0 && (
            <>
              <rect
                x={padding + leftMargin + i * (barWidth + 20)}
                y={Math.min(sleepY, transitionY) + 5}
                width={barWidth}
                height={transitionHeight}
                fill={COLORS.TRANSITION}
                opacity={1.0}
              />
              <rect
                x={padding + leftMargin + i * (barWidth + 20)}
                y={Math.min(sleepY, transitionY)}
                width={barWidth}
                height={7}
                fill={COLORS.TRANSITION}
                opacity={1.0}
                rx="2"
                ry="2"
              />
            </>
          )}
        </g>
      )
    })
  }

  // Update the legend section to be dynamic based on data type
  const renderLegend = () => {
    if (sleepLevelsProcessed && sleepLevelsProcessed.length > 0) {
      // Wearable sleep legend
      return (
        <g transform={`translate(${width - 30 - 80}, ${padding + 100})`}>
          {[
            { color: SLEEP_COLORS.deep, label: 'Deep Sleep' },
            { color: SLEEP_COLORS.light, label: 'Light Sleep' },
            { color: SLEEP_COLORS.rem, label: 'REM Sleep' },
            { color: SLEEP_COLORS.awake, label: 'Awake' }
          ].map((item, i) => (
            <g key={item.label} transform={`translate(0, ${i * 25})`}>
              <rect width="10" height="10" fill={item.color} rx="1" ry="1" />
              <text
                x="15"
                y="8"
                fill={COLORS.TEXT}
                style={{ fontSize: '12px', fontFamily: 'inherit' }}
              >
                {item.label}
              </text>
            </g>
          ))}
        </g>
      )
    }

    // Traditional sleep legend (existing legend code)
    return (
      <g transform={`translate(${width - 30 - 80}, ${padding + 100})`}>
        {/* Color legend */}
        {[
          { color: COLORS.TRANSITION, label: 'Transition' },
          { color: COLORS.SLEEP_GOOD, label: 'Good Sleep' },
          { color: COLORS.SLEEP_BAD, label: 'Poor Sleep' },
          { color: COLORS.INTERRUPTION, label: 'Interruption' }
        ].map((item, i) => (
          <g key={item.label} transform={`translate(0, ${i * 25})`}>
            <rect width="10" height="10" fill={item.color} rx="1" ry="1" />
            <text
              x="15"
              y="8"
              fill={COLORS.TEXT}
              style={{ fontSize: '12px', fontFamily: 'inherit' }}
            >
              {item.label}
            </text>
          </g>
        ))}

        {/* Icon legend - add 20px spacing after color legend */}
        {[
          { type: 'EXERCISE', label: 'Exercise' },
          { type: 'BEDTIME', label: 'Bedtime Routine' },
          { type: 'TECH', label: 'Technology Use' },
          { type: 'MEDICAL', label: 'Medical Aids' },
          { type: 'PRESCRIPTION', label: 'Prescription' }
        ].map((item, i) => (
          <g key={item.label} transform={`translate(0, ${i * 25 + 120})`}>
            <svg width="14" height="14" viewBox={ICONS[item.type].viewBox}>
              <path d={ICONS[item.type].path} fill={COLORS.ICON} />
            </svg>
            <text
              x="20"
              y="10"
              fill={COLORS.TEXT}
              style={{ fontSize: '12px', fontFamily: 'inherit' }}
            >
              {item.label}
            </text>
          </g>
        ))}
      </g>
    )
  }

  return (
    <svg
      width="100%"
      height="100%"
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="xMidYMid meet"
      style={{ fontFamily: 'inherit' }}
    >
      {/* Update background to white */}
      <rect
        x="0"
        y="0"
        width={width}
        height={height}
        fill="white"
        rx="20"
        ry="20"
      />

      <GlobalIndicators sleepData={sleepData} padding={padding} />

      {/* Base sleep and wake time reference lines */}
      <line
        x1={padding}
        y1={minutesToY(baseSleepTime)}
        x2={padding + leftMargin + 7 * (barWidth + 20)}
        y2={minutesToY(baseSleepTime)}
        stroke="#FFFFFF"
        strokeWidth={1}
        strokeDasharray="4,4"
        opacity={0.5}
      />
      <line
        x1={padding}
        y1={minutesToY(baseWakeTime)}
        x2={padding + leftMargin + 7 * (barWidth + 20)}
        y2={minutesToY(baseWakeTime)}
        stroke="#FFFFFF"
        strokeWidth={1}
        strokeDasharray="4,4"
        opacity={0.5}
      />

      {/* Time labels for reference lines */}
      <text
        x={padding + 380}
        y={minutesToY(baseSleepTime)}
        textAnchor="end"
        alignmentBaseline="middle"
        fontSize="12"
        fill="#FFFFFF"
      >
        {sleepData.sleep_time}
      </text>
      <text
        x={padding + 380}
        y={minutesToY(baseWakeTime)}
        textAnchor="end"
        alignmentBaseline="middle"
        fontSize="12"
        fill="#FFFFFF"
      >
        {sleepData.wake_time}
      </text>

      {/* Y-axis (time) */}
      <line
        x1={padding}
        y1={padding}
        x2={padding}
        y2={height - padding}
        stroke="#FFFFFF"
        strokeWidth={1}
      />
      {/* Time labels - now in original order */}
      {timeLabels.map((label, i) => (
        <text
          key={label}
          x={padding - 10}
          y={padding + (i * (height - 2 * padding)) / (timeLabels.length - 1)}
          textAnchor="end"
          alignmentBaseline="middle"
          fontSize="12"
          fill={COLORS.TEXT}
        >
          {label}
        </text>
      ))}

      {/* Render either wearable or traditional sleep bars */}
      {sleepLevelsProcessed && sleepLevelsProcessed.length > 0
        ? renderWearableSleepBars()
        : renderTraditionalSleepBars()}

      {/* Only render interruptions and behavior icons for traditional data */}
      {(!sleepLevelsProcessed || sleepLevelsProcessed.length === 0) && (
        <>
          {/* Interruptions */}
          {weekData.map((day, i) =>
            day.interruptions.map((interrupt, j) => {
              const x = padding + leftMargin + i * (barWidth + 20)
              const interruptionHeight = interrupt.resleepTime * 0.8

              return (
                <rect
                  key={`${i}-${j}`}
                  x={x}
                  y={minutesToY(interrupt.time)}
                  width={barWidth}
                  height={interruptionHeight}
                  fill={COLORS.INTERRUPTION}
                  opacity={1.0}
                />
              )
            })
          )}

          {/* Behavior icons */}
          {weekData.map((day, i) => (
            <BehaviorIcons
              key={i}
              behaviors={day.behaviors}
              x={padding + leftMargin + i * (barWidth + 20)}
              width={barWidth}
              padding={padding}
              height={height}
            />
          ))}
        </>
      )}

      {/* Dynamic legend */}
      {renderLegend()}
    </svg>
  )
}

// Update BehaviorIcons component
const BehaviorIcons = ({ behaviors, x, width, padding, height }) => {
  const iconSize = 14
  const iconSpacing = 16
  const baseOffset = 0

  const icons = []
  if (behaviors.exercise)
    icons.push({ type: 'EXERCISE', title: 'Exercise', glow: 'red' })
  if (behaviors.bedtime)
    icons.push({ type: 'BEDTIME', title: 'Bedtime Routine', glow: 'blue' })
  if (behaviors.tech)
    icons.push({ type: 'TECH', title: 'Technology Use', glow: 'red' })
  if (behaviors.medicalAids)
    icons.push({ type: 'MEDICAL', title: 'Medical Aids', glow: 'blue' })
  if (behaviors.prescription)
    icons.push({ type: 'PRESCRIPTION', title: 'Prescription', glow: 'red' })

  return (
    <g>
      {icons.map((icon, i) => (
        <g
          key={i}
          transform={`translate(
                        ${x + width / 2 - iconSize / 2}, 
                        ${padding - baseOffset - i * iconSpacing}
                    )`}
        >
          <svg
            width={iconSize}
            height={iconSize}
            viewBox={ICONS[icon.type].viewBox}
          >
            <path
              d={ICONS[icon.type].path}
              fill={COLORS.ICON} // Updated from white to COLORS.ICON
            />
          </svg>
          <title>{icon.title}</title>
        </g>
      ))}
    </g>
  )
}
