import React from 'react'

export const StyledCard = ({ children, className = '' }) => (
    <div className={`styled-card ${className}`}>
        {children}
    </div>
)

export const StyledContainer = ({ children, className = '' }) => (
    <div className={`styled-container ${className}`}>
        {children}
    </div>
)

export const StyledSection = ({ children, title, className = '' }) => (
    <div className={`styled-section ${className}`}>
        <h2 className="section-title">{title}</h2>
        {children}
    </div>
)

export const StyledSectionOverflow = ({ children, title, className = '' }) => (
    <div className={`styled-section-overflow ${className}`}>
        <h2 className="section-title">{title}</h2>
        {children}
    </div>
)

export const RemoveButton = ({ onClick }) => (
    <button
        className="remove-activity-button"
        onClick={onClick}
    >
        X
    </button>
)
