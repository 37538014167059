import React, { useState } from 'react'
import Modal from 'react-modal'

export const ActivityGroup = ({ title, activities = [] }) => {
    const getGroupColor = (title) => {
        switch (title.toLowerCase()) {
            case 'activities to avoid':
                return 'activity-group-avoid'
            case 'activities to increase':
                return 'activity-group-increase'
            case 'activities to decrease':
                return 'activity-group-decrease'
            case 'preferred activities':
            default:
                return 'activity-group-prefer'
        }
    }

    return (
        <div className={`activity-group ${getGroupColor(title)}`}>
            <h3 className="activity-group-title">{title}</h3>
            <div className="activity-bubble-container">
                {activities.map((activity, index) => (
                    <div key={index} className={`activity-bubble ${getGroupColor(title)}`}>
                        {activity}
                    </div>
                ))}
            </div>
        </div>
    )
}

export const ActivityModal = ({ isOpen, onRequestClose, onSave, activity }) => {
    const [type, setType] = useState(activity.type || 'aerobic')
    const [time, setTime] = useState(activity.time || 30)
    const [heartRateZone, setHeartRateZone] = useState(activity.heartRateZone ? activity.heartRateZone.toString() : '')
    const [difficultyLevel, setDifficultyLevel] = useState(activity.difficultyLevel || '')
    const [rpe, setRpe] = useState(activity.rpe ? activity.rpe.toString() : '')

    // Track which intensity measure is active
    const [activeIntensity, setActiveIntensity] = useState(
        activity.heartRateZone ? 'hrz' :
            activity.rpe ? 'rpe' :
                activity.difficultyLevel ? 'difficulty' :
                    ''
    )

    // Update handleIntensityChange to handle string values
    const handleIntensityChange = (type, value) => {
        if (value === '' || value === null) {
            setActiveIntensity('')

            // Clear the specific value
            switch (type) {
                case 'hrz':
                    setHeartRateZone('')
                    break
                case 'rpe':
                    setRpe('')
                    break
                case 'difficulty':
                    setDifficultyLevel('')
                    break
            }
            return
        }

        // For number inputs, validate the range
        if (type === 'hrz' || type === 'rpe') {
            const numValue = parseInt(value)
            const max = type === 'hrz' ? 5 : 10

            // Only proceed if it's a valid number and within range
            if (!isNaN(numValue) && numValue >= 0 && numValue <= max) {
                setActiveIntensity(type)

                // Clear other intensity values
                if (type !== 'hrz') setHeartRateZone('')
                if (type !== 'rpe') setRpe('')
                if (type !== 'difficulty') setDifficultyLevel('')

                // Set the new value
                switch (type) {
                    case 'hrz':
                        setHeartRateZone(value)
                        break
                    case 'rpe':
                        setRpe(value)
                        break
                }
            }
        } else {
            // Handle difficulty level (no change needed for select input)
            setActiveIntensity(type)
            setHeartRateZone('')
            setRpe('')
            setDifficultyLevel(value)
        }
    }

    const handleSave = (e) => {
        e && e.preventDefault()

        // Convert string values back to numbers or null when saving
        onSave({
            ...activity,
            type,
            time,
            heartRateZone: heartRateZone ? parseInt(heartRateZone) : null,
            difficultyLevel: difficultyLevel || null,
            rpe: rpe ? parseInt(rpe) : null
        })

        onRequestClose()
    }

    const handleSubmit = (e) => {
        e.preventDefault() // Prevent default form submission
        handleSave()
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Activity Details"
            className="modal"
            overlayClassName="overlay"
            shouldFocusAfterRender={false}
            shouldReturnFocusAfterClose={false}
        >
            <h2>Activity Details</h2>
            <form onSubmit={handleSubmit} className="modal-content">
                <p><strong>Name:</strong> {activity.name}</p>

                <div className="form-group">
                    <label>Type:</label>
                    <select value={type} onChange={(e) => setType(e.target.value)}>
                        <option value="resistance">Resistance</option>
                        <option value="aerobic">Aerobic</option>
                        <option value="activity">Activity</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Time (mins):</label>
                    <input
                        type="number"
                        value={time}
                        onChange={(e) => setTime(Number(e.target.value))}
                    />
                </div>
                <div className="form-group">
                    <label>Heart Rate Zone (1-5):</label>
                    <input
                        type="text"
                        value={heartRateZone}
                        onChange={(e) => handleIntensityChange('hrz', e.target.value)}
                        disabled={activeIntensity && activeIntensity !== 'hrz'}
                        maxLength={1}
                    />
                </div>
                <div className="form-group">
                    <label>Difficulty Level:</label>
                    <select
                        value={difficultyLevel}
                        onChange={(e) => handleIntensityChange('difficulty', e.target.value)}
                        disabled={activeIntensity && activeIntensity !== 'difficulty'}
                    >
                        <option value="">Select difficulty</option>
                        <option value="light">Light</option>
                        <option value="moderate">Moderate</option>
                        <option value="intense">Intense</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>RPE (1-10):</label>
                    <input
                        type="text"
                        value={rpe}
                        onChange={(e) => handleIntensityChange('rpe', e.target.value)}
                        disabled={activeIntensity && activeIntensity !== 'rpe'}
                        maxLength={2}
                    />
                </div>
                <div className="modal-actions">
                    <button type="submit" className="save-button-ex">Save</button>
                    <button type="button" onClick={onRequestClose} className="cancel-button-ex">Cancel</button>
                </div>
            </form>
        </Modal>
    )
}
