import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'antd'
import Graph from '../assessmentReview/comp/graph'
import HealthCard from './card'
import TotalCard from './totalCard'
import UserInfo from './userInfo'
import { healthCardList as list } from '../../utils/constant'
import { getHealthStatus, saveScoreBulk } from '../../api/healthScore'
import { healthStyle } from './styles/index.sass'
import { HSDSpiderGraph } from '../assessmentReview/comp/HSDSpiderGraph'
import HealthCompassSection from '../assessmentReview/sections/HealthCompassSection'

class HealthScoreContainer extends Component {
  static propTypes = {
    person: PropTypes.object,
    mobile: PropTypes.bool
  }

  state = {
    loading: true,
    saveLoading: false,
    latest: { snapshot: {} },
    isEditing: false,
    catchEditScore: [],
    scoresLegalCheck: {},
    viewModal: 'normal'
  }

  save = async (type, setToDefaultValue) => {
    const { catchEditScore } = this.state
    if (setToDefaultValue) {
      await this.saveBulk([
        {
          type,
          score: null,
          graph_type: 'latest'
        },
        {
          type,
          score: null,
          graph_type: 'previous'
        }
      ])
    } else if (catchEditScore.length) {
      await this.saveBulk()
    }
  }

  cancelEdit = (type) => {
    if (type) {
      let { catchEditScore } = this.state
      catchEditScore = catchEditScore.filter((item) => item.type !== type)
      this.setState({ catchEditScore })
    } else {
      this.setState({ isEditing: false, catchEditScore: [] })
    }
  }

  setAllDefaultValue = () => {
    let params = []
    for (const item of list) {
      params = params.concat([
        {
          type: item.type,
          graph_type: 'previous',
          score: null
        },
        {
          type: item.type,
          graph_type: 'latest',
          score: null
        }
      ])
    }
    this.saveBulk(params)
  }

  saveBulk = async (catchEditScore) => {
    catchEditScore = catchEditScore || this.state.catchEditScore
    const person_id = this.props.person.id
    this.setState({ saveLoading: true })
    await saveScoreBulk({
      details: catchEditScore,
      person_id
    })

    const { results, latest_bmi } = await getHealthStatus(person_id)
    this.setState({
      latest: results.filter((item) => item.graph_type === 'latest')[0],
      previous: results.filter((item) => item.graph_type === 'previous')[0],
      latest_bmi: latest_bmi,
      saveLoading: false,
      catchEditScore: [],
      isEditing: false
    })
  }

  changeMode = () => {
    const { viewModal } = this.state
    const dom = ReactDOM.findDOMNode(this)
    const rightContainer = dom.parentNode.parentNode
    const leftContainer = rightContainer.previousSibling
    if (viewModal === 'normal') {
      leftContainer.style = 'display: none;'
      rightContainer.style = 'background: transparent; box-shadow: none;'
      this.setState({ viewModal: 'present' })
    } else {
      leftContainer.style = ''
      rightContainer.style = ''
      this.setState({ viewModal: 'normal' })
    }
  }

  initial = async () => {
    let { person, match } = this.props
    const hash = match && match.params && match.params.hash
    const personId = match && match.params && match.params.personId

    const { results, latest_bmi } = await getHealthStatus(
      personId || (person && person.id),
      null,
      hash
    )
    if (results && results[0]) {
      this.setState({
        latest: results.filter((item) => item.graph_type === 'latest')[0],
        previous: results.filter((item) => item.graph_type === 'previous')[0],
        latest_bmi: latest_bmi,
        loading: false
      })
    }
  }

  getDataList = (snapshot, graph_type) => {
    const { catchEditScore } = this.state
    return list.map((item) => {
      const catchScore = catchEditScore.find(
        (score) => score.type === item.type && score.graph_type === graph_type
      )
      const detail =
        (snapshot[item.type] && _.cloneDeep(snapshot[item.type])) || {}
      detail.isDefaultValue = true
      if (detail.score_manual && detail.score_manual.value) {
        detail.isDefaultValue = false
      }
      if (catchScore) {
        detail.score_manual = Object.assign(detail.score_manual || {}, {
          value: catchScore.score,
          updated_at: new Date()
        })
      }
      return {
        ...item,
        ...detail
      }
    })
  }

  onScoreChangeHandle = (score, type, graph_type) => {
    score = score || null
    const { catchEditScore, scoresLegalCheck } = this.state
    let targetIndex = catchEditScore.findIndex(
      (item) => item.type === type && item.graph_type === graph_type
    )
    const scoreLegal = score ? score >= 5 && score <= 99 : true
    const scoreKey = type + graph_type
    scoresLegalCheck[scoreKey] = scoreLegal

    if (targetIndex !== -1) {
      catchEditScore[targetIndex].score = score
    } else {
      catchEditScore.push({
        type,
        graph_type,
        score
      })
    }
    this.setState({ catchEditScore, scoresLegalCheck })
  }

  componentDidMount() {
    this.initial()
  }

  componentWillUnmount() {
    if (this.state.viewModal !== 'normal') {
      this.changeMode()
    }
  }

  render() {
    const {
      latest,
      loading,
      previous,
      latest_bmi,
      isEditing,
      saveLoading,
      catchEditScore,
      scoresLegalCheck,
      viewModal
    } = this.state
    const { person, mobile, loginRole } = this.props
    const { snapshot } = latest
    const dataList = this.getDataList(snapshot, 'latest')
    let compareDataList
    if (previous) {
      compareDataList = this.getDataList(previous.snapshot, 'previous')
    }
    const allScoreLegal = Object.keys(scoresLegalCheck)
      .map((e) => scoresLegalCheck[e])
      .every((e) => e)
    return (
      <div className={healthStyle}>
        <div className={`health-scroe-container ${mobile ? 'mobile' : ''}`}>
          {(loginRole === 'Expert' || loginRole === 'InternalAdmin') && (
            <div className="edit-botton">
              <Button type="primary" ghost onClick={this.changeMode}>
                {viewModal === 'normal' ? 'PRESENT ' : 'NORMAL '} MODE
              </Button>
              <Button
                type="primary"
                onClick={() =>
                  isEditing
                    ? this.saveBulk()
                    : this.setState({ isEditing: true })
                }
                loading={saveLoading}
                disabled={isEditing && !catchEditScore.length}
              >
                {isEditing ? 'SAVE' : 'EDIT SCORE'}
              </Button>
            </div>
          )}
          {person && person.id && <UserInfo person={person} bmi={latest_bmi} />}
          {/* {mobile ? (
            <SpiderGraph
              list={dataList}
              compareData={compareDataList}
              mobile={mobile}
            />
          ) : ( */}
          {/* <Graph
            {...{
              compareData: compareDataList || [],
              dataList: dataList || [],
              mobile
            }}
          /> */}
          <HealthCompassSection dataList={dataList || []} compareData={compareDataList || []} />
          {/* )} */}

          {/* <div className={`compare-labels ${mobile ? 'mobile' : ''}`}>
            <div>
              <Circle fillOpacity={0.2} fill="#7FB800" stroke="#7FB800" />{' '}
              Latest
            </div>
            <div>
              <Circle
                fillOpacity={0.2}
                dasharray
                fill="#7FB800"
                stroke="#7FB800"
              />
              Estimate
            </div>
            <div>
              <Circle fillOpacity={0.2} fill="#0D2350" stroke="#0D2350" />{' '}
              Previous
            </div>
            <div>
              <Circle fillOpacity={1} fill="white" stroke="#0D2350" />
              carried forward
            </div>
          </div> */}
          <div className="health-card-conatiner">
            <Row gutter={32} type="flex">
              {dataList.map((item, index) => (
                <Col span={mobile ? 24 : 12} key={index}>
                  <HealthCard
                    {...item}
                    loading={loading}
                    compare={compareDataList && compareDataList[index]}
                    save={this.save}
                    jumpToDataset={this.props.jumpToDataset}
                    routeTypes={this.props.routeTypes}
                    disEdit={mobile}
                    loginRole={loginRole}
                    globalEdit={isEditing}
                    onChangeHandle={this.onScoreChangeHandle}
                    cancel={this.cancelEdit}
                  />
                </Col>
              ))}
              <Col span={mobile ? 24 : 12}>
                <TotalCard
                  list={dataList}
                  compareDataList={compareDataList}
                  loginRole={loginRole}
                />
              </Col>
            </Row>
          </div>
        </div>

        {isEditing && (
          <footer>
            <div>
              <Button type="primary" ghost onClick={this.setAllDefaultValue}>
                Set to default value
              </Button>
            </div>
            <div>
              <Button type="primary" ghost onClick={() => this.cancelEdit()}>
                Cancel
              </Button>
              <Button
                type="primary"
                loading={saveLoading}
                onClick={() => this.saveBulk()}
                disabled={
                  (isEditing && !catchEditScore.length) || !allScoreLegal
                }
              >
                Save{' '}
              </Button>
            </div>
          </footer>
        )}
      </div>
    )
  }
}

export default HealthScoreContainer

function Circle(props) {
  const { fillOpacity, fill, stroke, dasharray } = props
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6"
        cy="6"
        r="5"
        fill={fill}
        fillOpacity={fillOpacity}
        stroke={stroke}
        strokeWidth="2"
        strokeDasharray={dasharray ? '2,2' : null}
      />
    </svg>
  )
}
