import React, { useCallback, useMemo, useState, useEffect } from 'react'
import IogoIcon02 from '../../../asserts/icon/client/recommendation.png'
import RecommendationIcon from '../../../asserts/icon/client/rec.png'
import { ReactComponent as ExerciseIcon } from '../../../asserts/icon/client/ic_exercise.svg'
import { ReactComponent as NutritionIcon } from '../../../asserts/icon/client/ic_nutrition.svg'
import { ReactComponent as RejuvenationIcon } from '../../../asserts/icon/client/ic_rejuvenation.svg'
import { FormFields } from './ReferralEditor/index'
import StepItem from './StepItem'
import HighestPriorityItem from './HighestPriorityItem'
import { find, isArray, some } from 'lodash'
import _ from 'lodash'
import './Resource.css'
import { Button, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import ReferralItem from './ReferralItem/index'
import ResourceSection from './ResourceSection'
import styled from 'styled-components'
import { SectionStyle } from '../../questionnaire/styles/sectionStyle'
import HideWrap from '../comp/hideWrap'
import { RecommendationColors } from '../ExerciseGraph'
import ExerciseScheduler from './ExerciseRecommendationComp/components/RecommendModal'
import {
  XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer, BarChart, Bar
} from 'recharts'

export const RecommendationContainer = styled.section`
  ${SectionStyle}
  padding-top: 106px;
  .edit-btn {
    margin-left: 36px;
    padding: 0 24px;
    height: 40px;
    display: flex;
    align-items: center;
    color: #264382;
    border-color: #264382;
    .icon {
      margin-right: 10px;
    }
  }
  .subtitle {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.3px;
    color: rgb(76, 96, 114);
  }
  /* 1. Banner Section */
  .banner-section {
    padding: 30px 40px;
    height: 202px;
    border-radius: 8px;
    background: url(${IogoIcon02}) no-repeat center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .logos-wrapper {
      display: flex;
      align-items: center;
      img {
        margin-right: 18px;
        height: 32px;
      }
    }
    .title {
      font-size: 32px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: 0.3px;
      color: #ffffff;
    }
  }
  /* 2. Highest Priorities Section */
  .highest-priorities-section {
    padding: 36px 0;
    border-bottom: 1px solid rgba(230, 233, 242, 1);
    .items-section {
      margin: 24px -8px 0;
      display: flex;
      .highest-priority-item {
        margin: 0 8px;
        flex: 1;
        padding: 24px;
        border-radius: 4px;
        border: 1px solid rgba(227, 230, 234, 1);
        background: rgba(250, 250, 250, 1);
        .title-section {
          display: flex;
          justify-content: space-between;
          margin-bottom: 24px;
          padding-bottom: 24px;
          border-bottom: 1px solid rgba(227, 230, 234, 1);
          button {
            padding: 0;
            width: 36px;
            min-width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #eff1f3;
          }
        }
      }
    }
  }
  /* 3. Integrated Recommendation Section */
  .ir-section {
    margin-top: 36px;
    display: flex;
    .editor-section {
      flex: 1;
    }
  }
  /* 4. Exercise, Nutrition, Rejuvenation */
  .step-section {
    margin-top: 60px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(230, 233, 242, 1);
    .subtitle {
      margin-bottom: 24px;
    }
    .step-item {
      &:not(:last-child) {
        margin-bottom: 48px;
      }
      .top-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-section {
          display: flex;
          align-items: center;
          .title {
            margin-left: 16px;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0.3px;
          }
        }
        button {
          padding: 0;
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #eff1f3;
        }
      }
      .editor-section {
        margin-top: 16px;
      }
    }
  }
  /* 5. Referrals Section */
  .referrals-section {
    margin-top: 60px;
    .top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .add-btn {
        padding: 0 24px;
        height: 40px;
        border-color: rgb(38, 67, 130);
        color: rgb(38, 67, 130);
        &:disabled {
          opacity: 0.7;
        }
      }
    }
    .bottom-section {
      margin-top: 24px;
    }
    /* .add-more-section {
      margin-top: 56px;
      padding-top: 14px;
      border-top: 1px solid rgba(215, 215, 215, 1);
      .add-more-btn {
        padding: 0;
        color: rgba(38, 67, 130, 1);
      }
    } */
  }
`

const RecommendationFields = {
  GOALS: 'goals',
  LIFESTYLE: 'lifestyle',
  HEALTHSPAN_DOMAIN: 'healthspan',
  INTEGRATED_RECOMMENDATION: 'integrated',
  EXERCISE: 'exercise',
  NUTRITION: 'nutrition',
  REJUVENATION: 'rejuvenation',
  REFERRALS: 'recommendation_referrals',
  WHAT_TO_EXPECT: 'expectations',
  NEXT_STEPS: 'support',
  PROGRESSION: 'progression',
  HOLISTIC_RECS: 'intro'
}

const {
  GOALS,
  LIFESTYLE,
  HEALTHSPAN_DOMAIN,
  INTEGRATED_RECOMMENDATION,
  EXERCISE,
  NUTRITION,
  REJUVENATION,
  WHAT_TO_EXPECT,
  NEXT_STEPS,
  PROGRESSION,
  HOLISTIC_RECS
} = RecommendationFields

const HighestPrioritiesConfigs = [
  {
    field: GOALS,
    title: 'Goals',
    placeholder: 'Please enter goals'
  },
  {
    field: LIFESTYLE,
    title: 'Lifestyle',
    placeholder: 'Please enter lifestyle'
  },
  {
    field: HEALTHSPAN_DOMAIN,
    title: 'Healthspan Domains',
    placeholder: 'Please enter healthspan domains'
  }
]

const StepsConfigs = [
  {
    field: PROGRESSION,
    title: 'Progression',
    placeholder: 'Light, Moderate, Light, Moderate, Moderate, Vigorous',
    icon: <ExerciseIcon />,
    color: 'rgb(255, 107, 0)'
  },
  {
    field: HOLISTIC_RECS,
    title: 'Holistic Recommendations',
    placeholder: 'Please enter holistic recommendations',
    icon: <ExerciseIcon />,
    color: 'rgb(255, 107, 0)'
  }
]

// const StepsCofigs = [
//   {
//     field: EXERCISE,
//     title: 'Exercise',
//     placeholder: 'Please enter exercise',
//     icon: <ExerciseIcon />,
//     color: 'rgb(255, 107, 0)'
//   },
//   {
//     field: NUTRITION,
//     title: 'Nutrition',
//     placeholder: 'Please enter nutrition',
//     icon: <NutritionIcon />,
//     color: 'rgb(123, 173, 45)'
//   },
//   {
//     field: REJUVENATION,
//     title: 'Sleep',
//     placeholder: 'Please enter rejuvenation',
//     icon: <RejuvenationIcon />,
//     color: 'rgb(92, 0, 209)'
//   }
// ]

const expectationAndNextStepsConfigs = [
  {
    field: WHAT_TO_EXPECT,
    title: 'What to Expect',
    placeholder: 'Please enter what to expect',
    icon: <ExerciseIcon />,
    color: 'rgb(255, 107, 0)'
  },
  {
    field: NEXT_STEPS,
    title: 'Next Steps',
    placeholder: 'Please enter next steps',
    icon: <NutritionIcon />,
    color: 'rgb(123, 173, 45)'
  }
]

const { emerald, green, blue, teal, purple, indigo } = RecommendationColors

const ExerciseGridContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => props.showHsdChart ? '33% 67%' : 'minmax(40px, 5%) 95%'};
  gap: 8px;
  width: 100%;
  min-height: 600px;
  padding-top: 10px;
  box-sizing: border-box;
`

const ChartContainer = styled.div`
  height: 600px;
  width: 100%;
  background: transparent;
  border-radius: 20px;
  position: relative;
  min-width: 40px;
  overflow: hidden;
  display: flex;
  align-items: stretch;

  & > div {
    width: 100% !important;
    height: 100% !important;
  }
`

const SchedulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 90%;
  padding: 8px;
  padding-top: 32px;
`

// Helper function to render the weekly schedule
const renderWeeklySchedule = (weeklySchedule, intensity) => {
  if (!weeklySchedule || !weeklySchedule[intensity]) return null

  const colorMap = {
    light: emerald,
    moderate: teal,
    vigorous: indigo
  }

  const titleMap = {
    light: 'Light Week Program',
    moderate: 'Moderate Week Program',
    vigorous: 'Vigorous Week Program'
  }

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <h4 style={{
        color: colorMap[intensity],
        margin: '0 0 8px 0',
        fontSize: '16px',
        fontWeight: 'bold'
      }}>
        {titleMap[intensity]}
      </h4>
      <div className="weekly-schedule" style={{
        flex: 1,
        width: '100%',
        border: `1px solid ${colorMap[intensity]}`,
        borderRadius: '8px',
        padding: '1px',
        overflow: 'auto',
        boxSizing: 'border-box'
      }}>
        <div className="schedule-grid" style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7, minmax(min-content, 1fr))',
          gap: '1px',
          fontSize: '12px',
          width: '100%',
          minWidth: 0,
          overflow: 'hidden'
        }}>
          {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => (
            <div key={day} className="day-column" style={{
              textAlign: 'center',
              minWidth: 0,
              overflow: 'hidden',
              padding: '0 1px'
            }}>
              <div className="day-header" style={{
                fontWeight: 'bold',
                marginBottom: '1px',
                color: colorMap[intensity],
                fontSize: '12px'
              }}>
                {day.charAt(0).toUpperCase() + day.slice(1, 3)}
              </div>
              {weeklySchedule[intensity][day].map((activity, idx) => (
                <div
                  key={`${activity.name}-${idx}`}
                  className="activity-item"
                  style={{
                    padding: '1px 2px',
                    marginBottom: '1px',
                    backgroundColor: colorMap[intensity],
                    color: 'white',
                    border: `1px solid ${colorMap[intensity]}`,
                    borderRadius: '5px',
                    fontSize: '12px',
                    wordBreak: 'break-word',
                    width: 'auto',
                    maxWidth: '100%'
                  }}
                >
                  <div style={{ fontWeight: 'bold' }}>{activity.name}</div>
                  <div>{activity.time}min</div>
                  {activity.rpe && <div>RPE: {activity.rpe}</div>}
                  {activity.heartRateZone && <div>HRZ: {activity.heartRateZone}</div>}
                  {activity.difficultyLevel && <div>{activity.difficultyLevel}</div>}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
const BannerSection = () => {
  return (
    <div className="banner-section">
      <div className="logos-wrapper">
        <img src={RecommendationIcon} alt="logo02" />
      </div>
      <div className="title">Recommendations and Next Steps</div>
    </div>
  )
}

const HighestPrioritiesSection = ({ editabled, data, onChange }) => {
  return (
    <div className="highest-priorities-section">
      <div className="subtitle">Priorities</div>
      <div className="items-section">
        {HighestPrioritiesConfigs.map((item, index) => (
          <HighestPriorityItem
            key={index}
            {...item}
            editabled={editabled}
            value={data[item.field]}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  )
}

// const StepsSection = ({ editabled, data, onChange }) => {
//   return (
//     <div className="step-section">
//       <div className="subtitle">Recommendations</div>
//       {StepsConfigs.map((item, index) => (
//         <StepItem
//           key={index}
//           {...item}
//           editabled={editabled}
//           value={data[item.field]}
//           onChange={onChange}
//         />
//       ))}
//     </div>
//   )
// }

// 4. Exercise, Nutrition, Rejuvenation
const StepsSection = ({ editabled, data, onChange }) => {
  return (
    <div className="step-section">
      <div className="subtitle">Recommendations</div>
      {StepsConfigs.map((item, index) => (
        <StepItem
          key={index}
          {...item}
          editabled={editabled}
          value={data[item.field]}
          onChange={onChange}
        />
      ))}
    </div>
  )
}

const ExpectationAndNextSteps = ({ editabled, data, onChange }) => {
  return (
    <div className="step-section">
      <div className="subtitle">Expectation And Next Steps</div>
      {expectationAndNextStepsConfigs.map((item, index) => (
        <StepItem
          key={index}
          {...item}
          editabled={editabled}
          value={data[item.field]}
          onChange={onChange}
        />
      ))}
    </div>
  )
}

// // 5. Referrals Section
const ReferralsSection = ({ editabled, data, onSave, updateArDetail }) => {
  const [editedData, setEditedData] = useState(
    data.map((item) => ({ ...item, editing: false }))
  )

  const isEditing = useMemo(() => some(editedData, { editing: true }), [
    editedData
  ])

  const handleChange = useCallback(
    (index, newReferral) => {
      const _data = [...editedData]
      _data[index] = newReferral
      setEditedData(_data)
    },
    [editedData]
  )

  const handleAddMore = useCallback(() => {
    const newReferral = { editing: true }
    for (let key in FormFields) {
      newReferral[FormFields[key]] = ''
    }
    setEditedData((data) => [...data, newReferral])
  }, [])

  const handleCancel = useCallback(
    (index) => {
      const _editedData = [...editedData]
      if (_editedData[index].id) {
        _editedData[index] = { ...data[index], editing: false }
      } else {
        _editedData.splice(index, 1)
      }
      setEditedData(_editedData)
    },
    [data, editedData]
  )

  const handleUpdateWrapperImage = useCallback(
    (index, imageObj) => {
      const _data = [...data]
      _data[index] = { ..._data[index], ...imageObj }
      updateArDetail(_data)
    },
    [data, updateArDetail]
  )

  return (
    <div className="referrals-section">
      <div className="top-section">
        <div className="subtitle">Referrals</div>
        {editabled && (
          <Button
            className="add-btn"
            disabled={isEditing}
            onClick={handleAddMore}
          >
            <PlusOutlined />
            ADD
          </Button>
        )}
      </div>
      <div className="bottom-section">
        {editedData.map((item, index) => (
          <ReferralItem
            key={index}
            index={index}
            editabled={editabled}
            data={item}
            onChange={handleChange}
            onSave={onSave}
            onCancel={handleCancel}
            updateWrapperImage={handleUpdateWrapperImage}
          />
        ))}
      </div>
      {/* <div className='add-more-section'>
        <Button className='add-more-btn' type='text' onClick={handleAddMore}>
          + ADD MORE
        </Button>
      </div> */}
    </div>
  )
}

const convertMarkdownToBold = (text) => {
  if (!text) return ''
  return text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
}

// Add helper function to check if schedule data exists
const hasScheduleData = (weeklySchedule, hsdByIntensity) => {
  const hasWeeklySchedule = weeklySchedule && 
    Object.keys(weeklySchedule).length > 0 &&
    Object.values(weeklySchedule).some(intensity => 
      Object.values(intensity).some(day => day.length > 0)
    );

  const hasHsdData = hsdByIntensity && 
    Object.keys(hsdByIntensity).length > 0 &&
    Object.values(hsdByIntensity).some(intensity =>
      Object.values(intensity).some(value => value > 0)
    );

  return hasWeeklySchedule || hasHsdData;
};

export default function RecommendationSection({
  editabled,
  arDetail,
  onUpdateNotes,
  onUpdateReferral,
  updateArDetail,
  view,
  hideSection,
  hideSectionHandle,
  updateEditingCount,
  person,
  lsd,
  hsd,
  exerciseRecommendationPlots,
  copilotDashboard
}) {
  const { notes, referrals, detail } = arDetail
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const lifestyleScore = _.get(lsd, 'lifestyleScore', {})
  const goals = _.get(lsd, 'goals', {})

  // Add these state declarations
  const [weeklySchedule, setWeeklySchedule] = useState(
    exerciseRecommendationPlots &&
      exerciseRecommendationPlots.weekly_schedule &&
      Object.keys(exerciseRecommendationPlots.weekly_schedule).length > 0
      ? exerciseRecommendationPlots.weekly_schedule
      : null
  )

  const [hsdByIntensity, setHsdByIntensity] = useState(
    exerciseRecommendationPlots &&
      exerciseRecommendationPlots.hsd_intensity &&
      Object.keys(exerciseRecommendationPlots.hsd_intensity).length > 0
      ? exerciseRecommendationPlots.hsd_intensity
      : null
  )

  // Add useEffect to update state when props change
  useEffect(() => {
    if (exerciseRecommendationPlots) {
      setWeeklySchedule(
        exerciseRecommendationPlots.weekly_schedule &&
          Object.keys(exerciseRecommendationPlots.weekly_schedule).length > 0
          ? exerciseRecommendationPlots.weekly_schedule
          : null
      )
      setHsdByIntensity(
        exerciseRecommendationPlots.hsd_intensity &&
          Object.keys(exerciseRecommendationPlots.hsd_intensity).length > 0
          ? exerciseRecommendationPlots.hsd_intensity
          : null
      )
    }
  }, [exerciseRecommendationPlots])

  const handleModalClose = useCallback(() => {
    if (!isUpdating) {
      setIsModalOpen(false)
    }
  }, [isUpdating])

  const getData = useCallback(
    (param) => {
      const getValue = (pillar) => {
        // First check notes for any field
        const note = find(notes, { pillar })
        if (note && note.memo) {
          return convertMarkdownToBold(note.memo)
        }

        // If no note exists or is empty, fall back to detail for special fields
        if (pillar === 'intro' && detail && detail.intro) {
          return convertMarkdownToBold(detail.intro)
        }
        if (pillar === 'expectations' && detail && detail.expectations) {
          return convertMarkdownToBold(detail.expectations)
        }
        if (pillar === 'support' && detail && detail.support) {
          return convertMarkdownToBold(detail.support)
        }

        // If neither exists, return empty string
        return ''
      }

      if (isArray(param)) {
        const _data = {}
        param.forEach((pillar) => {
          _data[pillar] = getValue(pillar)
        })
        return _data
      }
      return getValue(param)
    },
    [notes, detail]
  )

  const handleUpdateArDetail = useCallback(
    (referrals) => {
      updateArDetail({ ...arDetail, referrals })
    },
    [arDetail, updateArDetail]
  )

  const isSectionHidden = useCallback((sectionName) => {
    return hideSection.includes(sectionName)
  }, [hideSection])

  // Helper function to render the HSD Chart
  const renderHSDChart = () => {
    if (!hsdByIntensity) return null

    const domains = [
      'aerobic_fitness',
      'balance',
      'bone',
      'movement_quality',
      'body_composition',
      'cognitive_health',
      'muscular_fitness'
    ]

    // Filter out hidden intensity types
    const visibleIntensities = {
      Light: !isSectionHidden('Exercise Schedule - Light Week'),
      Moderate: !isSectionHidden('Exercise Schedule - Moderate Week'),
      Vigorous: !isSectionHidden('Exercise Schedule - Vigorous Week')
    }

    // Create data array only including visible intensities
    const data = domains.map(domain => {
      const dataPoint = {
        name: domain.split('_').map(word =>
          word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ')
      }

      if (visibleIntensities.Light) {
        dataPoint.Light = hsdByIntensity.light[domain] || 0
      }
      if (visibleIntensities.Moderate) {
        dataPoint.Moderate = hsdByIntensity.moderate[domain] || 0
      }
      if (visibleIntensities.Vigorous) {
        dataPoint.Vigorous = hsdByIntensity.vigorous[domain] || 0
      }

      return dataPoint
    })

    // Get visible bars for the chart
    const visibleBars = Object.entries(visibleIntensities)
      .filter(([_, isVisible]) => isVisible)
      .map(([intensity, _]) => ({
        dataKey: intensity,
        fill: intensity === 'Light' ? emerald :
          intensity === 'Moderate' ? teal : indigo
      }))

    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          layout="vertical"
          margin={{
            top: 20,
            right: 20,
            left: 0,
            bottom: 20
          }}
          barSize={40}
          barGap={4}
          barCategoryGap={40}
          background={{ fill: 'transparent' }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <XAxis
            type="number"
            domain={[0, 300]}
            ticks={[0, 50, 100, 150, 200, 250, 300]}
            tickMargin={8}
            scale="linear"
            padding={{ left: 0, right: 0 }}
          />
          <YAxis
            type="category"
            dataKey="name"
            tick={{ fontSize: 13 }}
            width={140}
            padding={{ top: 15, bottom: 15 }}
          />
          <Tooltip />
          <Legend
            wrapperStyle={{
              position: 'absolute',
              bottom: 0,
              left: 25,
              width: '90%',
              lineHeight: '16px'
            }}
          />
          {visibleBars.map((bar, index) => (
            <Bar
              key={bar.dataKey}
              dataKey={bar.dataKey}
              fill={bar.fill}
              barSize={40}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    )
  }

  return (
    <RecommendationContainer>
      <BannerSection />
      <HighestPrioritiesSection
        editabled={editabled}
        data={getData(HighestPrioritiesConfigs.map(({ field }) => field))}
        onChange={onUpdateNotes}
      />

      {/* Only show Exercise Scheduler button if copilotDashboard exists with required fields */}
      {view === 'Draft' && copilotDashboard && copilotDashboard.activities && (
        <div style={{ display: 'flex', gap: '16px', marginBottom: '16px', marginTop: '10px' }}>
          <Button
            type="primary"
            onClick={() => setIsModalOpen(true)}
            disabled={!copilotDashboard.allActivities || !copilotDashboard.heartRateZones}
          >
            Open Exercise Scheduler
          </Button>
        </div>
      )}

      {/* Only show ExerciseGridContainer if there's schedule data */}
      {hasScheduleData(weeklySchedule, hsdByIntensity) && (
        <HideWrap
          name="Exercise Scheduler"
          title="Exercise Scheduler"
          syncStatus={(name, status) => hideSectionHandle(name, status)}
          status={hideSection || []}
          view={view}
          isHealthRisk={true}
        >
          <ExerciseGridContainer showHsdChart={!isSectionHidden('HSD Intensity Chart')}>
            <ChartContainer>
              {renderHSDChart()}
          </ChartContainer>

          <SchedulesContainer>
            <HideWrap
              name="Exercise Schedule - Light Week"
              title="Light Week Schedule"
              syncStatus={(name, status) => hideSectionHandle(name, status)}
              status={hideSection || []}
              view={view}
              isHealthRisk={true}
            >
              <div>
                {renderWeeklySchedule(weeklySchedule, 'light')}
              </div>
            </HideWrap>
            <HideWrap
              name="Exercise Schedule - Moderate Week"
              title="Moderate Week Schedule"
              syncStatus={(name, status) => hideSectionHandle(name, status)}
              status={hideSection || []}
              view={view}
              isHealthRisk={true}
            >
              <div>
                {renderWeeklySchedule(weeklySchedule, 'moderate')}
              </div>
            </HideWrap>
            <HideWrap
              name="Exercise Schedule - Vigorous Week"
              title="Vigorous Week Schedule"
              syncStatus={(name, status) => hideSectionHandle(name, status)}
              status={hideSection || []}
              view={view}
              isHealthRisk={true}
            >
              <div>
                {renderWeeklySchedule(weeklySchedule, 'vigorous')}
              </div>
            </HideWrap>
            </SchedulesContainer>
          </ExerciseGridContainer>
        </HideWrap>
      )}

      <StepsSection
        editabled={editabled}
        data={getData(StepsConfigs.map(({ field }) => field))}
        onChange={onUpdateNotes}
      />
      <ExpectationAndNextSteps
        editabled={editabled}
        data={getData(expectationAndNextStepsConfigs.map(({ field }) => field))}
        onChange={onUpdateNotes}
      />
      <ReferralsSection
        editabled={editabled}
        data={referrals}
        onSave={onUpdateReferral}
        updateArDetail={handleUpdateArDetail}
      />
      <ResourceSection
        view={view}
        hideSection={hideSection}
        hideSectionHandle={hideSectionHandle}
      />
      <Modal
        open={isModalOpen}
        onCancel={handleModalClose}
        width="95%"
        footer={null}
        destroyOnClose={false}
      >
        {isModalOpen && (
          <ExerciseScheduler
            onCloseModal={() => setIsModalOpen(false)}
            onSubmit={async (data) => {
              setIsUpdating(true)
              try {
                setWeeklySchedule(data.weekly_schedule)
                setHsdByIntensity(data.hsd_intensity)
                setIsModalOpen(false)
              } finally {
                setIsUpdating(false)
              }
            }}
            goals={goals}
            lifestyleScore={lifestyleScore}
            hsd={hsd}
            initialWeeklySchedule={weeklySchedule}
            initialHsdIntensity={hsdByIntensity}
            copilotDashboard={copilotDashboard}
            person={person}
            arDetail={arDetail}
          />
        )}
      </Modal>
    </RecommendationContainer>
  )
}
