import moment from 'moment'
import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Divider, Button, Tooltip, message } from 'antd'
import { getRole } from '../../utils/common'
import { getList, deleteAR } from '../../api/assessmentReview'
import DataTable from '../UI/table'
import Confirm from '../UI/confirmModal'

import { EditCircleIcon, ViewCircleIcon } from '../icons/weeklySummary'
import DelIcon from '../../asserts/icon/weekly-summary/delete.svg'
import CopyIcon from '../../asserts/icon/client/Duplicate.png'
import { get } from 'lodash'

const Container = styled.div`
  background: #fff;
  color: #5c626a;
  opacity: 0.8;
  .ant-table-thead {
    text-transform: uppercase;
  }
  .status-circle {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin-right: 8px;
    display: inline-block;
    background: gray;
  }
  .status-0 {
    background: #f7c137;
  }
  .status-1 {
    background: #7bad2d;
  }
  .anticon {
    font-size: 32px;
    color: #264382;
  }
  .del-icon {
    margin-left: 12px;
    vertical-align: top;
    cursor: pointer;
  }
`

const Creator = styled.div`
  float: right;
  margin-top: -60px;
`

export default function List(props) {
  const { getPerson, match, setBreadcrumb } = props
  const [list, setList] = useState(null)
  const [person, setPerson] = useState({})
  const loginRole = getRole()
  const [confirmRecord, setConfirmRecord] = useState(false)

  useEffect(() => {
    const initial = async () => {
      const person = await getPerson()
      setPerson(person)
      let list = await getList(person.id)
      if (loginRole === 'User') {
        list = list.filter((item) => item.status === 1)
      }
      setList(list)
      setBreadcrumb && setBreadcrumb(person)
    }
    initial()
  }, [person.id])

  const handleDelete = async (id) => {
    await deleteAR(id)
    const list = await getList(person.id)
    setList(list)
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    message.success('Assessment Link Copied!')
  }  

  const columns = [
    {
      key: 'time',
      title: 'Time Period',
      render: (text, record, index) => (
        <>{moment(record.time_period).format('MMM YYYY')}</>
      )
    },
    {
      key: 'created_at',
      title: 'Date CREATED',
      render: (text, record, index) => (
        <>{moment(record.created_at).format('MMM DD, YYYY')}</>
      )
    },
    {
      key: 'published_at',
      title: 'Date PUBLISHED',
      render: (text, record, index) => (
        <>
          {record.published_at
            ? moment(record.published_at).format('MMM DD, YYYY')
            : '--'}
        </>
      )
    },
    {
      key: 'created_by',
      title: 'CREATED by',
      dataIndex: 'created_by'
    },
    {
      key: 'status',
      title: 'Status',
      render: (text, record, index) => (
        <>
          <span className={`status-circle status-${record.status}`} />
          {record.status === 0 ? 'Draft' : 'Published'}
        </>
      )
    },
    {
      key: 'actions',
      title: 'Actions',
      render: (text, record) => {
        const isPublished = record.status === 1
        const clientViewUrl = `${window.location.origin}/app/ass-report/preview/${record.id}`

        return (
          <>
            {record.status === 0 ? (
          <>
            <Link to={`${match.url}/draft/${record.id}`}>
              <EditCircleIcon />
            </Link>
            {(loginRole === 'Expert' || loginRole === 'InternalAdmin') && (
              <img
                src={DelIcon}
                className="del-icon"
                alt="del"
                onClick={() => setConfirmRecord(record)}
              />
            )}
          </>
            ) : (
              <>
                <Link
                  to={`${match.url}/${
                    get(record, 'version') === 1 ? 'preview-v1' : 'preview'
                  }/${record.id}`}
                >
                  <ViewCircleIcon />
                </Link>

                {(loginRole === 'Expert' || loginRole === 'InternalAdmin') && (
                  <>
                    <img
                      src={DelIcon}
                      className="del-icon"
                      alt="del"
                      onClick={() => setConfirmRecord(record)}
                    />
                    {isPublished && (
                      <Tooltip title="Copy Assessment Link">
                        <img
                          src={CopyIcon}
                          className="del-icon"
                          alt="Copy"
                          onClick={() => copyToClipboard(clientViewUrl)}
                          style={{
                            width: '33px',
                            height: '33px'
                          }}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )
      }
    }
  ]

  return (
    <>
      <h1 className="page-title">Assessment Review & Milestone Planning</h1>
      {(loginRole === 'Expert' || loginRole === 'InternalAdmin') && (
        <Creator>
          <Link to={`${match.url}/draft/new`}>
            <Button type="primary">
              <PlusOutlined /> CREATE NEW ASSESSMENT REVIEW
            </Button>
          </Link>
        </Creator>
      )}

      <Divider />
      <Container>
        <DataTable
          columns={columns}
          dataSource={list || []}
          loading={!list}
          hidePagination={true}
        />
      </Container>
      {confirmRecord && (
        <ConfirmDelete
          handleDelete={handleDelete}
          onCancel={() => setConfirmRecord(false)}
          target={confirmRecord}
        />
      )}
    </>
  )
}

function ConfirmDelete(props) {
  const { handleDelete, onCancel, target } = props
  const [isDeleting, setIsDeleting] = useState(false)
  async function onConfirm() {
    setIsDeleting(true)
    await handleDelete(target.id)
    setIsDeleting(false)
    onCancel()
  }
  return (
    <Confirm
      {...{
        modalWidth: 564,
        title: (
          <span
            style={{
              fontSize: 16,
              color: '#262626',
              lineHeight: 1.7,
              display: 'inline-block'
            }}
          >
            <ExclamationCircleOutlined
              style={{ color: '#F9B300', marginRight: 8 }}
            />
            {/* {getModalTitle()} */}
            Do you want to delete:{' '}
            {moment(target.time_period).format('MMM YYYY')}?
          </span>
        ),
        onCancel: onCancel,
        footer: (
          <>
            <Button onClick={onCancel} style={{ height: 'auto' }}>
              Cancel
            </Button>
            <Button
              danger
              loading={isDeleting}
              disabled={isDeleting}
              style={{ height: 'auto', marginLeft: 20 }}
              onClick={onConfirm}
            >
              Delete
            </Button>
          </>
        )
      }}
    />
  )
}
