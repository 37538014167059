import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { animateScroll as scroll } from 'react-scroll'
import lodash from 'lodash'
import moment from 'moment'
import { LeftOutlined } from '@ant-design/icons'
import { Divider, Row, Col } from 'antd'
import DetailItems from '../../goals/activity/detailItems'
import ActivityTrend from '../../goals/activity/trend'
import HeartRateZoneStage from '../../goals/activity/heartRateZoneStage'
import RPE from '../../goals/activity/rpe'
import AttachThumb from '../../UI/attachment/thumb'
import RightHalfModalContainer from '../../UI/halfPageModal'
import Confirm from '../../UI/confirmModal'
import filesize from '../../../utils/filesize'
import PageLoading from '../../UI/status/loading'
import {
  deleteProgramItem,
  getWorkoutDetail,
  deleteWorkout
} from '../../../api/program'
import {
  updateAdaptiveRecommendation,
  deleteAdaptiveRecommendation
} from '../../../api/adaptiveCoach'
import { saveRPE } from '../../../api/summaryEmail'
import EditIcon from '../../../asserts/icon/goals/edit.svg'
import TrashIcon from '../../../asserts/icon/goals/trash.svg'
// import FileMask from '../../../asserts/icon/client/Mask.svg'
import DetailStyle from '../style/detail.module.sass'
import WorkoutEditor from './workoutEditor'
import AiWorkoutEditor from './aiWorkoutEditor'
import ItemResources from './itemResources'

/**
 * Format duration (66, 'seconds') => 0:01:06
 * @param duration
 * @param unit
 */
const _formatDuration = (duration, unit, hoursInclude = true) => {
  const momentDuration = moment.duration(duration, unit)
  return momentDuration.format(
    `${hoursInclude || momentDuration.hours() > 0 ? 'h:' : ''}mm:ss`,
    {
      trim: false
    }
  )
}

class ItemDetail extends Component {
  static propTypes = {
    closeHandle: PropTypes.func,
    item: PropTypes.object,
    operateHandle: PropTypes.func,
    deleteAsync: PropTypes.func,
    personId: PropTypes.number,
    aiWorkoutsDate: PropTypes.string,
    itemDurationMapping: PropTypes.array
  }

  state = {
    showDeleteConfirm: false,
    showDeleteWorkoutConfirm: false,
    isDeleting: false,
    loadingWorkout: false,
    showEditWorkout: false,
    showAiEditor: false,
    workout: null
  }

  deleteItem = async () => {
    const { item, deleteAsync, personId, aiWorkoutsDate } = this.props
    this.setState({ isDeleting: true })
    
    // Handle deletion based on item type
    if (item.isAiRecommendation) {
      // For AI recommendations, call the API to delete them
      await deleteAdaptiveRecommendation(
        personId, 
        aiWorkoutsDate,
        item.id
      )
    } else {
      // Original deletion logic for program items
      await deleteProgramItem(item.id)
    }
    
    this.setState({ isDeleting: false, showDeleteConfirm: false })
    deleteAsync()
  }

  deleteWorkout = async () => {
    const { item, deleteAsync } = this.props
    this.setState({ isDeleting: true })
    await deleteWorkout(item.id)
    this.setState({ isDeleting: false, showDeleteWorkoutConfirm: false })
    deleteAsync()
  }

  fetchWorkout = async (id) => {
    this.setState({ loadingWorkout: true })
    const { workout } = await getWorkoutDetail(id)
    this.setState({ workout, loadingWorkout: false })
  }

  setManualRpe = async (id, value) => {
    const { intensityRange } = this.props
    const workout = await saveRPE(id, value)
    let intensity
    for (const range in intensityRange) {
      const [a, b] = intensityRange[range]
      if (value >= a && value <= b) {
        intensity = range
      }
    }
    this.setState({ workout, intensity })
  }

  // Update the handler for saving AI recommendation updates
  saveAiRecommendation = async (updatedItem) => {
    const { deleteAsync } = this.props
    this.setState({ isDeleting: true }) // Reuse loading state
    
    // Call API to update the AI recommendation with person_id
    await updateAdaptiveRecommendation(
      updatedItem.person_id, 
      updatedItem.id, 
      updatedItem
    )
    
    this.setState({ 
      isDeleting: false, 
      showEditWorkout: false 
    })
    
    // Refresh the parent component
    deleteAsync()
  }

  componentDidMount() {
    scroll.scrollToTop({ ignoreCancelEvents: true, smooth: true })
    const { item } = this.props
    // Only fetch workout if it's a real workout (not AI or program item)
    if (item.record_date && !item.isAiRecommendation) {
      this.fetchWorkout(item.id)
    }
  }

  renderPlannedDuration(planned) {
    const { itemDurationMapping } = this.props
    if (planned && planned.duration) {
      const mappedValue =
        itemDurationMapping &&
        itemDurationMapping.find((e) => e.value === planned.duration)
      if (mappedValue) {
        return (
          <>
            {mappedValue.label.match(/[0-9\-\\+]+/)[0]}
            <span> min</span>
          </>
        )
      } else {
        return _formatDuration(planned.duration, 'seconds')
      }
    } else {
      return '--'
    }
  }

  renderComparison(planned, completed) {
    const { intensity } = this.state
    return (
      <div className={DetailStyle.compareContainer}>
        <div className={DetailStyle.compareTitle}>
          <div>Planned</div>
          <div className={DetailStyle.vs}>
            <div>vs</div>
          </div>
          <div>Completed</div>
        </div>
        <div className={DetailStyle.compareRow}>
          <div className={DetailStyle.boldText}>
            {this.renderPlannedDuration(planned)}
          </div>
          <div> Duration</div>
          <div className={DetailStyle.boldText}>
            {(completed && _formatDuration(completed.duration, 'seconds')) ||
              '--'}
          </div>
        </div>

        <div className={DetailStyle.compareRow}>
          <div className={DetailStyle.boldText}>
            {(planned && planned.intensity) || '--'}
          </div>
          <div>Intensity</div>
          <div className={DetailStyle.boldText}>
            {intensity || (completed && completed.intensity) || '--'}
          </div>
        </div>
      </div>
    )
  }

  renderAttachment(attach) {
    const name = attach.name || attach.oss_name || ''
    const fileType = name.split('.').pop() || attach.type
    return (
      <>
        <div>
          {/* <img src={attach.oss_url || FileMask} alt="file" /> */}
          <AttachThumb attach={attach} />
        </div>
        <div>
          <div className={DetailStyle.fileName}>
            {attach.title || attach.name}
            {/* {fileType === 'url' ? attach.title : name} */}
          </div>
          {fileType} •{' '}
          {fileType === 'url'
            ? attach.url
            : attach.size
            ? filesize(Number(attach.size), { round: 1 })
            : '--'}
        </div>
      </>
    )
  }

  renderWorkout() {
    const {
      item: { program_item_id },
      programItems
    } = this.props
    const { workout } = this.state
    const activityDetail = (workout && workout.details) || {}
    if (workout && workout.manual) {
      activityDetail.source = 'manual'
      activityDetail.formattedDuration = _formatDuration(
        activityDetail.duration,
        'seconds'
      )
      activityDetail.activity_classification = workout.shfitType || workout.type
    }
    // Find action item
    const actionItem = lodash.find(
      programItems,
      (item) => item.id === program_item_id
    )
    return (
      <div>
        {program_item_id && <Divider />}
        <DetailItems
          // showHeartRate={true}
          manualDetail={(workout && workout.manual_details) || null}
          activityDetail={activityDetail}
        />

        {workout && !workout.manual && (
          <>
            <Divider />
            <Row gutter={0}>
              <Col span={12}>
                <div className={DetailStyle.heartRateTitle}>Heart Rate</div>
                <ActivityTrend
                  // id={activityDetailIndex}
                  detail={activityDetail}
                />
              </Col>
              <Col span={12}>
                <HeartRateZoneStage
                  editGoal={{}}
                  activityDetail={activityDetail}
                  calculatePercentage={true}
                />
              </Col>
            </Row>
          </>
        )}

        <Divider />
        <RPE
          activityDetail={{
            record_id: workout && workout.id,
            ...lodash.pickBy(
              workout,
              (_, key) =>
                key.includes('rpe_') ||
                key === 'training_load' ||
                key === 'tracking_metrics'
            )
          }}
          setManualRpe={this.setManualRpe}
        />
        {actionItem &&
          actionItem.attachments &&
          actionItem.attachments.length > 0 && (
            <>
              <Divider />
              <ItemResources
                inForm={false}
                attachments={actionItem.attachments}
              />
            </>
          )}
      </div>
    )
  }

  renderIntensityAndDesc(planned, desc) {
    const description = {
      Low:
        'Your breathing is deeper but comfortable. You can talk in complete sentences.',
      Moderate: 'You’re breathing deeply and your talking becomes broken.',
      High: 'You’re breathing hard and getting noticeably uncomfortable.',
      'Very High':
        'You can only maintain this activity for a few minutes. You’re breathing forcefully and can’t talk.'
    }
    return (
      <>
        <Divider />
        {desc && (
          <>
            <div className={DetailStyle.descriptionTitle}>Description</div>
            <div className={DetailStyle.description}>{desc}</div>
          </>
        )}
        <div
          className={DetailStyle.descriptionTitle}
          style={{
            marginTop: desc ? '42px' : '5px'
          }}
        >
          Intensity
        </div>
        <div className={DetailStyle.description}>
          {description[planned.intensity]}
        </div>
      </>
    )
  }

  renderItemInfo(item) {
    const metrics = [
      {
        key: 'Workout Type',
        value: item.workout_type
      },
      {
        key: 'Category',
        value: item.category
      },
      {
        key: 'Duration',
        value: item.workout_type
      },
      {
        key: 'Intensity',
        value: item.intensity
      },
      {
        key: 'Frequency',
        value: item.frequency
      }
    ]
    return (
      <Row gutter={[8, 28]}>
        {metrics.map((obj, index) => {
          return (
            <Col span={12} key={index}>
              <div className={DetailStyle.itemInfoTitle}>{obj.key}</div>
              <div className={DetailStyle.itemInfoValue}>
                {obj.key === 'Duration'
                  ? this.renderPlannedDuration(item)
                  : obj.value}
              </div>
            </Col>
          )
        })}
      </Row>
    )
  }

  render() {
    const {
      closeHandle,
      item,
      operateHandle,
      modalContainerStyle,
      position,
      programItems,
      personId,
      aiWorkoutsDate,
      itemDurationMapping
    } = this.props
    const {
      isDeleting,
      showDeleteConfirm,
      loadingWorkout,
      showDeleteWorkoutConfirm,
      showEditWorkout,
      showAiEditor,
      workout
    } = this.state
    
    // Determine if this is a completed workout vs planned item
    const isCompletedWorkout = item.record_date && !item.isAiRecommendation
    
    // For program items or AI recommendations
    let planned, completed
    if (isCompletedWorkout) {
      completed = item
      planned = item.program_item_id && programItems.find((i) => i.id === item.program_item_id)
    } else {
      planned = item
    }

    // Allow all users to edit/delete, regardless of position
    const suffix = [
      <img
        key="edit"
        src={EditIcon}
        alt="edit"
        onClick={() => {
          if (isCompletedWorkout) {
            this.setState({ showEditWorkout: true })
          } else if (item.isAiRecommendation) {
            // For AI recommendations, show the AI editor instead
            this.setState({ showAiEditor: true })
          } else {
            operateHandle({ showExerciseEditor: true })
          }
        }}
      />,
      <img
        key="trash"
        src={TrashIcon}
        alt="trash"
        onClick={() => {
          if (isCompletedWorkout) {
            this.setState({ showDeleteWorkoutConfirm: true })
          } else {
            this.setState({ showDeleteConfirm: true })
          }
        }}
      />
    ]

    return (
      <RightHalfModalContainer
        title={item.category || item.type}
        extraTitle={item.workout_type}
        position="right"
        prefix={<LeftOutlined onClick={closeHandle} />}
        close={closeHandle}
        extraClass="goal-detail-bg"
        suffix={suffix}
        containerStyle={modalContainerStyle}
      >
        {/* Only show comparison if it's a completed program workout */}
        {item.program_item_id && !item.isAiRecommendation && 
          this.renderComparison(planned, completed)}

        {/* Show item info for program items and AI recommendations */}
        {(!isCompletedWorkout) && this.renderItemInfo(item)}

        {item && (
          <>
            {isCompletedWorkout ? (
              <>{loadingWorkout ? <PageLoading /> : this.renderWorkout()}</>
            ) : (
              <>
                {this.renderIntensityAndDesc(
                  planned,
                  item.description
                )}

                {item.attachments && item.attachments.length > 0 && (
                  <>
                    <Divider />
                    <ItemResources
                      inForm={false}
                      attachments={item.attachments}
                    />
                  </>
                )}
              </>
            )}

            {showDeleteConfirm && (
              <Confirm
                {...{
                  modalWidth: 600,
                  title: 'Delete program item',
                  description: 'Do you want to delete this item?',
                  loading: isDeleting,
                  onConfirm: this.deleteItem,
                  onCancel: () => this.setState({ showDeleteConfirm: false })
                }}
              />
            )}
            {showDeleteWorkoutConfirm && (
              <Confirm
                {...{
                  modalWidth: 600,
                  title: 'Delete workout',
                  description: 'Do you want to delete this workout?',
                  loading: isDeleting,
                  onConfirm: this.deleteWorkout,
                  onCancel: () =>
                    this.setState({ showDeleteWorkoutConfirm: false })
                }}
              />
            )}
            {showEditWorkout && workout && (
              <WorkoutEditor
                {...{
                  workout: workout,
                  cancel: () => {
                    this.setState({ showEditWorkout: false })
                  },
                  edited: () => {
                    this.props.deleteAsync(true)
                  }
                }}
              />
            )}
            {showAiEditor && (
              <AiWorkoutEditor
                exercise={item}
                personId={personId}
                aiWorkoutsDate={aiWorkoutsDate}
                itemDurationMapping={itemDurationMapping}
                cancel={() => {
                  this.setState({ showAiEditor: false })
                }}
                confirmCallback={(updatedItem) => {
                  this.setState({ showAiEditor: false })
                  this.props.deleteAsync(updatedItem)
                }}
              />
            )}
          </>
        )}
      </RightHalfModalContainer>
    )
  }
}

export default ItemDetail
