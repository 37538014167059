import React, { useCallback, useMemo, useEffect, useState, useRef } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'
import Modal from 'react-modal' // Import react-modal for popups
import './styles.css' // Import the CSS file
import axios from 'axios' // Add this import at the top

import { calculateTotalHSD } from './HSDCalculator'
import { getTypeColor, calculateDayIntensity, calculateTotalTime, getIntensityBarColor, getIntensityDisplay } from './utils'
import { HealthDomainChart } from './charts'
import { ActivityModal, ActivityGroup } from './ActivityModal'
import { RemoveButton, StyledCard, StyledContainer, StyledSection, StyledSectionOverflow } from './StyledSections'
import TopGrid from './TopGrid'
import { CalendarCarousel } from './CalendarCarousel'

// Initialize react-modal
Modal.setAppElement('#root')

// Update the formatScheduleData function to assign HSD values
const formatScheduleData = (schedule, allActivities) => {
    if (!schedule) return null

    const formattedSchedule = {
        light: {
            monday: [], tuesday: [], wednesday: [],
            thursday: [], friday: [], saturday: [], sunday: []
        },
        moderate: {
            monday: [], tuesday: [], wednesday: [],
            thursday: [], friday: [], saturday: [], sunday: []
        },
        vigorous: {
            monday: [], tuesday: [], wednesday: [],
            thursday: [], friday: [], saturday: [], sunday: []
        }
    }

    Object.entries(schedule).forEach(([intensity, days]) => {
        Object.entries(days).forEach(([day, activities]) => {
            if (!Array.isArray(activities)) return

            formattedSchedule[intensity][day] = activities.map(activity => {
                // Find the matching activity in allActivities to get the HSD values
                const sourceActivity = allActivities.find(a => a.name === activity.name)

                return {
                    id: `${intensity}-${day}-${activity.name}-${Date.now()}`,
                    name: activity.name || 'Unnamed Activity',
                    time: activity.time || 30,
                    rpe: activity.rpe || null,
                    type: sourceActivity.type,
                    hsd: sourceActivity.hsd,
                    instanceId: Date.now()
                }
            })
        })
    })

    return formattedSchedule
}

const ExerciseScheduler = ({
    onCloseModal,
    onSubmit,
    goals,
    lifestyleScore,
    hsd,
    initialWeeklySchedule,
    initialHsdIntensity,
    copilotDashboard,
    person,
    arDetail
}) => {
    // Add validation check for copilotDashboard and its properties
    const validCopilotDashboard = useMemo(() => {
        return copilotDashboard && {
            allActivities: copilotDashboard.allActivities || [],
            heartRateZones: copilotDashboard.heartRateZones || {},
            activities: {
                rationale: (copilotDashboard.activities && copilotDashboard.activities.rationale) || '',
                likes: (copilotDashboard.activities && copilotDashboard.activities.likes) || [],
                increase: (copilotDashboard.activities && copilotDashboard.activities.increase) || [],
                dislikes: (copilotDashboard.activities && copilotDashboard.activities.dislikes) || [],
                decrease: (copilotDashboard.activities && copilotDashboard.activities.decrease) || []
            }
        }
    }, [copilotDashboard])

    // Add useMemo for expensive computations
    const memoizedWeeklySchedule = useMemo(() => ({
        light: {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: []
        },
        moderate: {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: []
        },
        vigorous: {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: []
        }
    }), [])

    // Modify INITIAL_HSD to match the structure of your data
    const INITIAL_HSD = {
        light: {
            bone: 0,
            aerobic_fitness: 0,
            balance: 0,
            muscular_fitness: 0,
            cardiometabolic: 0,
            body_composition: 0,
            cognitive_health: 0,
            movement_quality: 0
        },
        moderate: {
            bone: 0,
            aerobic_fitness: 0,
            balance: 0,
            muscular_fitness: 0,
            cardiometabolic: 0,
            body_composition: 0,
            cognitive_health: 0,
            movement_quality: 0
        },
        vigorous: {
            bone: 0,
            aerobic_fitness: 0,
            balance: 0,
            muscular_fitness: 0,
            cardiometabolic: 0,
            body_composition: 0,
            cognitive_health: 0,
            movement_quality: 0
        }
    }

    const [weeklySchedule, setWeeklySchedule] = useState(
        initialWeeklySchedule ? formatScheduleData(initialWeeklySchedule, (validCopilotDashboard && validCopilotDashboard.allActivities) || []) : memoizedWeeklySchedule
    )
    const [allActivities, setAllActivities] = useState(
        (validCopilotDashboard && validCopilotDashboard.allActivities) || []
    )
    console.info('allActivities', allActivities)
    console.info('heartRateZones', validCopilotDashboard.heartRateZones)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [currentActivity, setCurrentActivity] = useState(null)
    const [hsdByIntensity, setHsdByIntensity] = useState(initialHsdIntensity || INITIAL_HSD)

    // Add state for current intensity at the top level
    const [currentIntensity, setCurrentIntensity] = useState('light')

    // Add new state for saving status
    const [isSaving, setIsSaving] = useState(false)

    // Update useEffect for HSD calculation with safety checks
    useEffect(() => {
        if (!weeklySchedule || !validCopilotDashboard) return

        try {
            const newHsdByIntensity = calculateTotalHSD(weeklySchedule, validCopilotDashboard)
            console.info('New HSD calculations:', newHsdByIntensity)

            if (newHsdByIntensity && typeof newHsdByIntensity === 'object') {
                const hasValidValues = Object.values(newHsdByIntensity).some(intensity =>
                    Object.values(intensity).some(value => value > 0)
                )

                if (hasValidValues) {
                    setHsdByIntensity(prev => {
                        console.info('Updating HSD from:', prev, 'to:', newHsdByIntensity)
                        return newHsdByIntensity
                    })
                } else {
                    console.warn('Calculated HSD contains all zero values')
                }
            } else {
                console.warn('Invalid HSD calculation result:', newHsdByIntensity)
            }
        } catch (error) {
            console.error('Error calculating HSD:', error)
        }
    }, [weeklySchedule, validCopilotDashboard])

    // Modify handleClose to include the API call
    const handleClose = async () => {
        try {
            setIsSaving(true)

            const update_data = {
                weekly_schedule: weeklySchedule,
                hsd_intensity: hsdByIntensity
            }

            // First update the UI through the existing onSubmit
            await onSubmit(update_data)

            // Then save to backend
            const apiUrl = `${process.env.REACT_APP_ML_API_ENDPOINT}/api/ml/assessment_review/${person.id}/${arDetail.id}/exercise-recommendation`
            await axios.put(apiUrl, {
                weekly_schedule: weeklySchedule,
                hsd_intensity: hsdByIntensity
            })

        } catch (error) {
            console.error('Error saving exercise recommendation:', error)
            // You might want to show an error message to the user
            alert('Error saving exercise recommendation. Please try again.')
            return // Don't close the modal if save failed
        } finally {
            setIsSaving(false)
            onCloseModal()
        }
    }

    // Update handleDragEnd to include HSD values when creating new activities
    const handleDragEnd = useCallback((result) => {
        if (!result.destination) return

        const { source, destination, draggableId } = result
        const sourceDroppableId = source.droppableId
        const destDroppableId = destination.droppableId

        // Parse the dropppable IDs to get intensity and day
        const getIntensityAndDay = (droppableId) => {
            const parts = droppableId.replace('day-', '').split('-')
            return {
                intensity: parts[0],
                day: parts[1]
            }
        }

        // If dragging from activities list to a day
        if (sourceDroppableId === 'activities' && destDroppableId.startsWith('day-')) {
            const { intensity, day } = getIntensityAndDay(destDroppableId)

            const activityId = draggableId.replace('activity-', '')
            const activity = allActivities.find(a => a.id.toString() === activityId.toString())

            if (!activity) {
                console.info('No activity found with id:', activityId)
                return
            }

            // Create a new unique instance of the activity with only necessary values
            const newActivity = {
                ...activity,
                id: `scheduled-${activity.id}-${Date.now()}`,
                name: activity.name || 'Unnamed Activity',
                type: activity.type || 'aerobic',
                time: activity.default_duration || 30,
                heartRateZone: null,
                difficultyLevel: null,
                rpe: activity.default_rpe || 5,
                hsd: activity.hsd,
                instanceId: Date.now()
            }

            setWeeklySchedule(prev => ({
                ...prev,
                [intensity]: {
                    ...prev[intensity],
                    [day]: [...prev[intensity][day], newActivity]
                }
            }))
        }
        // If dragging within the same intensity calendar or between intensity calendars
        else if (destDroppableId.startsWith('day-')) {
            const source = getIntensityAndDay(sourceDroppableId)
            const dest = getIntensityAndDay(destDroppableId)

            setWeeklySchedule(prev => {
                // Create deep copy of the source day's activities
                const sourceActivities = [...prev[source.intensity][source.day]]
                // Remove the activity from the source
                const [movedActivity] = sourceActivities.splice(source.index, 1)

                // Create deep copy of the destination day's activities
                const destActivities = [...prev[dest.intensity][dest.day]]
                // Insert the activity at the new position
                destActivities.splice(destination.index, 0, movedActivity)

                return {
                    ...prev,
                    [source.intensity]: {
                        ...prev[source.intensity],
                        [source.day]: sourceActivities
                    },
                    [dest.intensity]: {
                        ...prev[dest.intensity],
                        [dest.day]: destActivities
                    }
                }
            })
        }
    }, [allActivities])

    const openModal = (activity, day, intensity) => {
        setCurrentActivity({ ...activity, day, intensity })
        setModalIsOpen(true)
    }

    const closeModal = () => {
        setModalIsOpen(false)
        setCurrentActivity(null)
    }

    const saveActivityDetails = (updatedActivity) => {
        const { day, intensity, id } = updatedActivity
        setWeeklySchedule((prev) => ({
            ...prev,
            [intensity]: {
                ...prev[intensity],
                [day]: prev[intensity][day].map((activity) =>
                    activity.id === id ? updatedActivity : activity
                )
            }
        }))
    }

    const handleRemoveActivity = (intensity, day, activityId) => {
        setWeeklySchedule(prev => ({
            ...prev,
            [intensity]: {
                ...prev[intensity],
                [day]: prev[intensity][day].filter(activity => activity.id !== activityId)
            }
        }))
    }

    // Modify the renderCalendar function to include the total time display
    const renderCalendar = (intensityLevel) => {
        const totalTime = calculateTotalTime(weeklySchedule[intensityLevel.toLowerCase()])

        return (
            <div className="calendar-container">
                <div className="total-time-display">
                    Total Time: {totalTime} minutes
                </div>
                <div className="calendar-grid">
                    {Object.keys(weeklySchedule[intensityLevel.toLowerCase()]).map((day) => {
                        const dayActivities = weeklySchedule[intensityLevel.toLowerCase()][day]
                        const dayIntensity = calculateDayIntensity(dayActivities)

                        return (
                            <Droppable
                                key={`${intensityLevel}-${day}`}
                                droppableId={`day-${intensityLevel.toLowerCase()}-${day}`}
                                type="activity"
                            >
                                {(provided, snapshot) => (
                                    <div className="calendar-day-wrapper">
                                        <div
                                            className="intensity-bar"
                                            style={{
                                                width: '100%',
                                                height: `${Math.min(dayIntensity * 2, 80)}px`, // Height grows with dayIntensity
                                                backgroundColor: getIntensityBarColor(dayIntensity)
                                            }}
                                        />
                                        <div
                                            className="calendar-day"
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            <h3 className="calendar-day-title">
                                                {day.charAt(0).toUpperCase() + day.slice(1)}
                                            </h3>
                                            {weeklySchedule[intensityLevel.toLowerCase()][day].map((activity, index) => (
                                                <Draggable
                                                    key={activity.id}
                                                    draggableId={activity.id.toString()}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            className="scheduled-activity"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={{
                                                                ...provided.draggableProps.style,
                                                                backgroundColor: getTypeColor(activity.type),
                                                                height: `${Math.max(50, activity.time)}px`
                                                            }}
                                                        >
                                                            <RemoveButton
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    handleRemoveActivity(intensityLevel.toLowerCase(), day, activity.id)
                                                                }}
                                                            />
                                                            <div
                                                                onClick={() => openModal(activity, day, intensityLevel.toLowerCase())}
                                                                className="activity-content"
                                                            >
                                                                <span className="activity-name">{activity.name}</span>
                                                                <div className="activity-details">
                                                                    <span className="activity-duration">{activity.time}min</span>
                                                                    <span className="activity-intensity">{getIntensityDisplay(activity)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                        )
                    })}
                </div>
            </div>
        )
    }

    // Update the HSD chart rendering with better validation
    const renderHsdChart = (intensity) => {
        if (!hsdByIntensity || !hsdByIntensity[intensity]) {
            return <p>No data available for {intensity} intensity activities</p>
        }

        const data = {
            labels: Object.keys(hsdByIntensity[intensity]).filter(key => key !== 'cardiometabolic'),
            values: Object.entries(hsdByIntensity[intensity])
                .filter(([key]) => key !== 'cardiometabolic')
                .map(([_, value]) => value === null ? 0 : Number(value) || 0)
        }

        return (
            <HealthDomainChart
                data={data}
                height={300}
                y_axis={400}
            />
        )
    }

    // Update the activities list section to include HSD plot
    const renderActivitiesList = (provided) => (
        <div className="activities-grid">
            <div className="activities-column">
                <div className="activities-group resistance">
                    <h4>Resistance</h4>
                    <div className="activities-row" ref={provided.innerRef} {...provided.droppableProps}>
                        {allActivities
                            .filter(activity => activity && activity.type === 'resistance')
                            .map((activity, index) => (
                                <Draggable
                                    key={`activity-${activity.id}`}
                                    draggableId={`activity-${activity.id}`}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            className={`activity-bubble draggable ${snapshot.isDragging ? 'dragging' : ''}`}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                ...provided.draggableProps.style,
                                                backgroundColor: getTypeColor('resistance')
                                            }}
                                        >
                                            {activity.name || 'Unnamed Activity'}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                    </div>

                    <div className="activities-group aerobic">
                        <h4>Aerobic</h4>
                        <div className="activities-row">
                            {allActivities
                                .filter(activity => activity && activity.type === 'aerobic')
                                .map((activity, index) => (
                                    <Draggable
                                        key={`activity-${activity.id}`}
                                        draggableId={`activity-${activity.id}`}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                className={`activity-bubble draggable ${snapshot.isDragging ? 'dragging' : ''}`}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                    ...provided.draggableProps.style,
                                                    backgroundColor: getTypeColor('aerobic')
                                                }}
                                            >
                                                {activity.name || 'Unnamed Activity'}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                        </div>
                    </div>

                    <div className="activities-group activity">
                        <h4>Activity</h4>
                        <div className="activities-row">
                            {allActivities
                                .filter(activity => activity && activity.type === 'activity')
                                .map((activity, index) => (
                                    <Draggable
                                        key={`activity-${activity.id}`}
                                        draggableId={`activity-${activity.id}`}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                className={`activity-bubble draggable ${snapshot.isDragging ? 'dragging' : ''}`}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                    ...provided.draggableProps.style,
                                                    backgroundColor: getTypeColor('activity')
                                                }}
                                            >
                                                {activity.name || 'Unnamed Activity'}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="activities-chart-column">
                <div className="hsd-plot-container">
                    {renderHsdChart(currentIntensity)}
                </div>
            </div>
        </div>
    )

    if (!validCopilotDashboard) {
        return <div className="loading-state">Loading dashboard data...</div>
    }

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <StyledCard>
                <StyledContainer>
                    <TopGrid
                        lifestyleScore={lifestyleScore || {}}
                        goals={goals || {}}
                        hsd={hsd || {}}
                        rationale={validCopilotDashboard.activities.rationale}
                    />

                    {/* Activity Groups with null checks */}
                    <div className="activity-groups-horizontal">
                        <ActivityGroup
                            title="Preferred Activities"
                            activities={validCopilotDashboard.activities.likes}
                        />
                        <ActivityGroup
                            title="Activities to Increase"
                            activities={validCopilotDashboard.activities.increase}
                        />
                        <ActivityGroup
                            title="Activities to Avoid"
                            activities={validCopilotDashboard.activities.dislikes}
                        />
                        <ActivityGroup
                            title="Activities to Decrease"
                            activities={validCopilotDashboard.activities.decrease}
                        />
                    </div>

                    {/* Draggable Activities List and Weekly Schedule in the same frame */}
                    <StyledSection title="Activities and Schedule" style={{ display: 'flex', flexDirection: 'row', overflow: 'hidden', maxHeight: '100%' }}>
                        <Droppable droppableId="activities" direction="horizontal" type="activity">
                            {(provided) => renderActivitiesList(provided)}
                        </Droppable>

                        <div style={{ flex: 1, marginLeft: '20px' }}> {/* Adjust margin as needed */}
                            <StyledSection title="Weekly Schedule" style={{ marginTop: '0' }}> {/* Remove top margin */}
                                <div style={{
                                    textAlign: 'right',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    gap: '10px',
                                    marginBottom: '10px'
                                }}>
                                    <button
                                        onClick={handleClose}
                                        className="save-button-ex"
                                        disabled={isSaving}
                                        style={{
                                            height: '30px',
                                            width: '100px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            border: 'none',
                                            opacity: isSaving ? 0.5 : 1,
                                            cursor: isSaving ? 'not-allowed' : 'pointer'
                                        }}
                                    >
                                        {isSaving ? (
                                            <span className="loading-spinner"></span>
                                        ) : 'Save'}
                                    </button>
                                </div>
                                <CalendarCarousel onIntensityChange={setCurrentIntensity}>
                                    {renderCalendar('light')}
                                    {renderCalendar('moderate')}
                                    {renderCalendar('vigorous')}
                                </CalendarCarousel>
                            </StyledSection>
                        </div>
                    </StyledSection>
                </StyledContainer>
            </StyledCard>

            {/* Activity Details Modal */}
            {currentActivity && (
                <ActivityModal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    onSave={saveActivityDetails}
                    activity={currentActivity}
                />
            )}
        </DragDropContext>
    )
}

ExerciseScheduler.propTypes = {
    onCloseModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    goals: PropTypes.object,
    lifestyleScore: PropTypes.object,
    hsd: PropTypes.object,
    initialWeeklySchedule: PropTypes.object,
    initialHsdIntensity: PropTypes.object,
    copilotDashboard: PropTypes.object.isRequired,
    person: PropTypes.object.isRequired,
    arDetail: PropTypes.object.isRequired
}

export default ExerciseScheduler
