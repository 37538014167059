import React from 'react'

import styled from 'styled-components'

const Container = styled.div`
  padding: 23px 0px 23px 56px;
  background: rgb(255, 255, 255);
  ul {
    list-style: none;
    display: flex;
    font-size: 18px;
    color: #29303e;
    line-height: 150%;
    margin: 0;
    font-family: 'Gilroy';
    padding: 0;
    li {
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        width: 26px;
        height: 26px;
        text-align: center;
        color: #98a9bc;
        font-size: 12px;
        font-family: 'Lato';
        margin-right: 12px;
        border: 1px solid #98a9bc;
        border-radius: 100%;
        line-height: 24px;
      }
      &:after {
        content: '';
        margin: 0 16px;
        border-bottom: 1px solid #bac3ce;
        width: 10px;
      }
      &.active span {
        background: #264382;
        color: #fafcfd;
      }
      &:last-child:after {
        border: none;
      }
    }
  }
`

let list = [
  {
    showIndex: 1,
    name: 'Lifestyle & Goals'
  },
  {
    showIndex: 2,
    name: 'Medical History'
  },
  {
    showIndex: 3,
    name: 'Medications & Supplements'
  },
  {
    showIndex: 4,
    name: 'Capacity to Exercise'
  },
  {
    showIndex: 4,
    name: 'Physical Activity'
  },
  {
    showIndex: 5,
    name: 'Nutrition Signals'
  },
  {
    showIndex: 6,
    name: 'Sleep'
  }
]

export default function NavBar(props) {
  const { activeStep, sections } = props
  if (sections) {
    list = sections.map(({ name }, index) => ({ showIndex: index + 1, name }))
  }
  return (
    <Container>
      <ul>
        {list.map((item, index) => (
          <li key={index} className={activeStep === index ? 'active' : ''}>
            <span>{item.showIndex}</span>
            {item.name}
          </li>
        ))}
      </ul>
    </Container>
  )
}
