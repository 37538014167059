import React from 'react'
// import { RecommendationColors } from '../../utils'

export const RecommendationColors = {
  emerald: '#40C47C', // Kept as is - vibrant emerald
  green: '#7BAD2D', // Kept as is - natural green
  blue: '#86C2D8', // Kept as is - calm blue
  teal: '#20B2AA', // New - medium intensity
  purple: '#9370DB', // New - higher intensity
  indigo: '#4B0082' // New - highest intensity (replacing red)
}
function getIntensityColor(intensity) {
  if (intensity <= 4) return RecommendationColors.emerald
  if (intensity <= 6) return RecommendationColors.teal
  return RecommendationColors.indigo
}

export const ExerciseViz = ({ data, maxDuration, title }) => {
  const width = 500
  const height = 180
  const columnWidth = 10
  const padding = {
    top: 40, // Space for title
    left: 100, // Increased from 40 to accommodate longer labels
    right: 140, // Space for legend
    bottom: 20 // Space for duration axis
  }
  const verticalSpacing = 15

  // Calculate total duration for each category
  const getTotalDuration = (exercises) => {
    return exercises.reduce((sum, [duration]) => sum + duration, 0)
  }

  const maxTotalDuration = Math.max(
    getTotalDuration(data.activity),
    getTotalDuration(data.resistance),
    getTotalDuration(data.aerobic)
  )

  // Updated scale factor calculation to handle larger values better
  const availableWidth = width - padding.left - padding.right
  const baseScale = availableWidth / Math.max(maxTotalDuration, maxDuration)
  const heightScaleFactor = Math.min(Math.max(baseScale * 0.6, 0.15), 0.3) // Adjusted ranges
  console.info(heightScaleFactor)

  // Calculate the position where the last bar group ends
  const lastBarGroupY = padding.top + 10 + 2 * (columnWidth + verticalSpacing)

  // Add drop shadow filter definition
  const filters = (
    <defs>
      {/* Sleek drop shadow */}
      <filter id="drop-shadow" x="-20%" y="-20%" width="140%" height="140%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
        <feOffset dx="2" dy="2" result="offsetblur" />
        <feComponentTransfer>
          <feFuncA type="linear" slope="0.3" />
        </feComponentTransfer>
        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>

      {/* Glass effect */}
      <filter id="glass">
        <feGaussianBlur in="SourceGraphic" stdDeviation="0.5" />
        <feColorMatrix
          type="matrix"
          values="1 0 0 0 0
                            0 1 0 0 0
                            0 0 1 0 0
                            0 0 0 0.8 0"
        />
      </filter>

      {/* Updated background gradient */}
      <linearGradient id="panelGradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" style={{ stopColor: '#151B42', stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: '#1C3065', stopOpacity: 1 }} />
      </linearGradient>
    </defs>
  )

  const durationScale = (value) =>
    (value / maxDuration) * (width - 2 * padding.left) * heightScaleFactor

  // Calculate cumulative widths for stacking from left
  const getStackedX = (data, index) => {
    let totalWidth = 0
    for (let i = 0; i < index; i++) {
      totalWidth += durationScale(data[i][0])
    }
    return totalWidth
  }

  // Add horizontal spacing between bars
  const barSpacing = 4 // New spacing constant

  // In the BarGroup component, update the rect positions:
  const BarGroup = ({ data, yOffset, label }) => (
    <g transform={`translate(0, ${yOffset})`}>
      {data.map(([duration, intensity], i) => {
        const intensityColor = getIntensityColor(intensity)
        const xPos = getStackedX(data, i)
        return (
          <g key={`bar-${i}`} className="bar-group">
            {/* Background glow */}
            <rect
              x={padding.left + xPos + i * barSpacing} // Add spacing
              y={0}
              height={columnWidth}
              width={durationScale(duration)}
              fill={intensityColor}
              filter="url(#glass)"
              opacity="0.5"
              rx="2"
            />
            {/* Main bar */}
            <rect
              x={padding.left + xPos + i * barSpacing} // Add spacing
              y={0}
              height={columnWidth}
              width={durationScale(duration)}
              fill={intensityColor}
              filter="url(#enhanced-drop-shadow)"
              rx="2"
              style={{
                transition: 'all 0.3s ease-in-out'
              }}
            />
          </g>
        )
      })}
      {/* Column label */}
      <text
        x={padding.left - 15}
        y={columnWidth / 2}
        textAnchor="end"
        alignmentBaseline="middle"
        fill="#4c6072"
        style={{
          fontSize: '14px',
          fontWeight: '500',
          letterSpacing: '0.5px',
          fontFamily: 'inherit'
        }}
      >
        {label}
      </text>
    </g>
  )

  // Add tick mark generation
  const generateTickMarks = () => {
    const numTicks = 5
    const maxTime = Math.max(maxTotalDuration, maxDuration)
    const tickInterval = Math.ceil(maxTime / numTicks / 30) * 30 // Round to nearest 30 minutes
    const ticks = []

    for (let i = 0; i <= maxTime; i += tickInterval) {
      if (i > maxTime) break
      const xPos = padding.left + durationScale(i)
      ticks.push(
        <g
          key={`tick-${i}`}
          transform={`translate(${xPos}, ${lastBarGroupY + columnWidth + 15})`}
        >
          <line y1="-4" y2="0" stroke="#4c6072" strokeWidth="1" />
          <text
            y="15"
            textAnchor="middle"
            fill="#4c6072"
            style={{ fontSize: '10px', fontFamily: 'inherit' }}
          >
            {i}
          </text>
        </g>
      )
    }
    return ticks
  }

  return (
    <div
      style={{
        background: 'white',
        borderRadius: '5px',
        padding: '16px',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'inherit'
      }}
    >
      <svg
        width="100%"
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="xMidYMid meet"
      >
        {filters}

        {/* Title - updated color */}
        <text
          x={padding.left}
          y={padding.top - 10}
          style={{
            fontSize: '16px',
            fontFamily: 'inherit',
            fontWeight: '500',
            fill: '#4c6072'
          }}
        >
          {title}
        </text>

        {/* Duration axis with tick marks and label */}
        <g
          transform={`translate(${padding.left}, ${lastBarGroupY +
            columnWidth +
            15})`}
        >
          <line
            x1="0"
            y1="0"
            x2={width - padding.left - padding.right + 60}
            y2="0"
            stroke="#4c6072"
            strokeWidth="1"
          />
          <path
            d={`M${width - padding.left - padding.right + 60},0 L${width -
              padding.left -
              padding.right +
              52},-4 L${width - padding.left - padding.right + 52},4 Z`}
            fill="#4c6072"
          />
          <text
            x={(width - padding.left - padding.right) / 2}
            y="30"
            textAnchor="middle"
            fill="#4c6072"
            style={{ fontSize: '12px', fontFamily: 'inherit' }}
          >
            Minutes
          </text>
        </g>

        {generateTickMarks()}

        {/* Legend */}
        <g
          transform={`translate(${width - padding.right + 20}, ${padding.top})`}
        >
          {[
            { color: RecommendationColors.emerald, label: 'Light' },
            { color: RecommendationColors.teal, label: 'Moderate' },
            { color: RecommendationColors.indigo, label: 'Vigorous' }
          ].map((item, i) => (
            <g key={item.label} transform={`translate(0, ${i * 20})`}>
              <rect width="10" height="10" fill={item.color} rx="1" ry="1" />
              <text
                x="15"
                y="8"
                fill="#4c6072"
                style={{ fontSize: '12px', fontFamily: 'inherit' }}
              >
                {item.label}
              </text>
            </g>
          ))}
        </g>

        <BarGroup
          data={data.activity}
          yOffset={padding.top + 10}
          label="Activity"
        />
        <BarGroup
          data={data.resistance}
          yOffset={padding.top + 10 + columnWidth + verticalSpacing}
          label="Resistance"
        />
        <BarGroup
          data={data.aerobic}
          yOffset={padding.top + 10 + 2 * (columnWidth + verticalSpacing)}
          label="Aerobic"
        />
      </svg>
    </div>
  )
}
