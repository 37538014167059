import SigninPage from '../view/signIn'
import ResetView from '../view/signIn/reset'
import ChangePassword from '../view/signIn/change'
import Providers from '../view/providers'
import ClientView from '../view/client'
import ComprehensiveReport from '../view/creport'
// import AssessmentReview from '../view/assessmentReview'
import AdminTool from '../view/adminTool'
import MetricEditor from '../view/adminTool/metrics/editor'
import GoalsTool from '../view/goalsTool'
import BloodTestEditor from '../view/adminTool/bloodTest/editor'
import PrivacyView from '../view/privacy'
import AppVersion from '../view/version'
import Onboarding from '../view/onboarding'
import ClientApp from '../view/app'
import TwoFA from '../view/2fa'
import PreScreening from '../view/onboarding/preScreening/index'
import MembershipSelection from '../view/onboarding/membership/index'
import MembershipAgreement from '../view/onboarding/membership/agreement'
// import ClientProfile from '../view/profile'
import ClientQuestionnaire from '../view/client/questionnaire'
import SendMembershipSelection from '../view/sendMembershipSelection'
// import ShareLink from '../view/share-link'
import IosShareLink from '../view/share-link/ios'
import TOSView from '../view/privacy/tos'
import PPView from '../view/privacy/pp'
import SupportView from '../view/privacy/support'
import FacilityWaiver from '../view/privacy/facility'
import CheckIn from '../view/check-in'
// import FacilitiesManagement from '../view/facilities-management'
import CommonTools from '../view/commonTools'
import MobileView from '../view/mobile'
import Redirection from '../view/redirection'
import WaiverFormRedirection from '../view/redirection/waiverform'
import HealthySignalsBoosterView from '../view/healthy-signals-booster'

let commonRoutes = [
  {
    path: '/sign-in',
    component: SigninPage
  },
  {
    path: '/password-reset/:type',
    component: ResetView
  },
  {
    path: '/ios/share-link/:type/:hash',
    component: IosShareLink
  },
  // {
  //   path: '/share-link/:type/:hash',
  //   component: ShareLink
  // },
  {
    path: '/mobile',
    component: MobileView
  },
  {
    path: '/terms-of-use/:header',
    component: TOSView
  },
  {
    path: '/privacy-policy/:header',
    component: PPView
  },
  {
    path: '/facility-waiver/:header',
    component: FacilityWaiver
  },
  {
    path: '/terms-of-use',
    component: TOSView
  },
  {
    path: '/privacy-policy',
    component: PPView
  },
  {
    path: '/facility-waiver',
    component: FacilityWaiver
  },
  {
    path: '/check-in',
    component: CheckIn
  },
  {
    path: '/support',
    component: SupportView
  },
  {
    path: '/redirect/:type',
    component: Redirection
  },
  {
    path: '/change-password',
    component: ChangePassword
  },
  {
    path: '/membership-agreement',
    component: MembershipAgreement
  }
]

if (process.env.REACT_APP_WORKFLOW === 'develop') {
  commonRoutes = commonRoutes.concat([
    {
      path: '/providers',
      component: Providers
    },
    {
      path: '/privacy',
      component: PrivacyView
    }
  ])
}

const clientRoutes = [
  {
    path: '/',
    component: ClientApp,
    exact: true
  },
  {
    path: '/onboarding',
    component: Onboarding
  },
  {
    path: '/app',
    component: ClientApp
  },
  {
    path: '/2fa',
    component: TwoFA
  },
  {
    path: '/pre-screening',
    component: PreScreening
  },
  {
    path: '/membership-selection',
    component: MembershipSelection
  },
  {
    path: '/redirect-sign-waiver-form',
    component: WaiverFormRedirection
  }
  // {
  //   path: '/profile',
  //   component: ClientProfile
  // },
]

const coachRoutes = [
  { path: '/', exact: true, component: ClientView },
  {
    path: '/client',
    component: ClientView
  },
  { path: '/creport', component: ComprehensiveReport },
  // { path: '/ass-review', component: AssessmentReview },
  { path: '/admin', component: AdminTool },
  { path: '/goals', component: GoalsTool },
  {
    path: '/send-membership-selection/:id',
    component: SendMembershipSelection
  },
  { path: '/common-tools', component: CommonTools },
  { path: '/client-questionnaire/:id', component: ClientQuestionnaire },
  { path: '/blood-test/:id', component: BloodTestEditor },
  { path: '/:path/:id', component: MetricEditor },
  { path: '/healthy-signals-booster', component: HealthySignalsBoosterView }
]

const providerRoutes = [
  { path: '/', exact: true, component: ClientView },
  {
    path: '/client',
    component: ClientView
  }
]

if (process.env.REACT_APP_WORKFLOW !== 'production') {
  coachRoutes.push({ path: '/ios-version', component: AppVersion })
}

export { coachRoutes, commonRoutes, clientRoutes, providerRoutes }
