import React, { useCallback, useMemo, useState } from 'react'
import IogoIcon02 from '../../../asserts/icon/client/recommendation.png'
import RecommendationIcon from '../../../asserts/icon/client/rec.png'
import { ReactComponent as ExerciseIcon } from '../../../asserts/icon/client/ic_exercise.svg'
import { ReactComponent as NutritionIcon } from '../../../asserts/icon/client/ic_nutrition.svg'
import { ReactComponent as RejuvenationIcon } from '../../../asserts/icon/client/ic_rejuvenation.svg'
import { FormFields } from './ReferralEditor/index'
import StepItem from './StepItem'
import HighestPriorityItem from './HighestPriorityItem'
import { find, isArray, some } from 'lodash'
import './Resource.css'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import ReferralItem from './ReferralItem/index'
import ResourceSection from './ResourceSection'
import styled from 'styled-components'
import { SectionStyle } from '../../questionnaire/styles/sectionStyle'

export const RecommendationContainer = styled.section`
  ${SectionStyle}
  padding-top: 106px;
  .edit-btn {
    margin-left: 36px;
    padding: 0 24px;
    height: 40px;
    display: flex;
    align-items: center;
    color: #264382;
    border-color: #264382;
    .icon {
      margin-right: 10px;
    }
  }
  .subtitle {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.3px;
    color: rgb(76, 96, 114);
  }
  /* 1. Banner Section */
  .banner-section {
    padding: 30px 40px;
    height: 202px;
    border-radius: 8px;
    background: url(${IogoIcon02}) no-repeat center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .logos-wrapper {
      display: flex;
      align-items: center;
      img {
        margin-right: 18px;
        height: 32px;
      }
    }
    .title {
      font-size: 32px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: 0.3px;
      color: #ffffff;
    }
  }
  /* 2. Highest Priorities Section */
  .highest-priorities-section {
    padding: 36px 0;
    border-bottom: 1px solid rgba(230, 233, 242, 1);
    .items-section {
      margin: 24px -8px 0;
      display: flex;
      .highest-priority-item {
        margin: 0 8px;
        flex: 1;
        padding: 24px;
        border-radius: 4px;
        border: 1px solid rgba(227, 230, 234, 1);
        background: rgba(250, 250, 250, 1);
        .title-section {
          display: flex;
          justify-content: space-between;
          margin-bottom: 24px;
          padding-bottom: 24px;
          border-bottom: 1px solid rgba(227, 230, 234, 1);
          button {
            padding: 0;
            width: 36px;
            min-width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #eff1f3;
          }
        }
      }
    }
  }
  /* 3. Integrated Recommendation Section */
  .ir-section {
    margin-top: 36px;
    display: flex;
    .editor-section {
      flex: 1;
    }
  }
  /* 4. Exercise, Nutrition, Rejuvenation */
  .step-section {
    margin-top: 60px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(230, 233, 242, 1);
    .subtitle {
      margin-bottom: 24px;
    }
    .step-item {
      &:not(:last-child) {
        margin-bottom: 48px;
      }
      .top-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-section {
          display: flex;
          align-items: center;
          .title {
            margin-left: 16px;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0.3px;
          }
        }
        button {
          padding: 0;
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #eff1f3;
        }
      }
      .editor-section {
        margin-top: 16px;
      }
    }
  }
  /* 5. Referrals Section */
  .referrals-section {
    margin-top: 60px;
    .top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .add-btn {
        padding: 0 24px;
        height: 40px;
        border-color: rgb(38, 67, 130);
        color: rgb(38, 67, 130);
        &:disabled {
          opacity: 0.7;
        }
      }
    }
    .bottom-section {
      margin-top: 24px;
    }
    /* .add-more-section {
      margin-top: 56px;
      padding-top: 14px;
      border-top: 1px solid rgba(215, 215, 215, 1);
      .add-more-btn {
        padding: 0;
        color: rgba(38, 67, 130, 1);
      }
    } */
  }
`

const RecommendationFields = {
    GOALS: 'goals',
    LIFESTYLE: 'lifestyle',
    HEALTHSPAN_DOMAIN: 'healthspan',
    INTEGRATED_RECOMMENDATION: 'integrated',
    EXERCISE: 'exercise',
    NUTRITION: 'nutrition',
    REJUVENATION: 'rejuvenation',
    REFERRALS: 'recommendation_referrals',
    WHAT_TO_EXPECT: 'Whats Next',
    NEXT_STEPS: 'Next Steps'
}

const {
    GOALS,
    LIFESTYLE,
    HEALTHSPAN_DOMAIN,
    INTEGRATED_RECOMMENDATION,
    EXERCISE,
    NUTRITION,
    REJUVENATION,
    WHAT_TO_EXPECT,
    NEXT_STEPS
} = RecommendationFields

const HighestPrioritiesConfigs = [
    {
        field: GOALS,
        title: 'Goals',
        placeholder: 'Please enter goals'
    },
    {
        field: LIFESTYLE,
        title: 'Lifestyle',
        placeholder: 'Please enter lifestyle'
    },
    {
        field: HEALTHSPAN_DOMAIN,
        title: 'Healthspan Domains',
        placeholder: 'Please enter healthspan domains'
    }
]

const StepsCofigs = [
    {
        field: EXERCISE,
        title: 'Exercise',
        placeholder: 'Please enter exercise',
        icon: <ExerciseIcon />,
        color: 'rgb(255, 107, 0)'
    },
    {
        field: NUTRITION,
        title: 'Nutrition',
        placeholder: 'Please enter nutrition',
        icon: <NutritionIcon />,
        color: 'rgb(123, 173, 45)'
    },
    {
        field: REJUVENATION,
        title: 'Sleep',
        placeholder: 'Please enter rejuvenation',
        icon: <RejuvenationIcon />,
        color: 'rgb(92, 0, 209)'
    }
]

const expectationAndNextStepsConfigs = [
    {
        field: WHAT_TO_EXPECT,
        title: 'What to Expect',
        placeholder: 'Please enter what to expect',
        icon: <ExerciseIcon />,
        color: 'rgb(255, 107, 0)'
    },
    {
        field: NEXT_STEPS,
        title: 'Next Steps',
        placeholder: 'Please enter next steps',
        icon: <NutritionIcon />,
        color: 'rgb(123, 173, 45)'
    }
]

const BannerSection = () => {
    return (
        <div className="banner-section">
            <div className="logos-wrapper">
                <img src={RecommendationIcon} alt="logo02" />
            </div>
            <div className="title">Recommendations and Next Steps</div>
        </div>
    )
}

const HighestPrioritiesSection = ({ editabled, data, onChange }) => {
    return (
        <div className="highest-priorities-section">
            <div className="subtitle">Priorities</div>
            <div className="items-section">
                {HighestPrioritiesConfigs.map((item, index) => (
                    <HighestPriorityItem
                        key={index}
                        {...item}
                        editabled={editabled}
                        value={data[item.field]}
                        onChange={onChange}
                    />
                ))}
            </div>
        </div>
    )
}

// 4. Exercise, Nutrition, Rejuvenation
const StepsSection = ({ editabled, data, onChange }) => {
    return (
        <div className="step-section">
            <div className="subtitle">Recommendations</div>
            {StepsCofigs.map((item, index) => (
                <StepItem
                    key={index}
                    {...item}
                    editabled={editabled}
                    value={data[item.field]}
                    onChange={onChange}
                />
            ))}
        </div>
    )
}

const ExpectationAndNextSteps = ({ editabled, data, onChange }) => {
    return (
        <div className="step-section">
            <div className="subtitle">Expectation And Next Steps</div>
            {expectationAndNextStepsConfigs.map((item, index) => (
                <StepItem
                    key={index}
                    {...item}
                    editabled={editabled}
                    value={data[item.field]}
                    onChange={onChange}
                />
            ))}
        </div>
    )
}

// // 5. Referrals Section
const ReferralsSection = ({ editabled, data, onSave, updateArDetail }) => {
    const [editedData, setEditedData] = useState(
        data.map((item) => ({ ...item, editing: false }))
    )

    const isEditing = useMemo(() => some(editedData, { editing: true }), [
        editedData
    ])

    const handleChange = useCallback(
        (index, newReferral) => {
            const _data = [...editedData]
            _data[index] = newReferral
            setEditedData(_data)
        },
        [editedData]
    )

    const handleAddMore = useCallback(() => {
        const newReferral = { editing: true }
        for (let key in FormFields) {
            newReferral[FormFields[key]] = ''
        }
        setEditedData((data) => [...data, newReferral])
    }, [])

    const handleCancel = useCallback(
        (index) => {
            const _editedData = [...editedData]
            if (_editedData[index].id) {
                _editedData[index] = { ...data[index], editing: false }
            } else {
                _editedData.splice(index, 1)
            }
            setEditedData(_editedData)
        },
        [data, editedData]
    )

    const handleUpdateWrapperImage = useCallback(
        (index, imageObj) => {
            const _data = [...data]
            _data[index] = { ..._data[index], ...imageObj }
            updateArDetail(_data)
        },
        [data, updateArDetail]
    )

    return (
        <div className="referrals-section">
            <div className="top-section">
                <div className="subtitle">Referrals</div>
                {editabled && (
                    <Button
                        className="add-btn"
                        disabled={isEditing}
                        onClick={handleAddMore}
                    >
                        <PlusOutlined />
                        ADD
                    </Button>
                )}
            </div>
            <div className="bottom-section">
                {editedData.map((item, index) => (
                    <ReferralItem
                        key={index}
                        index={index}
                        editabled={editabled}
                        data={item}
                        onChange={handleChange}
                        onSave={onSave}
                        onCancel={handleCancel}
                        updateWrapperImage={handleUpdateWrapperImage}
                    />
                ))}
            </div>
            {/* <div className='add-more-section'>
        <Button className='add-more-btn' type='text' onClick={handleAddMore}>
          + ADD MORE
        </Button>
      </div> */}
        </div>
    )
}

export default function RecommendationSection({
    editabled,
    arDetail,
    onUpdateNotes,
    onUpdateReferral,
    updateArDetail,
    view,
    hideSection,
    hideSectionHandle,
    updateEditingCount
}) {
    const { notes, referrals } = arDetail
    const getData = useCallback(
        (param) => {
            const getValue = (pillar) => {
                const item = notes.find((note) => note.pillar === pillar)
                return item ? item.memo : ''
            }
            if (Array.isArray(param)) {
                const _data = {}
                param.forEach((pillar) => {
                    _data[pillar] = getValue(pillar)
                })
                return _data
            }
            return getValue(param)
        },
        [notes]
    )

    const handleUpdateArDetail = useCallback(
        (referrals) => {
            updateArDetail({ ...arDetail, referrals })
        },
        [arDetail, updateArDetail]
    )

    return (
        <RecommendationContainer>
            <BannerSection />
            <HighestPrioritiesSection
                editabled={editabled}
                data={getData(HighestPrioritiesConfigs.map(({ field }) => field))}
                onChange={onUpdateNotes}
            />
            <StepsSection
                editabled={editabled}
                data={getData(StepsCofigs.map(({ field }) => field))}
                onChange={onUpdateNotes}
            />
            <ExpectationAndNextSteps
                editabled={editabled}
                data={getData(expectationAndNextStepsConfigs.map(({ field }) => field))}
                onChange={onUpdateNotes}
            />
            <ReferralsSection
                editabled={editabled}
                data={referrals}
                onSave={onUpdateReferral}
                updateArDetail={handleUpdateArDetail}
            />
            <ResourceSection
                view={view}
                hideSection={hideSection}
                hideSectionHandle={hideSectionHandle}
            />
        </RecommendationContainer>
    )
}
