import mlAxios from './axios_ml.js'

/**
 * Call the ML API to create a new recommendation for "personId" on "date"
 */
export const createAdaptiveRecommendation = (personId, date) => {
  return mlAxios.get(`/api/ml/create_recommendation/${personId}/${date}`)
}

/**
 * Retrieve an existing recommendation for "personId" on "date"
 */
export const getAdaptiveRecommendation = (personId, date) => {
  return mlAxios.get(`/api/ml/get_recommendation/${personId}/${date}`)
}

/**
 * Update a specific recommendation by ID
 * @param {string} personId - The person's ID
 * @param {string} date - The date of the recommendation
 * @param {string} recommendationId - The recommendation ID
 * @param {Object} data - The updated recommendation data
 */
export const updateAdaptiveRecommendation = (personId, date, recommendationId, data) => {
  return mlAxios.put(`/api/ml/update_recommendation/${personId}/${date}/${recommendationId}/`, data)
}

/**
 * Delete a specific recommendation by ID
 * @param {string} personId - The person's ID
 * @param {string} date - The date of the recommendation
 * @param {string} recommendationId - The recommendation ID to delete
 */
export const deleteAdaptiveRecommendation = (personId, date, recommendationId) => {
  return mlAxios.delete(`/api/ml/delete_recommendation/${personId}/${date}/${recommendationId}/`)
}

/**
 * Publish or unpublish recommendations for a specific date
 * @param {string} personId - The person's ID
 * @param {string} date - The date of the recommendations
 * @param {Array} recommendations - The recommendations to modify
 * @param {boolean} publish - Whether to publish (true) or unpublish (false)
 */
export const publishAdaptiveRecommendations = (personId, date, recommendations, publish = true) => {
  // Set published status based on the publish parameter
  const updatedRecommendations = recommendations.map(rec => ({
    ...rec,
    published: publish
  }))

  return mlAxios.post(`/api/ml/publish_recommendations/${personId}/${date}/`, {
    combined_recommendation: updatedRecommendations
  })
}

/**
 * Regenerate a specific recommendation
 * @param {string} personId - The person's ID
 * @param {string} weekDate - The date of the recommendation
 * @param {string} recommendationId - The recommendation ID
 */
export const regenerateAdaptiveRecommendation = async (personId, weekDate, recommendationId) => {
  try {
    // Update the URL to match the backend endpoint
    return mlAxios.get(
      `/api/ml/regenerate_recommendation/${personId}/${weekDate}/${recommendationId}/`
    )
  } catch (error) {
    console.error('Error regenerating adaptive recommendation:', error)
    throw error
  }
}
