import React from 'react'
import { RangeColors } from '../../utils'

export const ExerciseGauge = ({
  value,
  label,
  subtitle,
  color,
  min = 0,
  max = 100
}) => {
  const isTrainingLoad = label === 'Training Load'
  const isRecoveryScore = label === 'Rest Index'

  // Define correct ranges and major tick values for each type
  const getGaugeConfig = () => {
    if (isTrainingLoad) {
      return {
        min: 100,
        max: 400,
        majorTicks: [160, 250, 340]
      }
    }
    if (isRecoveryScore) {
      return {
        min: 0,
        max: 0.2,
        majorTicks: [0.05, 0.1, 0.15]
      }
    }
    return {
      min: 0,
      max: 100,
      majorTicks: [25, 50, 75]
    }
  }

  const config = getGaugeConfig()
  const gaugeMin = config.min
  const gaugeMax = config.max
  const majorTickValues = config.majorTicks

  const radius = isTrainingLoad ? 120 : 80
  const strokeWidth = isTrainingLoad ? 15 : 10

  // Adjust angles to ensure proper positioning
  const startAngle = -210
  const endAngle = 30
  const angleRange = Math.abs(endAngle - startAngle)

  // Helper function to check if a value is a major tick
  const isMajorTickValue = (value) => {
    return majorTickValues.some((tick) => Math.abs(value - tick) < 0.01)
  }

  // Helper function to get the angle for a specific value
  const getAngleForValue = (value) => {
    const normalizedValue = (value - gaugeMin) / (gaugeMax - gaugeMin)
    return startAngle + angleRange * normalizedValue
  }

  const numTicks = 40
  const numInnerTicks = 80
  const tickLength = isTrainingLoad ? 15 : 10
  const innerTickLength = tickLength / 3
  const emphasizedInnerTickLength = innerTickLength * 2
  const ticks = []
  const innerTicks = []

  // Helper function to interpolate between two colors
  const interpolateColor = (color1, color2, factor) => {
    const r1 = parseInt(color1.slice(1, 3), 16)
    const g1 = parseInt(color1.slice(3, 5), 16)
    const b1 = parseInt(color1.slice(5, 7), 16)

    const r2 = parseInt(color2.slice(1, 3), 16)
    const g2 = parseInt(color2.slice(3, 5), 16)
    const b2 = parseInt(color2.slice(5, 7), 16)

    const r = Math.round(r1 + (r2 - r1) * factor)
    const g = Math.round(g1 + (g2 - g1) * factor)
    const b = Math.round(b1 + (b2 - b1) * factor)

    return `rgb(${r},${g},${b})`
  }

  const getTickColor = (tickValue) => {
    // Define color stops
    const colorStops = [
      { value: min, color: '#B2C40E' }, // New color
      { value: (min + max) / 2, color: '#B2C40E' }, // New color
      { value: max, color: '#B2C40E' } // New color
    ]

    // Find the appropriate color segment
    for (let i = 0; i < colorStops.length - 1; i++) {
      if (
        tickValue >= colorStops[i].value &&
        tickValue <= colorStops[i + 1].value
      ) {
        const factor =
          (tickValue - colorStops[i].value) /
          (colorStops[i + 1].value - colorStops[i].value)
        return interpolateColor(
          colorStops[i].color,
          colorStops[i + 1].color,
          factor
        )
      }
    }
    return colorStops[0].color // Default to first color
  }

  // Generate major ticks first to ensure they're always included
  majorTickValues.forEach((tickValue) => {
    const angle = getAngleForValue(tickValue) * (Math.PI / 180)
    const outerRadius = radius - strokeWidth / 2
    const innerRadius = outerRadius - tickLength
    const labelRadius = innerRadius - 30

    const x1 = radius + outerRadius * Math.cos(angle)
    const y1 = radius + outerRadius * Math.sin(angle)
    const x2 = radius + innerRadius * Math.cos(angle)
    const y2 = radius + innerRadius * Math.sin(angle)

    ticks.push({
      line: { x1, y1, x2, y2 },
      isColored: tickValue <= value,
      color: getTickColor(tickValue),
      label: {
        x: radius + labelRadius * Math.cos(angle),
        y: radius + labelRadius * Math.sin(angle),
        value: isRecoveryScore
          ? tickValue.toString().replace('0.', '.')
          : Math.round(tickValue)
      },
      isMajorTick: true
    })
  })

  // Generate regular ticks
  for (let i = 0; i <= numTicks; i++) {
    const angle = (startAngle + angleRange * (i / numTicks)) * (Math.PI / 180)
    const tickValue = gaugeMin + (gaugeMax - gaugeMin) * (i / numTicks)

    // Skip if this is already a major tick (updated precision handling)
    if (majorTickValues.some((tick) => Math.abs(tickValue - tick) < 0.0001))
      continue

    const outerRadius = radius - strokeWidth / 2
    const innerRadius = outerRadius - tickLength

    const x1 = radius + outerRadius * Math.cos(angle)
    const y1 = radius + outerRadius * Math.sin(angle)
    const x2 = radius + innerRadius * Math.cos(angle)
    const y2 = radius + innerRadius * Math.sin(angle)

    ticks.push({
      line: { x1, y1, x2, y2 },
      isColored: tickValue <= value,
      color: getTickColor(tickValue),
      isMajorTick: false
    })
  }

  // Generate inner ticks (updated precision handling)
  for (let i = 0; i <= numInnerTicks; i++) {
    const angle =
      (startAngle + angleRange * (i / numInnerTicks)) * (Math.PI / 180)
    const tickValue = gaugeMin + (gaugeMax - gaugeMin) * (i / numInnerTicks)
    const isMajorTick = majorTickValues.some(
      (tick) => Math.abs(tickValue - tick) < 0.0001
    )

    const outerRadius = radius - strokeWidth / 2 - tickLength - 10
    const innerRadius =
      outerRadius - (isMajorTick ? emphasizedInnerTickLength : innerTickLength)

    const x1 = radius + outerRadius * Math.cos(angle)
    const y1 = radius + outerRadius * Math.sin(angle)
    const x2 = radius + innerRadius * Math.cos(angle)
    const y2 = radius + innerRadius * Math.sin(angle)

    innerTicks.push({
      x1,
      y1,
      x2,
      y2,
      isMajorTick
    })
  }

  return (
    <div
      style={{
        background: 'white', // Changed to white
        borderRadius: '5px', // Match standard border radius
        padding: '16px',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'inherit'
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${radius * 2} ${radius * 2}`}
      >
        {/* Update colors for gauge elements */}
        <defs>
          <filter id="glow">
            <feGaussianBlur stdDeviation="2" result="coloredBlur" />
            <feMerge>
              <feMergeNode in="coloredBlur" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>

        {/* Inner ticks */}
        {innerTicks.map((tick, i) => (
          <line
            key={`inner-tick-${i}`}
            x1={tick.x1}
            y1={tick.y1}
            x2={tick.x2}
            y2={tick.y2}
            stroke="#666" // Changed to lighter grey
            strokeWidth={
              tick.isMajorTick
                ? isTrainingLoad
                  ? 1.5
                  : 1
                : isTrainingLoad
                ? 1
                : 0.5
            }
            strokeLinecap="round"
          />
        ))}

        {/* Outer ticks */}
        {ticks.map((tick, i) => (
          <g key={`outer-tick-${i}`}>
            <line
              {...tick.line}
              stroke={tick.isColored ? RangeColors.green : '#e6e9f2'}
              strokeWidth={2}
            />
            {tick.label && (
              <text
                x={tick.label.x}
                y={tick.label.y}
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize={isTrainingLoad ? '14' : '12'}
                fill="#4c6072"
              >
                {tick.label.value}
              </text>
            )}
          </g>
        ))}

        {/* Value text */}
        <text
          x={radius}
          y={radius}
          textAnchor="middle"
          dominantBaseline="middle"
          fill={RangeColors.green} // Use standard green
          style={{
            fontSize: '24px',
            fontWeight: '500',
            fontFamily: 'inherit'
          }}
        >
          {isRecoveryScore
            ? value.toFixed(2).replace('0.', '.')
            : Math.round(value)}
        </text>

        {/* Labels */}
        <text
          x={radius}
          y={radius * 1.7}
          textAnchor="middle"
          fill="#4c6072" // Standard text color
          style={{
            fontSize: '14px',
            fontWeight: '500',
            fontFamily: 'inherit'
          }}
        >
          {label}
        </text>
        <text
          x={radius}
          y={radius * 1.9}
          textAnchor="middle"
          fill="#4c6072" // Standard text color
          style={{
            fontSize: '12px',
            fontWeight: '400',
            fontFamily: 'inherit'
          }}
        >
          {subtitle}
        </text>
      </svg>
    </div>
  )
}
