import React, { useState } from 'react'
import { DatePicker } from 'antd'
import { CalendarOutlined } from '@ant-design/icons'
import moment from 'moment'
import styled from 'styled-components'

const DateRangeContainer = styled.div`
  display: inline-block;
  position: relative;
`

const DateSelector = styled.div`
  font-family: Gilroy;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
  color: #52606c;
  border: 1px solid #006400;  /* Changed to match the Adaptive tag green */
  border-radius: 12px;
  padding: 8px 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  cursor: pointer;
  min-width: 140px;
  margin-left: 0;
  margin-right: 12px;
  padding-left: 32px;
  position: relative;

  &:hover {
    border-color: #004d00;  /* Slightly darker green on hover */
  }
  
  .calendar-icon {
    position: absolute;
    left: 10px;
    font-size: 14px;
    color: #006400;  /* Match icon to border color */
  }
`

// Custom styles for the DatePicker to make it match the UI
const StyledDatePicker = styled(DatePicker)`
  &.ant-picker {
    border-radius: 12px;
    font-family: Gilroy;
    
    .ant-picker-input > input {
      font-size: 1em;
      color: #52606c;
    }
    
    .ant-picker-suffix {
      color: #52606c;
    }
    
    &:hover, &:focus, &.ant-picker-focused {
      border-color: #b7b7b7;
    }
  }
`

const DateRangeFine = ({ currentDate, weekStartDate, weekEndDate, onDateSelect }) => {
  // Set default dates if not provided
  const defaultStartDate = moment().subtract(7, 'days').startOf('day')
  const defaultEndDate = moment().endOf('day')
  
  // Use provided dates if valid, otherwise use defaults
  const actualStartDate = weekStartDate && moment(weekStartDate).isValid() 
    ? moment(weekStartDate) 
    : defaultStartDate
    
  const actualEndDate = weekEndDate && moment(weekEndDate).isValid() 
    ? moment(weekEndDate) 
    : defaultEndDate
  
  // Default to the provided current date, or today if none is provided
  const [selectedDate, setSelectedDate] = useState(
    currentDate && moment(currentDate).isValid() 
      ? moment(currentDate) 
      : moment()
  )
  
  // State to control DatePicker open state
  const [open, setOpen] = useState(false)
  
  // Limit date selection to the current week AND not beyond today
  const disabledDate = (current) => {
    if (!current) return false
    
    const start = actualStartDate.startOf('day')
    const end = actualEndDate.endOf('day')
    const today = moment().endOf('day')
    
    // Disable if date is outside the current week OR if it's in the future
    return current < start || current > end || current > today
  }
  
  const handleDateChange = (date) => {
    if (!date) return
    
    setSelectedDate(date)
    setOpen(false)
    
    if (onDateSelect) {
      onDateSelect(date.format('YYYY-MM-DD'))
    }
  }
  
  // Format displayed date as "Sun, Jan 15"
  const displayDate = selectedDate && selectedDate.format('ddd, MMM D') || ''
  return (
    <DateRangeContainer>
      <DateSelector onClick={() => setOpen(true)}>
        <CalendarOutlined className="calendar-icon" />
        {displayDate || 'Select date'}
      </DateSelector>
      
      <StyledDatePicker 
        value={selectedDate}
        onChange={handleDateChange}
        disabledDate={disabledDate}
        format="YYYY-MM-DD"
        open={open}
        onOpenChange={setOpen}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        style={{ position: 'absolute', opacity: 0, width: 0, height: 0, left: 0 }} // Added left: 0
        dropdownClassName="custom-date-dropdown"
        popupStyle={{ 
          borderRadius: '8px',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
          left: '0 !important' // Force dropdown to align with left edge
        }}
      />
    </DateRangeContainer>
  )
}

export default DateRangeFine
