import React, {useState, useEffect} from 'react'
import _, { capitalize } from 'lodash'
import { Row, Col, Divider } from 'antd'
import styled from 'styled-components'
import { RangeColors } from '../../../utils/constant'
import { VictoryChart, VictoryPolarAxis, VictoryScatter } from 'victory'
import { handleMetricRanges } from '../wrapper'
import HealthCard from '../../healthScore/card'
import Information from '../../UI/extraInformation'
import UploadImage from '../comp/uploadImage'

import QuietStandImage from '../../../asserts/images/physical-assessment/ic-quiet-stand.png'
import QuietStandImageMale from '../../../asserts/images/physical-assessment/QuietStandMale.svg'
import SingleLegStanceImage from '../../../asserts/images/physical-assessment/ic-single-let-stance.svg'
import SingleLegStanceImageFemale from '../../../asserts/images/physical-assessment/SingleLegStanceFemale.svg'
import Left from '../../../asserts/images/placeholder/balance-l.svg'
import LeftFemale from '../../../asserts/images/placeholder/Balance-l-female.svg'
import Middle from '../../../asserts/images/placeholder/balance-m.svg'
import MiddleFemale from '../../../asserts/images/placeholder/Balance-m-female.svg'
import Right from '../../../asserts/images/placeholder/balance-r.svg'
import RightFemale from '../../../asserts/images/placeholder/Balance-r-female.svg'
import SingleLegSquatMale from '../../../asserts/images/physical-assessment/SingleLegSquatMale.svg'
import SingleLegSquatFemale from '../../../asserts/images/physical-assessment/SingleLegSquatFemale.svg'
// import SingleLegSquatL from '../../../asserts/images/placeholder/balance-single-leg-squat-l.svg'
// import SingleLegSquatR from '../../../asserts/images/placeholder/balance-single-leg-squat-r.svg'
import Illustration from '../../../asserts/images/placeholder/illustration.svg'
import HideWrap from '../comp/hideWrap'
import classNames from 'classnames'
import { MovementMetricCard } from './movementQuality'
import ConditionalHideWrap from './ConditionalHidewrap'
import LowlevelMetrics from './LowleveMetrics'
import { formatDisplayName, getRangeByAgeAndGender } from './LowLevelMetricsHelpers'
import { getRangeAgeScopes } from '../../../api'
import { getMetricById } from '../../../api'
import { IGNORED_METRICS_FOR_LIFESTYLE_RECOMMENDATIONS } from '../../../utils/constant'

const BalanceContainer = styled.div`
  color: #4c6072;
  margin-bottom: 90px;
  .range-list {
    margin-bottom: 22px;
    display: flex;
    justify-content: flex-end;
    list-style: none;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: rgba(50, 58, 70, 0.5);
    &.center {
      display: block;
      text-align: center;
      padding: 0;
      margin-left: -24px;
      margin-top: 24px;
      li {
        display: inline-block;
      }
    }
    li {
      padding-left: 24px;
    }
    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
  }
  .ant-col-12 > div {
    background: rgba(38, 67, 130, 0.05);
    border-radius: 8px;
    padding: 32px;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    text-transform: capitalize;
    letter-spacing: 0.3px;
    font-size: 24px;
    .score {
      color: #0d2350;
      font-family: 'Gilroy-Bold';
      text-align: right;
    }
    .level {
      font-family: 'Gilroy-Bold';
      font-size: 16px;
    }
    .previous {
      font-family: 'Gilroy';
      font-size: 15px;
      color: #4c6072;
    }
  }
  .balance-layout {
    margin-top: 50px;
    font-size: 15px;
    text-align: center;
    .name {
      margin: 30px 0;
    }
    .previous-value-wrapper {
      position: absolute;
      bottom: 0px;
      left: 138px;
    }  
    .image {
      position: relative;
      .values {
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 100%;
        top: 105px;
        padding: 0 60px;
        div {
          background: #f4f6f9;
          width: 48px;
          height: 48px;
          border-radius: 100%;
          line-height: 48px;
          font-size: 18px;
          color: #0d2350;
          font-family: 'Gilroy-Bold';
        }
      }
    }
  }
  .chart-container {
    position: relative;
    padding-bottom: 160px;
    & > div {
      position: absolute;
    }
    .label {
      top: 20%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
      text-transform: uppercase;
      .value {
        font-size: 24px;
      }
    }
  }
  .single-leg-stance-status-list {
    padding-left: 22.57%;
    color: #4c6072;

    & > div {
      border-bottom: 1px solid #e6e9f2;
      height: 64px;
      &:last-child {
        border-bottom: none;
      }
    }
    .status {
      padding: 5px 12px;
      border-radius: 6px;
      color: white;
      font-weight: 600;
    }
    .optimal {
      background-color: green;
    }
  }
`
const SingleLegContainer = styled.div`
  margin-bottom: 42px;
  margin-left: -75px;
  .detail-container {
    display: flex;
    align-items: center;
    .image-container {
      display: flex;
      justify-content: space-evenly;
      flex-grow: 1;
      .stance {
        margin-left: -22px;
      }
      .squat {
        margin-top: 60px;
      }
    }
    .status-container {
      flex-grow: 1;
      font-size: 15px;
      color: #4c6072;
      .title {
        border-bottom: 1px solid #e3e6ea;
        padding-bottom: 24px;
        line-height: 21px;
        text-align: right;
        div:first-child {
          text-align: left;
        }
        .first-column-side {
          font-size: 14px;
          color: #4c6072;
          font-weight: 500;
        }
        .second-column {
          font-size: 14px;
        }
      }
      .status-row {
        padding-bottom: 18px;
        padding-top: 18px;
        border-bottom: 1px solid #e3e6ea;
        text-align: right;
        font-weight: 400;
        display: flex;
        align-items: flex-start;
        div:first-child {
          text-align: left;
        }
        .tag {
          width: 83px;
          border-radius: 6px;
          display: inline-block;
          background-color: #c9d0e0;
          color: white;
          text-align: center;
          font-weight: 600;
          line-height: 130%;
          padding-top: 5px;
          padding-bottom: 3px;
        }
        .previous {
          font-size: 12px;
          color: #4c6072;
          margin-top: 5px;
        }
      }
    }
  }
`

const SingleLegStanceContainer = styled.div`
  padding-left: 22.57%;
  color: #4c6072;

  & > div {
    border-bottom: 1px solid #e6e9f2;
    height: 64px;
    &:last-child {
      border-bottom: none;
    }
  }
  .status {
    padding: 5px 12px;
    border-radius: 6px;
    color: white;
    font-weight: 600;
    margin-left: 44px;
  }
  .optimal {
    background-color: green;
  }
`

const { red, yellow, green } = RangeColors
const Tips = [
  {
    name: 'Optimal',
    color: green
  },
  {
    name: 'Concern',
    color: yellow
  },
  {
    name: 'At risk',
    color: red
  }
]

export default function Balance(props) {
  const {
    ageScopes,
    person,
    hideSectionHandle,
    hideSection,
    dynamicData: { movementBalance, ui_version },
    arDetail: { notes, status, detail },
    save,
    onScoreChangeHandle,
    cancelEdit,
    handleCommendSave,
    updateEditingCount,
    dataList,
    compareData,
    view,
    loginRole,
    handleSave
  } = props
  const {
    Composit_Reach_Distance_Left,
    Composit_Reach_Distance_Right,
    anterior_reach_left,
    anterior_reach_right,
    postero_medial_reach_left,
    postero_medial_reach_right,
    postero_lateral_reach_left,
    postero_lateral_reach_right,
    anterior_reach_diff,
    postero_medial_reach_diff,
    postero_lateral_reach_diff,
    eyes_open_lt_10_left,
    eyes_closed_lt_10_left,
    eyes_open_lt_10_right,
    eyes_closed_lt_10_right,
    loss_of_balance_left,
    knee_collapses_inward_left,
    loss_of_balance_right,
    knee_collapses_inward_right,
    mean_force_asymmetry,
    quiet_stand_total_excursion,
    quiet_stand_total_excursion_asymmetry,
    single_leg_stance_asymmetry,
    single_leg_stance_total_excursion_left,
    single_leg_stance_total_excursion_right
  } = movementBalance

  const statusComp = (obj, iskneeflexion = false) => {
    const backgroundColor = RangeColors[obj.color] || '#C9D0E0'
    return (
      <span
        className="tag"
        style={{
          backgroundColor: backgroundColor,
          marginLeft: '20px',
          marginRight: iskneeflexion ? '34px' : '68px'
        }}
      >
        {obj.level || 'N/A'}
      </span>
    )
  }
  const [valueResults, setValueResults] = useState([])

  useEffect(() => {
    fetchBodyCompMetricsDetails()
  }, [])

  const fetchBodyCompMetricsDetails = async () => {
    const balanceMetrics = props.dynamicData.movementBalance
    const metricKeys = Object.keys(balanceMetrics)
    const ageScopes = (await getRangeAgeScopes()).age_scope

    const biomarkerDetailsPromises = metricKeys.map(async (metricKey) => {
      const metric =
        props &&
        props.dynamicData &&
        props.dynamicData.movementBalance[metricKey]

      if (!metric) {
        return null
      }

      if (['orange', 'red', 'yellow'].includes(metric.color) && !IGNORED_METRICS_FOR_LIFESTYLE_RECOMMENDATIONS.includes(metricKey)) {
        const conventionalRanges = metric.ranges
          ? getRangeByAgeAndGender(metric.ranges, person, ageScopes)
          : []

        if (metric && metric.metricId) {
          const biomarkerDetail = await getMetricById(
            metric.metricId,
            null,
            'physical_assessments'
          )

          const introduction =
            biomarkerDetail && biomarkerDetail.physical_assessment.introduction
              ? biomarkerDetail.physical_assessment.introduction
              : 'No introduction available'

          return {
            displayName: formatDisplayName(metricKey),
            value: metric.value,
            color: metric.color,
            level: metric.level,
            conventionalRanges,
            biomarkerDetail,
            introduction,
            sectionName: 'Balance'
          }
        }
      }

      return null
    })

    const results = (await Promise.all(biomarkerDetailsPromises)).filter(
      (result) => result !== null
    )
    setValueResults(results)
  }

  const data = dataList.find((item) => item.name === 'Balance') || {}
  const compare = compareData.find((item) => item.name === 'Balance') || {}

  const balance = notes.find((item) => item.pillar === 'balance')
  const gender = person && person.profile && person.profile.gender

  const metrics = [
    {
      name: 'Quiet Stand',
      image: gender === 'male' ? QuietStandImageMale : QuietStandImage,
      imageStyle: {
        width: gender === 'male' ? 100 : 140
      },
      bars: [
        {
          ...mean_force_asymmetry,
          name: 'Mean Force Asymmetry'
        },
        {
          ...quiet_stand_total_excursion,
          name: 'Total Excursion'
        }
      ],
      asymmetry: {
        ...quiet_stand_total_excursion_asymmetry
      },
      asymmetryText: 'Total Excursion Asymmetry'
    },
    {
      name: 'Single Leg Stance',
      image: gender === 'male' ? SingleLegStanceImage : SingleLegStanceImageFemale,
      imageStyle: {
        width: 150
      },
      bars: [
        {
          ...single_leg_stance_total_excursion_right,
          name: 'Total Excursion Right'
        },
        {
          ...single_leg_stance_total_excursion_left,
          name: 'Total Excursion Left'
        }
      ],
      asymmetry: {
        ...single_leg_stance_asymmetry
      },
      asymmetryText: 'Total Excursion Asymmetry'
    }
  ]

  // get value by ui_version
  const _Composit_Reach_Distance_Left = ui_version
    ? Composit_Reach_Distance_Left.value
    : Composit_Reach_Distance_Left
  const _Composit_Reach_Distance_Right = ui_version
    ? Composit_Reach_Distance_Right.value
    : Composit_Reach_Distance_Right
  return (
    <ConditionalHideWrap
      name="Balance"
      isSection={true}
      syncStatus={hideSectionHandle}
      status={hideSection}
      view={view}
      condition={
        view === 'Draft' || (view === 'Preview' && !hideSection.includes('Balance'))
      }
    >
      <section>
        <h1 className="section-title">Balance</h1>
        <ConditionalHideWrap
          name="Balance - summary"
          title="summary"
          syncStatus={hideSectionHandle}
          status={hideSection}
          view={view}
          condition={
            view === 'Draft' || (view === 'Preview' && !hideSection.includes('Balance - summary'))
          }
        >
          <div className="sub-title">summary</div>
          <HealthCard
            {...data}
            compare={compare}
            save={save}
            loginRole={loginRole}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            onChangeHandle={onScoreChangeHandle}
            disEdit={status === 1 || view === 'Preview'}
            cancel={cancelEdit}
          />
          <Information
            title="COMMENT/NOTE"
            disMarkDone={true}
            initialValue={balance ? balance.memo : ''}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            updateCommend={(value, status) =>
              handleCommendSave(value, 'balance', status)
            }
            updateEditingCount={updateEditingCount}
          />
          <Divider />
        </ConditionalHideWrap>
        <ConditionalHideWrap
          name="Balance - Movement Efficiency Y Balance"
          title="Movement Efficiency Y Balance"
          syncStatus={hideSectionHandle}
          status={hideSection}
          view={view}
          condition={
            view === 'Draft' || (view === 'Preview' &&!hideSection.includes('Balance - Movement Efficiency Y Balance'))
          }
        >
          <div className="sub-title">Movement Efficiency Y Balance</div>
          <BalanceContainer>
            <Row gutter={26}>
              <Col span={12}>
                <div>
                  <div>
                    Composite Score Left
                    {ui_version && (
                      <div
                        className="level"
                        style={{
                          color: RangeColors[Composit_Reach_Distance_Left.color]
                        }}
                      >
                        {Composit_Reach_Distance_Left.level}
                      </div>
                    )}
                  </div>
                  <div
                    className="score"
                    style={{
                      color: ui_version
                        ? RangeColors[Composit_Reach_Distance_Left.color]
                        : 'inherit'
                    }}
                  >
                    {_.isNumber(_Composit_Reach_Distance_Left)
                      ? _Composit_Reach_Distance_Left
                      : '--'}

                    {ui_version && Composit_Reach_Distance_Left.previous && (
                      <div className="previous">
                        Previous Score – {Composit_Reach_Distance_Left.previous}
                      </div>
                    )}
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <div>
                    Composite Score Right
                    {ui_version && (
                      <div
                        className="level"
                        style={{
                          color:
                            RangeColors[Composit_Reach_Distance_Right.color]
                        }}
                      >
                        {Composit_Reach_Distance_Right.level}
                      </div>
                    )}
                  </div>
                  <div
                    className="score"
                    style={{
                      color: ui_version
                        ? RangeColors[Composit_Reach_Distance_Right.color]
                        : 'inherit'
                    }}
                  >
                    {_.isNumber(_Composit_Reach_Distance_Right)
                      ? _Composit_Reach_Distance_Right
                      : '--'}

                    {ui_version && Composit_Reach_Distance_Right.previous && (
                      <div className="previous">
                        Previous Score –{' '}
                        {Composit_Reach_Distance_Right.previous}
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>

            <Row type="flex" justify="space-between" className="balance-layout">
              <Col span={8}>
                <div className="image">
                  {gender === 'male' ? (
                    <img src={Left} alt="placeholder" />
                  ):(
                      <img src={LeftFemale} alt="placeholder" />
                  )}
                  
                  <div className="values">
                    <div>
                      {_.isNumber(anterior_reach_right)
                        ? anterior_reach_right
                        : '--'}
                    </div>
                    <div>
                      {_.isNumber(anterior_reach_left)
                        ? anterior_reach_left
                        : '--'}
                    </div>
                  </div>
                </div>
                <div className="name">Anterior Reach Difference (%)
                  <div className='previous-value-wrapper' >
                    Previous -{' '}
                    {_.isNumber(anterior_reach_diff.previous) &&
                      anterior_reach_diff.previous + anterior_reach_diff.unit}
                  </div>
                </div>
                <Chart
                  metric={anterior_reach_diff}
                  ageScopes={ageScopes}
                  person={person}
                />
              </Col>
              <Col span={8}>
                <div className="image">
                  {gender === 'male' ? (
                    <img src={Middle} alt="placeholder" />
                  ) : (
                    <img src={MiddleFemale} alt="placeholder" />
                  )}                  
                  <div className="values">
                    <div>
                      {_.isNumber(postero_medial_reach_right)
                        ? postero_medial_reach_right
                        : '--'}
                    </div>
                    <div>
                      {_.isNumber(postero_medial_reach_left)
                        ? postero_medial_reach_left
                        : '--'}
                    </div>
                  </div>
                </div>
                <div className="name">
                  Posterior Medial Reach Difference (%)
                  <div className='previous-value-wrapper'>
                    Previous -{' '}
                    {_.isNumber(postero_medial_reach_diff.previous) &&
                      postero_medial_reach_diff.previous +
                      postero_medial_reach_diff.unit}
                  </div>
                </div>
                <Chart
                  metric={postero_medial_reach_diff}
                  ageScopes={ageScopes}
                  person={person}
                />

              </Col>
              <Col span={8}>
                <div className="image">
                  {gender === 'male' ? (
                    <img src={Right} alt="placeholder" />
                  ) : (
                    <img src={RightFemale} alt="placeholder" />
                  )} 
                    <div className="values">
                    <div>
                      {_.isNumber(postero_lateral_reach_right)
                        ? postero_lateral_reach_right
                        : '--'}
                    </div>
                    <div>
                      {_.isNumber(postero_lateral_reach_left)
                        ? postero_lateral_reach_left
                        : '--'}
                    </div>
                  </div>
                </div>
                <div className="name">
                  Posterior Lateral Reach Difference (%)
                  <div className='previous-value-wrapper'>
                    Previous -{' '}
                    {_.isNumber(postero_lateral_reach_diff.previous) &&
                      postero_lateral_reach_diff.previous +
                      postero_lateral_reach_diff.unit}
                  </div>
                </div>
                <Chart
                  metric={postero_lateral_reach_diff}
                  ageScopes={ageScopes}
                  person={person}
                />
              </Col>
            </Row>
            <ul className="range-list center">
              {Tips.map((item, index) => (
                <li key={index}>
                  <span style={{ background: item.color }} /> {item.name}
                </li>
              ))}
            </ul>
          </BalanceContainer>
          <Divider />
        </ConditionalHideWrap>
        <ConditionalHideWrap
          name="Balance - Quiet Stand"
          title="Quiet Stand"
          syncStatus={hideSectionHandle}
          status={hideSection}
          view={view}
          viewIconStyle={{
            zIndex: 1
          }}
          condition={
            view === 'Draft' || (view === 'Preview' && !hideSection.includes('Balance - Quiet Stand'))
          }
        >
          <MovementMetricCard
            height={'400px'}
            nameMarginTop={'0px'}
            {...metrics[0]}
            {...{
              person,
              ageScopes
            }}
          />
          <Divider />
        </ConditionalHideWrap>
        <ConditionalHideWrap
          name="Balance - Single Leg Stance"
          title="Single Leg Stance"
          syncStatus={hideSectionHandle}
          status={hideSection}
          view={view}
          viewIconStyle={{
            zIndex: 1
          }}
          condition={
            view === 'Draft' || (view === 'Preview' &&!hideSection.includes('Balance - Single Leg Stance'))
          }
        >
          <MovementMetricCard
            height={'400px'}
            nameMarginTop={'0px'}
            {...metrics[1]}
            {...{
              person,
              ageScopes
            }}
          />
        </ConditionalHideWrap>
        <SingleLegStanceContainer>
          <Row align={'middle'}>
            <Col span={4}>
              <span>Side</span>
            </Col>
            <Col span={8}>
              <strong>Balance Time Eyes Open</strong>
            </Col>
            <Col>
              <strong>Balance Time Eyes Closed</strong>
            </Col>
          </Row>
          <Row align={'middle'}>
            <Col span={4}>
              <span>Left</span>
            </Col>
            <Col span={8}>
              <span
                className={classNames('status')}
                style={{
                  backgroundColor:
                    RangeColors[eyes_open_lt_10_left.color] || 'lightgray'
                }}
              >
                {eyes_open_lt_10_left.level
                  ? capitalize(eyes_open_lt_10_left.level)
                  : '--'}
              </span>
            </Col>
            <Col>
              <span
                className={classNames('status')}
                style={{
                  backgroundColor:
                    RangeColors[eyes_closed_lt_10_left.color] || 'lightgray'
                }}
              >
                {eyes_closed_lt_10_left.level
                  ? capitalize(eyes_closed_lt_10_left.level)
                  : '--'}
              </span>
            </Col>
          </Row>
          <Row align={'middle'}>
            <Col span={4}>
              <span>Right</span>
            </Col>
            <Col span={8}>
              <span
                className={classNames('status')}
                style={{
                  backgroundColor:
                    RangeColors[eyes_open_lt_10_right.color] || 'lightgray'
                }}
              >
                {eyes_open_lt_10_right.level
                  ? capitalize(eyes_open_lt_10_right.level)
                  : '--'}
              </span>
            </Col>
            <Col>
              <span
                className={classNames('status')}
                style={{
                  backgroundColor:
                    RangeColors[eyes_closed_lt_10_right.color] || 'lightgray'
                }}
              >
                {eyes_closed_lt_10_right.level
                  ? capitalize(eyes_closed_lt_10_right.level)
                  : '--'}
              </span>
            </Col>
          </Row>
          <Divider />
        </SingleLegStanceContainer>
        <ConditionalHideWrap
          name="Balance - Single Leg Squat"
          title="Single Leg Squat"
          syncStatus={hideSectionHandle}
          status={hideSection}
          view={view}
          viewIconStyle={{
            zIndex: 1
          }}
          condition={
            view === 'Draft' || (view === 'Preview' && !hideSection.includes('Balance - Single Leg Squat'))
          }
        >
          <div className="sub-title" style={{ fontWeight: 600 }}>Single Leg Squat</div>
          <SingleLegContainer>
            <div className="detail-container" style={{
              marginRight: '278px'
            }}>
              <div className="image-container">
              <img src={ gender === 'male' ? SingleLegSquatMale : SingleLegSquatFemale} alt='Single leg Squat' />
              </div>
              <div className="status-container">
                <Row className="title">
                  <Col span={6}>
                    <strong className='first-column-side'>Side</strong>
                  </Col>
                  <Col span={6}>
                    <strong className='second-column'>Loss of Balance</strong>
                  </Col>
                  <Col span={12}>
                    <strong className='second-column'>Knee Collapses Inward</strong>
                  </Col>
                </Row>
                <Row className="status-row">
                  <Col span={6}>Left</Col>
                  <Col span={9}>
                    {statusComp(loss_of_balance_left)}
                    {loss_of_balance_left.previous_level && (
                      <div className="previous">
                        Previous – {loss_of_balance_left.previous_level}
                      </div>
                    )}
                  </Col>
                  <Col span={9}>
                    {statusComp(knee_collapses_inward_left, true)}
                    {knee_collapses_inward_left.previous_level && (
                      <div className="previous">
                        Previous – {knee_collapses_inward_left.previous_level}
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="status-row">
                  <Col span={6}>Right</Col>
                  <Col span={9}>
                    {statusComp(loss_of_balance_right)}
                    {loss_of_balance_right.previous_level && (
                      <div className="previous">
                        Previous – {loss_of_balance_right.previous_level}
                      </div>
                    )}
                  </Col>
                  <Col span={9}>
                    {statusComp(knee_collapses_inward_right, true)}
                    {knee_collapses_inward_right.previous_level && (
                      <div className="previous">
                        Previous – {knee_collapses_inward_right.previous_level}
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </SingleLegContainer>
        </ConditionalHideWrap>
        <LowlevelMetrics metricValues={valueResults} view={view} hideSection={hideSection} hideSectionHandle={hideSectionHandle} />
      </section>
    </ConditionalHideWrap>
  )
}

function Chart(props) {
  const { ageScopes, metric, person } = props
  let { level, ranges, color, unit } = metric
  color = RangeColors[color] || color
  const value = metric.value

  let _ranges = handleMetricRanges(ranges, person, ageScopes)
  const rangeArea = [].concat(
    ..._ranges.map(({ min, max }) => [Number(min), Number(max)])
  )
  const _max = Math.max(...rangeArea)
  const _min = Math.min(...rangeArea)
  return (
    <div className="chart-container">
      {_ranges.map(({ min, max, color }, index) => {
        min = min || 0
        max = max || 100
        return (
          <div key={index}>
            <VictoryChart
              polar
              startAngle={(_max - max) * 1.8}
              endAngle={(_max - min) * 1.8}
              maxDomain={_max}
              minDomain={_min}
            >
              <VictoryPolarAxis
                style={{
                  axis: {
                    strokeWidth: 6,
                    stroke: color
                  },
                  tickLabels: { fill: 'transparent' }
                }}
              />
            </VictoryChart>
          </div>
        )
      })}

      <div>
        <VictoryChart
          polar
          startAngle={0}
          endAngle={180}
          tickValues={[0, 45, 90, 135, 180]}
          maxDomain={_max}
          minDomain={_min}
        >
          <VictoryPolarAxis
            style={{
              axis: {
                strokeWidth: 0
              },
              tickLabels: { fill: 'transparent' }
            }}
          />
          {_.isNumber(value) && (
            <VictoryScatter
              data={[{ x: -1 * value, y: _max }]}
              dataComponent={<Scatter color={color} />}
            />
          )}
        </VictoryChart>
      </div>
      <div className="label">
        <span className="value">{_.isNumber(value) ? value + unit : '--'}</span>
        <br />
        <span style={{ color: color }}>{level}</span>
      </div>
    </div>
  )
}

function Scatter(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={props.x - 8}
      y={props.y - 8}
    >
      <circle
        cx="8"
        cy="7.99994"
        r="6"
        fill="white"
        stroke={props.color}
        strokeWidth="4"
      />
    </svg>
  )
}
