import React, { useState, useEffect, useReducer } from 'react'
import _ from 'lodash'
import { Button } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { animateScroll as scroll } from 'react-scroll'
import { ErrorIcon } from '../../../components/icons/onboarding'
import PageLoading from '../../../components/UI/status/loading'
import Questions from './questions'
import Successful from './successful'
import NavBar from './navBar'

import { OnboardingHeader } from '../style'
import AnswerUpdater, {
  verifyAnswer,
  checkCompleted
} from '../questionComponentsV2/answerUpdater'
import { loadUser, parseUrlSearch, getRole, saveUser } from '../../../utils'
import {
  getSectionDetails,
  getSessionOrInit,
  getRecordSessions,
  updateProfileStatus,
  sendPreScreeningEmail
} from '../../../api'

const initialState = {
  sections: [],
  answers: [],
  answerUpdater: null,
  loading: false,
  saveCount: 0,
  pageTitle: '',
  showNavBar: false
}

export default function QuestionSections(props) {
  const {
    history,
    setLoginAccount,
    match: { params },
    location: { search }
  } = props
  const person = loadUser()
  const [pageLoading, setPageLoading] = useState(true)
  const [saveLoading, setSaveLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [next, setNext] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(false)

  const [state, dispatch] = useReducer((state, action) => {
    if (action.type === 'update answers') {
      const { answerUpdater } = state
      setSaveLoading(answerUpdater.isLoading())
      return _.assignIn(state, { answers: answerUpdater._answers })
    } else if (action.type === 'check answers') {
      return _.assign({}, state, { answers: action.answers })
    } else {
      return _.assign({}, state, action.payload)
    }
  }, initialState)

  const {
    sections,
    answers,
    answerUpdater,
    pageTitle,
    showNavBar,
    activeStep
  } = state
  useEffect(() => {
    const initial = async () => {
      dispatch({
        type: 'update',
        payload: initialState
      })
      setPageLoading(true)
      setShowSuccess(false)
      let sectionIds = '',
        title,
        next = {
          success: true,
          description: 'Thank you for updating your questionnaire.'
        },
        activeStep,
        showNavBar = true,
        answers = []
      if (params.section) {
        switch (params.section) {
          case 'lifestyle':
            sectionIds = 'lifestyle_goals'
            title = 'Lifestyle & Goals'
            next = '/onboarding/questionnaire/medical'
            activeStep = 0
            break
          case 'medical':
            sectionIds = 'medical_history'
            title = 'Medical History'
            next = '/onboarding/questionnaire/medication'
            activeStep = 1
            break
          case 'medication':
            sectionIds = 'medications_supplements'
            title = 'Medications & Supplements'
            next = '/onboarding/questionnaire/capacity'
            activeStep = 2
            break
          case 'capacity':
            sectionIds = 'capacity_to_exercise'
            title = 'Capacity to Exercise'
            next = '/onboarding/questionnaire/activity'
            activeStep = 3
            break
          case 'activity':
            sectionIds = 'exercise'
            title = 'Physical Activity'
            next = '/onboarding/questionnaire/nutrition'
            activeStep = 4
            break  
          case 'nutrition':
            sectionIds = 'nutrition_eating_habits'
            title = 'Nutrition Signals'
            next = '/onboarding/questionnaire/sleep'
            activeStep = 5
            break
          case 'sleep':
            sectionIds = 'sleep'
            title = 'Sleep'
            next = {
              success: true,
              description:
                'Thank you for completing onboarding. Your Apeiron Life Concierge will assist with all of your Healthspan Assessment details.\n\nWe cannot wait to help you reach your health and wellness goals!'
            }
            activeStep = 6
            break
          default:
            // showNavBar = false
            // sectionIds = 'woman_specific'
            history.push('/404')
            return
        }

        answers = await getSessionOrInit(person.id, sectionIds)
      } else if (search) {
        showNavBar = false
        sectionIds = 'woman_specific'
        const { original_ids, record_date } = parseUrlSearch(search)
        const loginRole = getRole()
        sectionIds = original_ids
        next =
          loginRole === 'User'
            ? `/app/questionnaire/group?original_ids=${original_ids}`
            : `/client/${params.id}/detail/questionnaire/group?original_ids=${original_ids}`
        answers = await getRecordSessions(sectionIds, record_date, params.id)
      } else {
        history.push('/404')
        return
      }
      const personId = getRole() === 'User' ? person.id : params.id
      const sections = await getSectionDetails(personId, sectionIds)
      if (!title) {
        title = sections[0].name
      }
      setNext(next)
      dispatch({
        type: 'initial',
        payload: {
          sections,
          answers,
          pageTitle: title,
          activeStep,
          showNavBar,
          answerUpdater: new AnswerUpdater({
            answers,
            sections,
            saveCallback: () => dispatch({ type: 'update answers' })
          })
        }
      })
      setPageLoading(false)
    }

    initial()
  }, [params.section])

  useEffect(() => {
    setConfirmBtnDisabled(saveLoading || showError)
  }, [saveLoading, showError])

  return (
    <div className="main-container">
      <OnboardingHeader />
      {showSuccess && showSuccess.success ? (
        <Successful {...showSuccess} history={history} />
      ) : (
        <>
          {showNavBar && <NavBar activeStep={activeStep} />}
          <div className={`main-content questionnaire ${params.section}`}>
            <h1 className="title">{pageTitle}</h1>
            {pageLoading && <PageLoading />}
            {showError && (
              <div className="error-message">
                <ErrorIcon />
                Error: Please answer all required questions.
              </div>
            )}

            {sections.map(({ original_id, questions }) => {
              const answerInfo = answers.find(
                (item) => item.section_original_id === original_id
              )
              return (
                <div className="section-container" key={original_id}>
                  <Questions
                    {...{
                      original_id,
                      questions,
                      answerInfo,
                      updateAnswer,
                      setConfirmBtnDisabled
                    }}
                  />
                </div>
              )
            })}
          </div>

          <footer>
            <span className="back" onClick={() => history.go(-1)}>
              <LeftOutlined /> back
            </span>

            <Button
              type="primary"
              disabled={confirmBtnDisabled}
              loading={saveLoading}
              onClick={onConfirm}
            >
              CONFIRM
            </Button>
          </footer>
        </>
      )}
    </div>
  )

  async function updateAnswer(answer, original_id, question) {
    if (showError) setShowError(false)
    const _answer = await verifyAnswer(answer, question)
    if (!JSON.stringify(_answer).includes('verify')) {
      setSaveLoading(true)
      answerUpdater.autoSave(_answer, original_id)
      if (question.has_associated) {
        dispatch({ type: 'check answers', answers: _.cloneDeep(answers) })
      }
    } else {
      const answerInfo = answers.find((item) => item.id === _answer.session_id)
      const targetIndex = answerInfo.answers.findIndex(
        (an) => an.question_original_id === _answer.question_original_id
      )
      if (targetIndex > -1) {
        answerInfo.answers[targetIndex] = _answer
      } else {
        answerInfo.answers.push(_answer)
      }
      dispatch({ type: 'check answers', answers: _.cloneDeep(answers) })
    }
  }

  async function onConfirm() {
    const { isCompleted, answers: _answers } = await checkCompleted(
      answers,
      sections
    )
    if (!isCompleted) {
      setShowError(true)
      scroll.scrollToTop({ ignoreCancelEvents: true, smooth: true })
      dispatch({
        type: 'check answers',
        answers: _answers
      })
    } else {
      if (search) {
        const { original_ids } = parseUrlSearch(search)
        if (original_ids.includes('pre_medical')) {
          const _answers = answers.find(
            (item) => item.section_original_id === 'pre_medical'
          )
          const answerValues = _answers.answers.map((item) => item.answer.value)
          const anyYes = answerValues.find((isYes) => isYes)
          if (anyYes) {
            sendPreScreeningEmail(params.id || person.id)
          }
        }
      }
      if (typeof next === 'string') {
        history.push(next)
      } else {
        if (params.section === 'medication') {
          // update profile
          updateProfileStatus(
            person.id,
            {
              onboarding_steps: { onboarding_required: false }
            },
            (_person) => setLoginAccount(_person) && saveUser(_person)
          )
        }
        setShowSuccess(next)
      }
    }
  }
}
