import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import styled from 'styled-components'
import { InputNumber, Divider } from 'antd'
import { HealthySegment } from './healthyDataViewV2'
import PageLoading from '../../UI/status/loading'
import { getGradientRanges } from './healthyDataViewV2'
import { getInterpolateColor } from '../../../utils'
import moment from 'moment'
import IconTarget from '../../../asserts/icon/healthySignals/icon-hsd-target-circle.svg'
import { getExerciseSignals, getSleepSignals, getHealthySignals } from '../../../api/healthy_signals_booster'
import { message } from 'antd'

const Container = styled.div`
  background: #ffffff;
  .list-item {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    height: 42px;
    &:last-child {
      height: auto;
      opacity: 0;
    }
    .name {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.3px;
      color: #383e48;
    }
    .segment {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .bar-container {
        margin-right: ${(props) => (props.useSleepModifiedDataView ? '100px' : '0px')};
      }
      .pct-text,
      .target-input {
        width: 50px;
        height: 26px;
        line-height: 26px;
        border: 1px solid #8bb7e1;
        border-radius: 5px;
        font-size: 14px;
        margin-left: ${(props) => (props.useSleepModifiedDataView ? '0px' : '55px')};
        text-align: center;
        font-weight: 600;
        &.label {
          border: none;
          color: #52606c;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 600;
        }
      }
      .target-input {
        margin-left: ${(props) => (props.useSleepModifiedDataView ? '20px' : '33px')};
        border: none;
        .ant-input-number {
          width: 50px;
          border-radius: 5px;
          border-color: inherit;
          .ant-input-number-handler-wrap {
            display: none;
          }
          .ant-input-number-input {
            height: 26px;
            text-align: center;
          }
        }
        .anticon {
          position: absolute;
          margin-left: -41px;
          top: 50%;
          transform: translateY(-50%);
          color: #a5c3df;
        }
        .more-avg {
          background-color: rgb(241, 243, 246);
          width: 50px;
          display: inline-block;
          border-radius: 5px;
        }
      }
      & > div:last-child {
        width: 81px;
      }
      .mark-container {
        margin-right: ${(props) => (props.useSleepModifiedDataView ? '96px' : '0px')};
        width: 360px;
        display: flex;
        align-items: center;
        .mark-current-line {
          width: 16px;
          height: 2px;
          background-color: #2789e9;
        }
        img {
          margin-left: 16px;
        }
        .mark-label {
          color: #8596a4;
          font-family: Gilroy;
          font-size: 12px;
          line-height: 130%;
          margin-left: 6px;
        }
      }
    }
    .range-bar {
      color: #c0c7d1;
      font-size: 12px;
      width: 358px;
      display: flex;
      justify-content: space-between;
      padding-top: 12px;
      span {
        transform: translate(50%, 0);
        &:first-child {
          transform: translate(-50%, 0);
        }
      }
    }
    .range-gride {
      position: absolute;
      right: 188px;
      top: 16px;
      .bar-gride {
        width: 358px;
        display: flex;
        justify-content: space-between;
        .ant-divider-vertical {
          height: 326px;
          border-color: #c9c9c9;
          margin: 0;
          border-style: dashed;
          border-width: 1px;
        }
      }
    }
  }
`

export default function CompactView(props) {
  const {
    cardList,
    pageLoading,
    hightestValue,
    saveTargets,
    currentWeek,
    startDate,
    endDate,
    personId
  } = props

  const [healthySignalsData, setHealthySignalsData] = useState(null)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const useModfiedSleepDataView =
    queryParams.get('use-modified-sleep-data-view') === 'true'
  const previousWeekStartDate = moment(startDate)
    .subtract(6, 'days')
    .format('YYYY-MM-DD')

  useEffect(() => {
    if (useModfiedSleepDataView && personId) {
      getHealthySignals(
        personId,
        previousWeekStartDate,
        endDate,
        'json',
        'ouraring'
      )
        .then((data) => setHealthySignalsData(data))
        .catch((err) =>
          message.error('Error fetching HealthySignals data:', err)
        )
    }
  }, [location.search, startDate, endDate])

  let _hightestValue = hightestValue
  if (_hightestValue < 100 || _.isNaN(hightestValue)) {
    _hightestValue = 100
  }

  const step = Math.round(hightestValue / 3)
  const ranges = [0, step, step * 2, step * 3]

  return (
    <Container useSleepModifiedDataView={useModfiedSleepDataView}>
      {pageLoading ? (
        <PageLoading />
      ) : (
        <>
          <div className="list-item">
            <h5 className="">Exercise Signals</h5>
            <div className="segment">
              <div className="mark-container">
                <div className="mark-current-line" />
                <span className="mark-label">Current</span>
                <img src={IconTarget} className="send" alt="Target" />
                <span className="mark-label">Target</span>
              </div>
              <div
                className="pct-text label"
                style={{
                  marginRight: useModfiedSleepDataView ? '24px' : '0px'
                }}
              >
                {useModfiedSleepDataView ? 'Exercise' : 'Current'}
              </div>
              {useModfiedSleepDataView && (
                <>
                  <div
                    className="pct-text label"
                    style={{ marginRight: '18px' }}
                  >
                    Sleep
                  </div>
                  <div className="pct-text label">Net</div>
                </>
              )}
              <div className="target-input label">target</div>
              <div className="target-input more-avg label">3-week avg</div>
            </div>
          </div>
          {cardList.map((item, index) => {
            item.data = item.data || {}
            const {
              data,
              target,
              domain_label
              // weekly_seg_label
              // monthly_seg_label
            } = item
            // let _hightestValue = Math.max(
            //   data.seg_pct,
            //   target,
            //   data.more_seg_pct_avg
            // )
            // if (_hightestValue < 100) {
            //   _hightestValue = 100
            // }
            // const step = Math.round(_hightestValue / 3)
            // const ranges = [0, step, step * 2, step * 3]
            const textColor = getColor(data.seg_pct, _hightestValue)
            const targetColor = getColor(target, _hightestValue)
            const moreAvgColor = getColor(
              data.more_seg_pct_avg,
              Math.max(_hightestValue, data.more_seg_pct_avg)
            )
            const computeSumOfModifiedValues = (data, workouts) => {
              if (!data || !Array.isArray(data) || !workouts) return {}

              const workoutDates = new Set(workouts.map(w => w.record_date))

              return data.reduce((acc, entry) => {
                if (workoutDates.has(entry.record_date)) {
                  Object.keys(entry).forEach((key) => {
                    if (key.endsWith('_modified')) {
                      acc[key] = (acc[key] || 0) + (entry[key] || 0)
                    }
                  })
                }
                return acc
              }, {})
            }

            const summedHealthySignals = computeSumOfModifiedValues(
              healthySignalsData,
              data.seg_workouts
            )

            const sumHsdPct = (data) => {
              if (!data || !Array.isArray(data.seg_workouts)) return 0
              return data.seg_workouts.reduce((sum, workout) => sum + (workout.hsd_pct || 0), 0)
            }

            return (
              <div key={index} className="list-item">
                <div className="name">{domain_label}</div>
                <div className="segment">
                  <HealthySegment
                    hidePCTText
                    showTargetLine
                    {...{
                      // label:
                      //   rangeType === 'week'
                      //     ? weekly_seg_label
                      //     : monthly_seg_label,
                      widthReference: 'seg_pct',
                      seg_pct: data.seg_pct,
                      workouts: data.seg_workouts,
                      data,
                      ranges,
                      target,
                      hightestValue: _hightestValue,
                      useModfiedSleepDataView,
                      healthySignalsData,
                      domain_label
                    }}
                  />

                  <div
                    className="pct-text"
                    style={{ color: textColor, borderColor: textColor }}
                  >
                    {sumHsdPct(data) || '--'}
                  </div>
                  {useModfiedSleepDataView && (
                    <>
                      <div
                        className="target-input"
                        style={{
                          color: targetColor,
                          border: `1px solid ${targetColor}`
                        }}
                      >
                        {summedHealthySignals[`${item.domain}_modified`] ||
                          '--'}
                      </div>
                      <div
                        className="target-input"
                        style={{
                          color: targetColor,
                          border: `1px solid ${targetColor}`
                        }}
                      >
                        {(sumHsdPct(data) || 0) +
                          (summedHealthySignals[`${item.domain}_modified`] ||
                            0) ===
                          0
                          ? '--'
                          : (sumHsdPct(data) || 0) +
                          (summedHealthySignals[`${item.domain}_modified`] ||
                            0)}
                      </div>
                    </>
                  )}
                  <div
                    className="target-input"
                    style={{
                      color: targetColor,
                      border: `1px solid ${targetColor}`
                    }}
                  >
                    <TargetEditor
                      defaultValue={target}
                      type={item.domain}
                      saveValue={saveTargets}
                      currentWeek={currentWeek}
                    />
                    {/* {target >= data.seg_pct ? (
                      <CaretRightOutlined />
                    ) : (
                      <CaretLeftOutlined />
                    )} */}
                  </div>
                  <div className="target-input" style={{ color: moreAvgColor }}>
                    <div className="more-avg">
                      {data.more_seg_pct_avg || '--'}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
          <Divider />
          {/* <div className="list-item">
            <div className="name"></div>
            <div className="segment">
              <div className="range-bar">
                {ranges.map((value, index) => (
                  <span key={index}>{value}</span>
                ))}
              </div>
              <div className="pct-text label"></div>
              <div className="target-input label"></div>
            </div>
          </div> */}
        </>
      )}
    </Container>
  )
}

export function getColor(value, hightestValue) {
  if (!hightestValue) return
  // const colors = getGradientRanges(Math.round(hightestValue / 1.25))
  const colors = getGradientRanges(Math.round(hightestValue))
  const ratio = value / hightestValue
  const { color } = getInterpolateColor(colors, ratio === 1 ? 0.99 : ratio || 0)
  return color
}

function TargetEditor(props) {
  const { defaultValue, type, saveValue, currentWeek } = props

  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(defaultValue)

  return isEditing ? (
    <InputNumber
      min={0}
      bordered={false}
      autoFocus
      value={value}
      onPressEnter={async (e) => {
        const newValue = Number(e.target.value)
        if (newValue && _.isNumber(newValue)) {
          saveValueHandle(newValue)
        }
      }}
      onBlur={(e) => {
        const newValue = Number(e.target.value)
        if (newValue && _.isNumber(newValue)) {
          saveValueHandle(newValue)
        }
      }}
    />
  ) : (
    <div
      onClick={() => {
        if (moment(currentWeek).isSame(moment(), 'week')) {
          setIsEditing(true)
        }
      }}
    >
      {value}
    </div>
  )

  async function saveValueHandle(newValue) {
    const previous = value
    setValue(newValue)
    try {
      await saveValue(type, newValue)
      setIsEditing(false)
    } catch (err) {
      setValue(previous)
    }
  }
}
