import { getAgeTag } from '../../../utils'

export const formatDisplayName = (metricKey) => {
  if (!metricKey) return ''

  if (metricKey === 'waist') return 'Waist Circumference'
  if (metricKey === 'multi_segment_extenstion') return 'Multi Segmental Extension'
  if (metricKey === 'multi_segment_flexion') return 'Multi Segmental Flexion'

  return metricKey
    .replace(/_diff$/, ' Difference') 
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase())
}

export const getRangeByAgeAndGender = (ranges, person, ageScopes) => {
  const ageTag = getAgeTag(person.profile, ageScopes)

  return ranges.map((range) => {
    const scope = range[`${person.profile.gender}_age_scope`][ageTag]
    if (scope) {
      const [min, max, color] = scope
      return { name: range.name, min, max, color }
    }
    return null
  })
}
