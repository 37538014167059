import React from 'react'
import CapacitySection from './CapacitySection'
import GoalsSection from './GoalsSection'
import HealthSpanSection from './HealthSpanSection'
import RationaleSection from './RationaleSection'

const TopGrid = ({ lifestyleScore, goals, hsd, rationale }) => {
    return (
        <div className="top-grid">
            {/* Left Column */}
            <div className="left-column">
                <CapacitySection lifestyleScore={lifestyleScore} />
                <GoalsSection goals={goals} />
            </div>

            {/* Middle Column */}
            <div className="middle-column">
                <HealthSpanSection hsd={hsd} />
            </div>

            {/* Right Column */}
            <div className="right-column">
                <RationaleSection rationale={rationale} />
            </div>
        </div>
    )
}

export default TopGrid 
