import React from 'react'
import { StyledSectionOverflow } from '../StyledSections'

const RationaleSection = ({ rationale }) => {
    return (
        <StyledSectionOverflow title="Activity Rationale">
            <p className="rationale-text">{rationale}</p>
        </StyledSectionOverflow>
    )
}

export default RationaleSection 
