import React from 'react'
import { StyledSection } from '../StyledSections'
import { getCapacityColor } from '../utils'

const CapacitySection = ({ lifestyleScore }) => {
    return (
        <StyledSection title="Capacity to Exercise" className="half-height">
            <div
                className="metric-value"
                style={{
                    color: lifestyleScore && lifestyleScore.cte
                        ? getCapacityColor(lifestyleScore.cte)
                        : 'rgb(165, 42, 42)' // Default to darker red
                }}
            >
                {lifestyleScore && lifestyleScore.cte
                    ? (lifestyleScore.cte).toFixed(1)
                    : '0'}%
            </div>
            <div className="metric-label">Current Capacity</div>
        </StyledSection>
    )
}

export default CapacitySection 
