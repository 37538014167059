import React, { useState, useRef } from 'react'
import _ from 'lodash'
import { Button } from 'antd'
import ReactQuill from 'react-quill'
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
import 'react-quill/dist/quill.snow.css'
import styled from 'styled-components'
import classNames from 'classnames'
import ConfirmPopUp from '../../UI/extraInformation/confirm'

const EditContainer = styled.div`
  border: ${(props) => (props.editing ? '1px solid #ccc' : 'none')};

  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #ccc;
  }
  .ql-container {
    border: none;
  }

  // .custom-font {
  //   font-family: ${(props) => props.fontFamily || 'Gilroy'};
  //   font-size: ${(props) => props.fontSize || '15px'};
  //   color: ${(props) => props.fontColor || 'rgba(112, 128, 142, 1)'};
  //   &.placeholder {
  //     color: rgba(169, 169, 169, 0.7);
  //   }
  // }
  .custom-font {
    font-family: ${(props) => props.fontFamily || 'Gilroy'};
    font-size: ${(props) => props.fontSize || '15px'};
    color: ${(props) => props.fontColor || 'rgba(112, 128, 142, 1)'};
    white-space: pre-wrap;
    word-wrap: break-word;

    &.placeholder {
      // color: rgba(169, 169, 169, 0.7);
    }
    // Style the HTML output from Quill
    p {
      margin-bottom: 1em;
    }
    ul,
    ol {
      margin-left: 1.5em;
      margin-bottom: 1em;
    }
    li {
      margin-bottom: 0.5em;
    }
    strong {
      font-weight: bold;
    }
    em {
      font-style: italic;
    }
    // Style the HTML output from Quill
    p {
      margin-bottom: 1em;
    }
    ul,
    ol {
      margin-left: 1.5em;
      margin-bottom: 1em;
    }
    li {
      margin-bottom: 0.5em;
    }
    strong {
      font-weight: bold;
    }
    em {
      font-style: italic;
    }
  }
  .submit-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 17px;
    button {
      color: #264382;
    }
  }
`

const formats = [
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link'
]

export default function EditableText(props) {
  const {
    className,
    value,
    editing,
    setEditing,
    fontStyle = {},
    onSave,
    placeholder = ''
  } = props
  const [text, setText] = useState(value)
  return (
    <EditContainer
      className={classNames(className)}
      editing={editing}
      {...fontStyle}
    >
      {editing ? (
        <RichTextEditor
          simpleMode={props.simpleMode || false}
          text={text}
          placeholder={placeholder}
          onCancel={() => setEditing(false)}
          onSave={(text) => {
            setText(text)
            onSave && onSave(text)
            setEditing(false)
          }}
        />
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: text || placeholder
          }}
          className={classNames('custom-font', { placeholder: !text })}
        />
      )}
    </EditContainer>
  )
}

const RichTextEditor = (props) => {
  const { text, onCancel, onSave, placeholder = 'Leave a message' } = props
  const [cancelConfirm, setCancelConfirm] = useState(false)
  let editorRef = useRef(null)

  const modules = {
    toolbar: {
      container: props.simpleMode
        ? [['bold', 'italic', 'underline', 'strike', 'blockquote']]
        : [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
              { list: 'ordered' },
              { list: 'bullet' },
              { indent: '-1' },
              { indent: '+1' }
            ],
            ['link']
          ]
    }
  }

  return (
    <>
      <ReactQuill
        ref={editorRef}
        theme="snow"
        className="custom-font"
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        // onKeyDown={onKeyDown}
        defaultValue={text}
      />
      <div className="submit-button">
        <Button type="text" onClick={() => setCancelConfirm(true)}>
          CANCEL
        </Button>
        <Button
          type="text"
          onClick={submit}
          // disabled={submitting || props.loading}
          // loading={submitting}
        >
          SAVE
        </Button>
      </div>
      <ConfirmPopUp
        title="Cancel editing confirmation"
        content="Changes you made to this section will not be saved. Are you sure you want to continue?"
        okText="DISCARD"
        visible={cancelConfirm}
        handleCancel={() => setCancelConfirm(false)}
        handleOk={onCancel}
      />
    </>
  )

  function getEditor() {
    const getEditor = _.get(editorRef, 'current.getEditor')
    const quillEditor = getEditor && getEditor()
    let delta, range
    if (quillEditor) {
      delta = quillEditor.getContents()
      range = quillEditor.getSelection()
    }
    return { quillEditor, delta, range }
  }

  async function submit() {
    const { quillEditor, delta } = getEditor()
    const converter = new QuillDeltaToHtmlConverter(delta.ops, {})
    const richText = converter.convert()
    const text = quillEditor.getText()
    if (!text.trim() && !_.includes(richText, '<img')) {
      return
    }
    onSave(richText)
  }
}
