import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import axios from 'axios'
import styled from 'styled-components'
import { Button, Divider, message, Modal, Spin } from 'antd'
import { EditPanIcon } from '../icons'
import PageLoading from '../UI/status/loading'
import RichText from '../richText'
import ConnectingModal from './connecting'
import DisconnectService from './disconnectService'
import ClientForm from '../modalFrom/clientForm'
import WelcomeEmail from '../modalFrom/welcomeEmail'
import { formatInch, getRole } from '../../utils/common'
import OverviewMetrics from './metrics'
// import HealthySignalsV1 from './healthy'
// import TimeLine from './timeline'
import TimelineMini from './timelineMini'
import {
  editClient,
  getClientProfileInfo,
  getConnectDeviceURL,
  getSupportTeam
} from '../../api/team'
import { getPeopleById, deletePeople } from '../../api/people'
import { getActivityNames } from '../../api/microGoals'
import { autoSource } from '../../models/goals.form'
import { profileStyle, deleteClientStyle } from './profile.sass'
// import checkIcon from '../../asserts/icon/client/check.png'
// import warningIcon from '../../asserts/icon/client/warning.png'
import { loadUser } from '../../utils/storage'
import _ from 'lodash'
import { ClientInfo } from './comp/clientInfo'
import { getAdaptiveRecommendation } from '../../api/adaptiveCoach'

const confirm = Modal.confirm

class ClientProfile extends Component {
  static propTypes = {
    person: PropTypes.object
  }

  state = {
    pageLoading: true,
    date: null,
    person: { profile: {} },
    isEditing: false,
    saveAreaOfFocus: null,
    user: loadUser(),
    isConnecting: false,
    connectSource: null,
    showEditProfile: false,
    showEditEmail: false,
    showMembership: false,
    showPartType: 'profile',
    aiInsight: '',
    loadingInsights: true,
    aiWorkouts: [],
    loadingWorkouts: true,
    aiWorkoutsDate: null
  }

  saveAreaOfFocus = async () => {
    const { person } = this.props
    const { areasOfFocus } = this.state
    const areas_of_focus = (areasOfFocus || '')
      .replace(/<ul>/g, '<ul style="padding-left: 15px">')
      .replace(/<ol>/g, '<ol style="padding-left: 15px">')
    const result = await editClient(
      {
        profile: { areas_of_focus }
      },
      person.id
    )
    this.setState({
      person: result.team.person,
      isEditing: false
    })
  }

  saveLTA = async () => {
    const { person } = this.props
    const { longTermAspirations } = this.state
    const formatData = (longTermAspirations || '')
      .replace(/<ul>/g, '<ul style="padding-left: 15px">')
      .replace(/<ol>/g, '<ol style="padding-left: 15px">')
    const result = await editClient(
      {
        profile: {
          long_term_aspirations: formatData
        }
      },
      person.id
    )
    this.setState({
      person: result.team.person,
      isEditingLTA: false
    })
  }

  getInfo = async (person = this.state.person) => {
    const information = await getClientProfileInfo(person.id)
    this.setState({ information })
  }

  connectDevice = async (service, role) => {
    const { person } = this.props
    this.setState({ isConnecting: true })
    try {
      const CancelToken = axios.CancelToken
      const connectSource = CancelToken.source()
      this.setState({ connectSource })
      const result = await getConnectDeviceURL(
        service,
        person.id,
        connectSource,
        role.toLowerCase()
      )
      if (result) {
        this.setState({ isConnecting: false })
        window.location.href = result.redirect
      }
    } catch (err) {
      console.error(err)
      this.setState({ isConnecting: false })
    }
  }

  cancelConnect = () => {
    const { connectSource } = this.state
    connectSource && connectSource.cancel()
    this.setState({ isConnecting: false })
  }

  disConnect = (service) => {
    if (service) {
      this.setState({ disconnectService: service })
    }
  }

  disconnectCompleted = () => {
    this.setState({ disconnectService: '' })
    this.getInfo()
    message.success('Successfully disconnected')
  }

  handleDeleteClient = () => {
    const { person } = this.state
    const { history } = this.props
    confirm({
      title: `Do you want to delete client: ${person.profile.nickname ||
        person.first_name} ${person.last_name}?`,
      icon: <></>,
      className: deleteClientStyle,
      width: '546px',
      content: (
        <>
          <p>Please be aware when this account is deleted:</p>
          <ul>
            <li>You can't access this account anymore</li>
            <li>This account will no longer be able to log in</li>
            <li>
              You will not be able to retrieve this account data or reactivate
              this account. This is a permanent action
            </li>
            <li>Programs (if any) of this account will be canceled</li>
          </ul>
        </>
      ),
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        return deleteClient()
      },
      onCancel() {}
    })

    async function deleteClient() {
      try {
        await deletePeople(person.id)
        message.success('The account has been deleted!')
        history.replace('/client')
      } catch (err) {
        console.error(err)
        message.warning(err.message)
      }
    }
  }

  initial = async () => {
    const { person } = await getPeopleById(this.props.person.id)
    await this.getInfo(person)
    const activityNames = await getActivityNames()
    const { al_team_member } = await getSupportTeam(person.id)

    this.setState({
      person,
      activityNames,
      pageLoading: false,
      longTermAspirations: person.profile.long_term_aspirations,
      areasOfFocus: person.profile.areas_of_focus,
      alTeam: al_team_member
    })
  }

  getPersonInfo = () => {
    const { person } = this.state
    const age = moment().diff(person.profile.date_of_birth, 'years')
    const { height_ft, height_in } = formatInch(person.profile.height_inch)
    const personalInfo = [
      {
        key: 'Age',
        value: age
      },
      {
        key: 'Gender',
        value: (
          <div style={{ textTransform: 'capitalize', display: 'inline-block' }}>
            {person.profile.gender}
          </div>
        )
      },
      { key: 'Height', value: `${height_ft}'${height_in}''` },
      { key: 'Weight', value: _.round(person.profile.weight_lbs) + ' Lbs' },
      {
        key: 'Location',
        value: `${person.profile.city}, ${person.profile.state}`
      }
    ]
    return personalInfo
  }

  fetchInsights = async (selectedDate) => {
    const { person } = this.props
    this.setState({ loadingInsights: true, loadingWorkouts: true })

    try {
      // Use the selectedDate if provided, otherwise use today's date
      const date = selectedDate || moment().format('YYYY-MM-DD')
      // Retrieve recommendations via the /recommendation/ endpoint
      const response = await getAdaptiveRecommendation(person.id, date)
      const { combined_recommendation } = response || {}

      // Transform each recommendation into the shape your weekly schedule expects
      const aiWorkouts = (combined_recommendation || []).map((rec) => ({
        // Use backend-provided ID instead of creating our own
        id: rec.id,
        person_id: person.id, // Store person_id for API calls
        day_of_week: rec.day_of_week,
        duration: rec.duration,
        intensity: rec.intensity,
        category: rec.category,
        workout_type: rec.workout_type,
        description: rec.description,
        isAiRecommendation: true,
        isSleepActivity: rec.isSleepActivity,
        isProgramItem: rec.isProgramItem || false,
        published: rec.published || false, // Include published status
        record_date: moment()
          .startOf('week')
          .add(rec.day_of_week, 'days')
          .format('YYYY-MM-DD')
      }))

      this.setState({
        aiWorkouts,
        aiInsight: '',
        loadingWorkouts: false,
        loadingInsights: false,
        aiWorkoutsDate: date
      })
    } catch (error) {
      console.error('Error fetching insights:', error)
      this.setState({ loadingInsights: false, loadingWorkouts: false })
    }
  };

  // Add this method to ClientProfile to ensure a refresh after updates
  refreshAiWorkouts = (selectedDate) => {
    // Call fetchInsights with the selected date or use default behavior
    this.fetchInsights(selectedDate)
  }

  componentDidMount() {
    this.initial()
    this.fetchInsights()
    this.fetchInsights()
  }

  renderOverview() {
    const {
      person,
      information,
      user,
      isEditing,
      isEditingLTA,
      areasOfFocus,
      longTermAspirations,
      isConnecting,
      showEditProfile,
      showEditEmail,
      showMembership,
      disconnectService,
      activityNames,
      aiInsight,
      loadingInsights,
      aiWorkouts,
      loadingWorkouts
    } = this.state
    const { device_info } = information || {}
    const role = getRole(user.role)
    const loginRole = getRole()
    const personalInfo = this.getPersonInfo()
    const childProps = {
      editProfile: () => this.setState({ showEditProfile: true }),
      connectDevice: (item) => this.connectDevice(item.service, role),
      disConnect: this.disConnect,
      device_info,
      personalInfo,
      autoSource,
      isEditing,
      person,
      user,
      areasOfFocus,
      saveAreaOfFocus: this.saveAreaOfFocus,
      editArea: () => this.setState({ isEditing: true }),
      onAreaChange: (value) =>
        this.setState({
          areasOfFocus: value
        }),
      cancelArea: () =>
        this.setState({
          isEditing: false,
          areasOfFocus: person.profile.areas_of_focus
        }),
      isEditingLTA,
      longTermAspirations,
      cancelLTA: () =>
        this.setState({
          isEditingLTA: false,
          longTermAspirations: person.profile.long_term_aspirations
        }),
      saveLTA: this.saveLTA,
      editLTA: () => this.setState({ isEditingLTA: true }),
      onLTAChange: (value) =>
        this.setState({
          longTermAspirations: value
        }),
      aiInsight,
      loadingInsights,
      aiWorkouts,
      loadingWorkouts
    }

    return (
      <>
        {loginRole === 'User' || loginRole === 'Service_Provider' ? (
          <ProfileClientView {...childProps} />
        ) : (
          <ProfileExpertView {...childProps} />
        )}
        <ConnectingModal cancel={this.cancelConnect} visible={isConnecting} />
        {disconnectService ? (
          <DisconnectService
            service={disconnectService}
            person_id={person.id}
            canceled={() => {
              this.setState({ disconnectService: '' })
            }}
            completed={this.disconnectCompleted}
          />
        ) : (
          <></>
        )}

        {/* <div className="section insights-section">
          <div className="insights-container">
            <div className="insight-box">
              <h3>Insights</h3>
              {loadingInsights ? (
                <Spin />
              ) : (
                <p 
                  className="insight-text"
                  dangerouslySetInnerHTML={{ __html: aiInsight }}
                />
              )}
            </div>
          </div>
        </div> */}

        {!this.props.isNonCore && (
          <>
            <Divider />
            <OverviewMetrics
              {...this.props}
              activityNames={activityNames}
              targetPillar="nutrition"
              period="weekly"
              loginRole={role}
              originView="Overview"
              aiWorkouts={this.state.aiWorkouts}
              aiWorkoutsDate={this.state.aiWorkoutsDate}
              loadingWorkouts={this.state.loadingWorkouts}
              refreshAiWorkouts={this.refreshAiWorkouts}
            />
          </>
        )}
        {showEditProfile && (
          <ClientForm
            loginRole={role}
            initValue={person}
            onCancel={(state) =>
              this.setState({ showEditProfile: false, ...state })
            }
            onDelete={this.handleDeleteClient}
            submitSync={(person) => this.setState({ person })}
            editEmail={() =>
              this.setState({ showEditProfile: false, showEditEmail: true })
            }
            sendMemeberShip={() =>
              this.setState({ showEditProfile: false, showMembership: true })
            }
          />
        )}
        {showEditEmail && (
          <WelcomeEmail
            onCancel={() => this.setState({ showEditEmail: false })}
            person={person}
          />
        )}
        {showMembership && (
          <WelcomeEmail
            onCancel={() => this.setState({ showMembership: false })}
            isMembership={true}
            person={person}
          />
        )}
        {/* Healthy Signals */}

        {/* {loginRole !== 'User' && (
          <>
            <Divider />
            <HealthySignalsV1 person={person} />
          </>
        )} */}
      </>
    )
  }

  render() {
    const {
      pageLoading,
      person,
      showPartType,
      alTeam,
      information,
      user
    } = this.state
    const role = getRole(user.role)
    const loginRole = getRole()

    return pageLoading ? (
      <PageLoading />
    ) : (
      <div className={profileStyle}>
        <ClientInfo
          person={person}
          deviceInfo={_.get(information, 'device_info', {})}
          editProfile={() => this.setState({ showEditProfile: true })}
          disconnect={(service) => this.disConnect(service)}
          connect={(service) => this.connectDevice(service, role)}
          loginRole={loginRole}
        />
        {loginRole === 'User' || loginRole === 'Service_Provider' ? (
          <>
            {alTeam && loginRole !== 'Service_Provider' && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <TeamGroup alTeam={alTeam} />
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    this.props.history.push('/app/message')
                  }}
                  style={{ marginLeft: 32, background: 'white' }}
                >
                  Chat with your team
                </Button>
              </div>
            )}
          </>
        ) : (
          <></>
        )}

        {/* {(loginRole === 'Expert' || loginRole === 'InternalAdmin') && (
          <ul className="type-list">
            <li
              className={showPartType === 'profile' ? 'active' : ''}
              onClick={() => this.setState({ showPartType: 'profile' })}
            >
              Profile
            </li>
            <li
              className={showPartType === 'timeline' ? 'active' : ''}
              onClick={() => this.setState({ showPartType: 'timeline' })}
            >
              Timeline
            </li>
          </ul>
        )} */}
        <div
          style={{
            display: showPartType === 'profile' ? 'block' : 'none'
          }}
        >
          {this.renderOverview()}
        </div>
        {/* <div
          style={{
            display: showPartType === 'timeline' ? 'block' : 'none'
          }}
        >
          <TimeLine person={person} />
        </div> */}
      </div>
    )
  }
}

export default ClientProfile

const ClientContainer = styled.div`
  .info-content {
    .info-card:first-child {
      width: 348px;
    }
    .avatar {
      img {
        width: 48px;
        height: 48px;
      }
      & + .name {
        margin: 15px 0;
        font-size: 18px;
        color: #383e48;
      }
    }

    .overview {
      display: block;
      /* margin-left: 24px; */
      /* width: calc(100% - 348px); */
      flex-grow: 1;
      /* .section {
        width: 100%;
      } */
    }
    .devices-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .link {
        color: #2b4b8f;
        font-size: 12px;
        cursor: pointer;
      }
    }
    .devices-list {
      display: flex;
      justify-content: space-between;
      .item {
        position: relative;
        margin-right: 10px;
        img {
          box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
          border-radius: 100%;
          &.icon {
            position: absolute;
            right: 0;
            margin-left: -46px;
            width: 18px;
          }
        }
      }
      img {
        width: 48px;
      }
    }
  }

  .insights-section {
    margin-top: 24px;
    
    .insights-container {
      display: flex;
      gap: 24px;
      
      .insight-box {
        flex: 1;
        background: #fff;
        padding: 20px;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
        
        h3 {
          margin-bottom: 16px;
          font-size: 16px;
          color: #383e48;
        }
        
        .insight-text {
          color: #383e48;
          line-height: 1.5;
          white-space: pre-wrap;
          word-wrap: break-word;
          margin: 0;
        }
      }
    }
  }
`

// const DevicesContainer = styled.div`
//   .devices-list {
//     display: flex;
//     flex-wrap: wrap;
//     .item {
//       margin-right: 32px;
//       margin-bottom: 15px;
//       text-align: center;
//       position: relative;
//       font-size: 14px;
//       font-weight: 600;
//       color: #383e48;
//       img {
//         box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
//         border-radius: 100%;
//         &.icon {
//           position: absolute;
//           right: 0;
//           margin-left: -46px;
//           width: 18px;
//         }
//       }
//     }

//     &.expert-view {
//       .item {
//         width: 100%;
//         display: flex;
//         justify-content: space-between;
//         margin-bottom: 16px;
//         margin-right: 0;
//         align-items: center;
//         & > div {
//           width: calc(100% - 115px);
//           display: flex;
//           align-items: center;
//           & > span {
//             max-width: calc(100% - 70px);
//           }
//         }
//         img {
//           width: 49px;
//           height: 49px;
//           &.icon {
//             right: auto;
//             margin-left: 35px;
//             margin-top: -16px;
//             width: 16px;
//             height: 16px;
//           }
//           & + span {
//             display: inline-block;
//             padding-left: 20px;
//             vertical-align: middle;
//             text-align: left;
//             .sync-info {
//               color: #6d7d8c;
//               font-size: 12px;
//               &.error {
//                 color: #e75f25;
//               }
//             }
//           }
//         }
//         button {
//           width: 116px;
//           height: 36px;
//         }
//       }
//     }
//   }
// `

function ProfileClientView(props) {
  // const [viewMore, setViewMore] = useState(false)
  const {
    // connectDevice,
    // personalInfo,
    // autoSource,
    // device_info,
    person
    // disConnect
  } = props
  // const loginRole = getRole()
  return (
    <ClientContainer>
      <TimelineMini person={person} />
      <div className="info-content">
        {/* <div className="info-card">
          <div className="avatar">
            {person.avatar && (
              <img
                className="avatar-small"
                src={person.avatar.oss_url}
                alt=""
              />
            )}
          </div>
          <div className="name">
            {`${(person.profile && person.profile.nickname) ||
              person.first_name} ${person.last_name}`}
          </div>
          <ul className="personal-info-list">
            {personalInfo.map((item, index) => (
              <li key={index}>
                <span>{item.key}</span>
                {item.value}
              </li>
            ))}
          </ul>
          <Divider />
          <h3 className="devices-title">
            <span>Devices</span>
            {loginRole !== 'Service_Provider' && (
              <div className="link" onClick={() => setViewMore(true)}>
                View more
              </div>
            )}
          </h3>
          <div className="devices-list">
            {autoSource.map((item, index) => {
              const deviceInfo = device_info[item.value] || {}
              return (
                <div key={index} className="item">
                  <img src={item.leftExtra} alt="" />
                  {deviceInfo.connection_status === 'Connected' && (
                    <img className="icon" src={checkIcon} alt="check" />
                  )}
                  {deviceInfo.connection_status === 'Disconnect' && (
                    <img className="icon" src={warningIcon} alt="warning" />
                  )}
                </div>
              )
            })}
          </div>
        </div> */}
        <div className="overview">
          <div className="section">
            <h3>Aspirations</h3>

            <p
              dangerouslySetInnerHTML={{
                __html: person.profile.long_term_aspirations
                // &&
                // person.profile.long_term_aspirations.replace(
                //   /style="(.*?)"/g,
                //   ''
                // )
              }}
            />
          </div>
          <div className="section">
            <h3>Goals</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: person.profile.areas_of_focus
              }}
            />
          </div>
        </div>
      </div>
      {/* <Modal
        open={viewMore}
        onCancel={() => setViewMore(false)}
        footer={null}
        style={{
          width: 600
        }}
      >
        <DevicesContainer>
          <h2>Devices</h2>
          <div className="expert-view devices-list">
            {autoSource.map((item, index) => {
              const deviceInfo = device_info[item.value] || {}

              return (
                <div key={index} className="item">
                  <div>
                    <img src={item.leftExtra} alt="" />
                    {deviceInfo.connection_status === 'Connected' && (
                      <img className="icon" src={checkIcon} alt="check" />
                    )}
                    {deviceInfo.connection_status === 'Disconnect' && (
                      <img className="icon" src={warningIcon} alt="warning" />
                    )}
                    <span>
                      {item.name}
                      <br />
                      <span
                        className={`sync-info ${
                          deviceInfo.connection_status === 'Disconnect'
                            ? 'error'
                            : ''
                        }`}
                      >
                        {deviceInfo.connection_status === 'Disconnect' && (
                          <>
                            Connection broken
                            <br />
                          </>
                        )}
                        {deviceInfo.last_sync && (
                          <>
                            Last updated{' '}
                            {dateFormat(
                              deviceInfo.last_sync.toString().substr(0, 15),
                              'referYesterday'
                            )}
                          </>
                        )}

                        {!deviceInfo.last_sync &&
                          item.value === 'healthkit' && (
                            <>
                              Healthkit must be connected via the Apeiron Life
                              iOS app
                            </>
                          )}
                      </span>
                    </span>
                  </div>
                  {deviceInfo.connection_status === 'Connected' ? (
                    <Button
                      type="success"
                      ghost
                      onClick={() => disConnect(item.service)}
                    >
                      Connected
                    </Button>
                  ) : (
                    item.value !== 'healthkit' && (
                      <Button
                        type="primary"
                        ghost
                        onClick={() => connectDevice(item)}
                      >
                        {deviceInfo.connection_status === 'Disconnect'
                          ? 'Reconnect'
                          : 'Connect'}
                      </Button>
                    )
                  )}
                </div>
              )
            })}
          </div>
        </DevicesContainer>
      </Modal> */}
    </ClientContainer>
  )
}

function ProfileExpertView(props) {
  const {
    isEditing,
    person,
    areasOfFocus,
    saveAreaOfFocus,
    editArea,
    onAreaChange,
    cancelArea,
    isEditingLTA,
    longTermAspirations,
    cancelLTA,
    saveLTA,
    editLTA,
    onLTAChange
  } = props
  return (
    <>
      <div className="overview">
        <div className="section">
          <h3>
            Aspirations
            <div className="edit-focus">
              {isEditingLTA ? (
                <>
                  <Button
                    type="primary"
                    ghost
                    onClick={cancelLTA}
                    style={{ marginRight: 15 }}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" onClick={saveLTA}>
                    Save{' '}
                  </Button>
                </>
              ) : (
                <EditPanIcon onClick={editLTA} />
              )}
            </div>
          </h3>

          {isEditingLTA ? (
            <RichText
              value={longTermAspirations}
              onChange={onLTAChange}
              fontsizeOnly={true}
              toolbar={{
                options: ['fontSize', 'list'],
                list: {
                  options: ['unordered', 'ordered']
                }
              }}
            />
          ) : (
            <p
              dangerouslySetInnerHTML={{
                __html: person.profile.long_term_aspirations
                // &&
                // person.profile.long_term_aspirations.replace(
                //   /style="(.*?)"/g,
                //   ''
                // )
              }}
            />
          )}
        </div>
        <div className="section">
          <h3>
            Goals
            <div className="edit-focus">
              {isEditing ? (
                <>
                  <Button
                    type="primary"
                    ghost
                    onClick={cancelArea}
                    style={{ marginRight: 15 }}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" onClick={saveAreaOfFocus}>
                    Save{' '}
                  </Button>
                </>
              ) : (
                <EditPanIcon onClick={editArea} />
              )}
            </div>
          </h3>
          {isEditing ? (
            <RichText
              value={areasOfFocus}
              onChange={onAreaChange}
              fontsizeOnly={true}
              toolbar={{
                options: ['fontSize', 'list'],
                list: {
                  options: ['unordered', 'ordered']
                }
              }}
            />
          ) : (
            <p
              dangerouslySetInnerHTML={{
                __html: person.profile.areas_of_focus
              }}
            />
          )}
        </div>
      </div>
    </>
  )
}

const TeamGroupContainer = styled.div`
  display: flex;
  padding-right: 16px;
  .team-member {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 100%;
    font-size: 16px;
    color: white;
    line-height: 40px;
    text-align: center;
    margin-right: -16px;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
`

function TeamGroup(props) {
  const {
    alTeam: { client_advocate, concierge, performance_team_lead }
  } = props
  return (
    <TeamGroupContainer>
      {[
        { ...client_advocate, bgColor: '#264382', dutyName: 'CA' },
        { ...concierge, bgColor: '#365496', dutyName: 'C' },
        { ...performance_team_lead, bgColor: '#4C6AAB', dutyName: 'MEL' }
      ].map((item, index) => {
        const { name, avatar } = item
        const names =
          (name && name.split(' ').map((item) => item.substring(0, 1))) || []
        return (
          <div
            className="team-member"
            key={index}
            style={{ background: item.bgColor, zIndex: 3 - index }}
          >
            {avatar ? (
              <img src={avatar.oss_url} alt="avatar" />
            ) : name ? (
              <span>{names}</span>
            ) : (
              <span>{item.dutyName}</span>
            )}
          </div>
        )
      })}
    </TeamGroupContainer>
  )
}
