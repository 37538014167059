import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Divider, Button } from 'antd' // Progress
import ExerciseCard from './exerciseCard'
import ItemDetail from './itemDetail'
import ProgramItemEditor from './itemEditor'
import AiWorkoutEditor from './aiWorkoutEditor'  // Import the new component
// import { Start, Hourglass } from '../../../components/icons/program'
import Empty from '../../UI/status/empty'
// import PolygonAvatar from '../../UI/polygonAvatar'
// import SelectExperts from './selectExperts'
import DataStyle from '../style/programData.module.sass'
import EmptyImage from '../../../asserts/images/empty-status.png'
import _ from 'lodash'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { updateAdaptiveRecommendation, regenerateAdaptiveRecommendation } from '../../../api/adaptiveCoach'
import { SyncOutlined } from '@ant-design/icons'

class ProgramDataContainer extends Component {
  static propTypes = {
    summary: PropTypes.object,
    program: PropTypes.object,
    position: PropTypes.string,
    experts: PropTypes.array,
    changeExpert: PropTypes.func,
    releations: PropTypes.object,
    aiWorkoutsDate: PropTypes.string,
    aiWorkouts: PropTypes.array
  }

  constructor(props) {
    super(props)
    this.state = {
      showItemDetail: false,
      selectExercise: null,
      showExerciseEditor: false,
      updatedAiRecommendations: {},
      isDragging: false,
      updatingWorkouts: {},
      showAiEditor: false
    }
    
    this.dayRefs = []
  }

  getDateList = (weekRange, workouts) => {
    const { program } = this.props
    for (const workout of workouts) {
      // If it's an AI recommendation, treat it like a program item
      if (workout.isAiRecommendation) {
        workout.name = workout.workout_type
      }
      // Existing program item logic
      else if (workout.program_item_id) {
        const targetItem = program.items.find(
          (item) => item.id === workout.program_item_id
        )
        workout.name = targetItem && targetItem.name
      }
    }
    
    const { startDate, endDate } = weekRange
    const filterWorkouts = (targetDate) => {
      const filteredItems = workouts.filter(
        (item) =>
          moment(item.record_date.split('T')[0]).format('YYYY-MM-DD') ===
          targetDate.format('YYYY-MM-DD')
      )
      
      // Sort workouts to put sleep activities last
      return filteredItems.sort((a, b) => {
        // If a is a sleep activity and b is not, a comes after b
        if (a.isAiRecommendation && a.isSleepActivity && (!b.isAiRecommendation || !b.isSleepActivity)) {
          return 1
        }
        // If b is a sleep activity and a is not, b comes after a
        if (b.isAiRecommendation && b.isSleepActivity && (!a.isAiRecommendation || !a.isSleepActivity)) {
          return -1
        }
        // Otherwise, maintain original order
        return 0
      })
    }

    const list = []
    let currentDate = startDate.clone()

    while (currentDate.isSameOrBefore(endDate)) {
      const listItem = {
        date: currentDate,
        workouts: filterWorkouts(currentDate).map(workout => ({
          ...workout,
          // Set isWorkout=false for AI recommendations to treat them like program items
          isWorkout: workout.isAiRecommendation ? false : true
        }))
      }
      list.push(listItem)
      currentDate = currentDate.clone().add(1, 'days')
    }
    return list
  }

  showDivider = (workouts, showItems, expiredDate) => {
    const show =
      (workouts && workouts.length > 0) ||
      (showItems && showItems.length > 0 && !expiredDate)
    return show ? <Divider /> : null
  }

  handleAiRecommendationUpdate = (id, data) => {
    this.setState(prevState => ({
      updatedAiRecommendations: {
        ...prevState.updatedAiRecommendations,
        [id]: data
      }
    }), () => {
      this.props.editItemCallback()
    })
  }

  handleRegenerateWorkout = async (workout, event) => {
    // Stop event propagation to prevent card click
    if (event) {
      event.stopPropagation()
    }
    
    const { person, aiWorkoutsDate, editItemCallback } = this.props
    
    // Mark the workout as updating
    this.setState(prevState => ({
      updatingWorkouts: {
        ...prevState.updatingWorkouts,
        [workout.id]: true
      }
    }))
    
    try {
      // Call the API to regenerate this recommendation
      // You'll need to implement this API function in adaptiveCoach.js
      const result = await regenerateAdaptiveRecommendation(
        person.id,
        aiWorkoutsDate,
        workout.id
      )
      
      // Update local state with the new recommendation
      if (result && result.recommendation) {
        this.handleAiRecommendationUpdate(workout.id, result.recommendation)
      }
      
      // Notify parent component to refresh the UI
      if (editItemCallback) {
        editItemCallback()
      }
    } catch (error) {
      console.error('Error regenerating AI workout:', error)
    } finally {
      // Clear updating state
      this.setState(prevState => ({
        updatingWorkouts: {
          ...prevState.updatingWorkouts,
          [workout.id]: false
        }
      }))
    }
  }

  handleDragEnd = async (result) => {
    const { person, aiWorkoutsDate, editItemCallback, aiWorkouts } = this.props    
    // Reset dragging state
    this.setState({ isDragging: false })
    // If dropped outside a droppable area or dropped in the same place
    if (!result.destination) {
      console.info('No destination - drag cancelled or dropped outside droppable area')
      return
    }
    
    // Extract workout ID from draggableId
    const [workoutId, sourceIndex] = result.draggableId.split('-')    
    // Get source and destination day indexes
    const sourceDayId = result.source.droppableId
    const destinationDayId = result.destination.droppableId
    
    // If dropped in the same day, do nothing
    if (sourceDayId === destinationDayId) {
      console.info('Source and destination days are the same - no change needed')
      return
    } 
    // First, check if we have aiWorkouts available directly from props
    let targetWorkout = null
    // Try to find the workout directly in aiWorkouts first (more reliable)
    if (aiWorkouts && Array.isArray(aiWorkouts)) {
      targetWorkout = aiWorkouts.find(workout => workout.id === workoutId)
    }
    
    if (!targetWorkout) {
      console.warn('Target workout not found! workoutId:', workoutId)
      return
    }
    
    // Get the destination day's date
    const destinationDayIndex = parseInt(destinationDayId.replace('day-', ''), 10)
    const listDate = this.getDateList(this.props.weekRange, this.props.summary.workouts)
    const destinationDay = listDate[destinationDayIndex]

    if (!destinationDay) {
      console.warn('Destination day not found!')
      return
    }
    
    // Format the new date string
    const newDateStr = destinationDay.date.format('YYYY-MM-DD')
    
    // Ensure consistent date format extraction
    let currentDateStr
    if (typeof targetWorkout.record_date === 'string') {
      // Handle ISO string format (YYYY-MM-DDT00:00:00Z)
      currentDateStr = targetWorkout.record_date.split('T')[0]
    } else if (targetWorkout.record_date instanceof Date) {
      // Handle Date object
      currentDateStr = moment(targetWorkout.record_date).format('YYYY-MM-DD')
    } else {
      // Handle moment or other formats
      currentDateStr = moment(targetWorkout.record_date).format('YYYY-MM-DD')
    }

    // Only update if the date actually changed
    if (currentDateStr !== newDateStr) {
      try {
        // Create updated workout object with new date
        const updatedWorkout = {
          ...targetWorkout,
          day_of_week: destinationDayIndex
        }

        // Set updating state for this workout
        this.setState(prevState => ({
          updatingWorkouts: {
            ...prevState.updatingWorkouts,
            [targetWorkout.id]: true
          }
        }))
        
        this.handleAiRecommendationUpdate(targetWorkout.id, updatedWorkout)
        
        // Force a re-render of the component
        this.forceUpdate()
        
        // Then make the API call
        const result = await updateAdaptiveRecommendation(
          person.id,
          aiWorkoutsDate,
          targetWorkout.id,
          updatedWorkout
        )
        
        // Clear updating state for this workout
        this.setState(prevState => ({
          updatingWorkouts: {
            ...prevState.updatingWorkouts,
            [targetWorkout.id]: false
          }
        }))
        
        // Trigger parent component refresh AFTER the API call
        if (editItemCallback) {
          editItemCallback()
        }
      } catch (error) {
        console.error('Error updating AI workout date:', error)
        
        // Clear updating state for this workout
        this.setState(prevState => ({
          updatingWorkouts: {
            ...prevState.updatingWorkouts,
            [targetWorkout.id]: false
          }
        }))
        
        // If the API call fails, revert the local state update
        this.handleAiRecommendationUpdate(targetWorkout.id, targetWorkout)
        this.forceUpdate()
        
        // Trigger parent component refresh to revert UI
        if (editItemCallback) {
          editItemCallback()
        }
      }
    } else {
      console.info('Current date and new date are the same - no update needed')
    }
  }
  handleDragStart = () => {
    this.setState({ isDragging: true })
  }

  render() {
    const {
      summary,
      program,
      weekRange,
      person,
      editItemCallback,
      addAttachSync,
      removeAttachSync,
      editAttachTitleSync,
      modalContainerStyle,
      origin,
      position,
      expiredDate,
      itemDurationMapping,
      intensityRange,
      workoutTypes,
      aiWorkoutsDate
    } = this.props
    let { showExerciseEditor, selectExercise, showItemDetail, isDragging, updatingWorkouts, showAiEditor } = this.state
    const listDate = this.getDateList(weekRange, summary.workouts)
    this.listDate = listDate
    
    if (selectExercise && !selectExercise.record_date) {
      selectExercise = summary.program_items.find(
        (item) => item.id === selectExercise.id
      )
    }
    if (!summary && !program) {
      return <>program</>
    }
    let showItems =
      program &&
      program.items &&
      (position === 'clientView'
        ? summary.program_items.filter(
            (item) => item.status === 1 || item.published_at ///
          )
        : summary.program_items)

    const hasContent =
      (showItems && showItems.length > 0) ||
      (summary.workouts && summary.workouts.length > 0)

    return (
      <>
        {hasContent ? (
          <>
            {((program && program.status === 1) || origin === 'overview') &&
              summary.workouts &&
              summary.workouts.length > 0 && (
                <div className={DataStyle.exerciseContainer}>
                  <DragDropContext 
                    onDragEnd={this.handleDragEnd}
                    onDragStart={this.handleDragStart}
                  >
                    <div className={DataStyle.exerciseList}>
                      {listDate.map((item, dayIndex) => (
                        <Droppable 
                          droppableId={`day-${dayIndex}`} 
                          key={`day-${dayIndex}`}
                        >
                          {(provided, snapshot) => (
                            <div
                              className={`${DataStyle.exerciseItem} ${snapshot.isDraggingOver ? DataStyle.dragOver : ''}`}
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              <div className={DataStyle.title}>
                                {moment(item.date).format('ddd, MMM DD')}
                              </div>
                              
                              {item.workouts.map((workout, workoutIndex) => {
                                // For AI recommendations that should be draggable
                                if ((workout.isAiRecommendation && position !== 'clientView') || 
                                    (workout.isAiRecommendation && position === 'clientView' && workout.published)) {
                                  const isUpdating = updatingWorkouts[workout.id]
                                  return (
                                    <Draggable
                                      key={`workout-${workout.id}`}
                                      draggableId={`${workout.id}-${dayIndex}`}
                                      index={workoutIndex}
                                      isDragDisabled={isUpdating}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            ...provided.draggableProps.style,
                                            opacity: snapshot.isDragging ? 0.8 : 1
                                          }}
                                          className={`${snapshot.isDragging ? DataStyle.dragging : ''} ${isUpdating ? DataStyle.updating : ''}`}
                                        >
                                          {isUpdating && (
                                            <div className={DataStyle.updatingOverlay}>
                                              <span>Updating...</span>
                                            </div>
                                          )}
                                          <ExerciseCard
                                            isProgramActive={(program && program.status === 1) || false}
                                            hasProgram={!_.isNil(program)}
                                            width={139}
                                            position={position}
                                            exercise={workout}
                                            isWorkout={workout.isWorkout}
                                            isAiWorkout={workout.isAiRecommendation}
                                            onRefresh={(e) => {
                                              e.stopPropagation()
                                              this.handleRegenerateWorkout(workout, e)
                                            }}
                                            clickHandle={() => {
                                              if (!isDragging && !isUpdating) {
                                                this.setState({
                                                  showItemDetail: true,
                                                  selectExercise: workout
                                                })
                                              }
                                            }}
                                            items={(program && program.items) || []}
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                }
                                
                                // For regular, non-draggable workouts
                                return (
                                  <div key={`workout-${workout.id || workoutIndex}`}>
                                    <ExerciseCard
                                      isProgramActive={(program && program.status === 1) || false}
                                      hasProgram={!_.isNil(program)}
                                      width={139}
                                      position={position}
                                      exercise={workout}
                                      isWorkout={workout.isWorkout}
                                      isAiWorkout={workout.isAiRecommendation}
                                      onRefresh={workout.isAiRecommendation ? (e) => {
                                        e.stopPropagation()
                                        this.handleRegenerateWorkout(workout, e)
                                      } : undefined}
                                      clickHandle={() => {
                                        this.setState({
                                          showItemDetail: true,
                                          selectExercise: workout
                                        })
                                      }}
                                      items={(program && program.items) || []}
                                    />
                                  </div>
                                )
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      ))}
                    </div>
                  </DragDropContext>
                  
                  {showItems && showItems.length > 0 && !expiredDate && (
                    <Divider />
                  )}
                </div>
              )}

            {showItems && showItems.length > 0 && !expiredDate && (
              <div className={DataStyle.exerciseContainer}>
                <div className={DataStyle.title}>TO DO</div>
                <div className={`${DataStyle.exerciseList} ${DataStyle.todo}`}>
                  {showItems.map((item, index) => (
                    <ExerciseCard
                      isProgramActive={program.status === 1}
                      position={position}
                      key={`workout-${index}`}
                      exercise={item}
                      clickHandle={() =>
                        this.setState({
                          showItemDetail: true,
                          selectExercise: item
                        })
                      }
                    />
                  ))}
                </div>
              </div>
            )}
            {origin === 'overview' &&
              this.showDivider(summary.workouts, showItems, expiredDate)}
          </>
        ) : (
          <div className={DataStyle.emptyStatus}>
            <Empty
              message="There is no exercise action items yet."
              emptyImage={EmptyImage}
            />
            {position !== 'clientView' && origin !== 'overview' && (
              <Button
                type="primary"
                onClick={() => this.setState({ showExerciseEditor: true })}
              >
                {/* ADD EXERCISE */}
                ACTION ITEM
              </Button>
            )}
            {origin === 'overview' && <Divider />}
          </div>
        )}

        {showExerciseEditor && selectExercise && !selectExercise.isAiRecommendation && (
          <ProgramItemEditor
            isProgramActive={program && program.status === 1}
            workoutTypes={workoutTypes}
            cancel={() => this.setState({ showExerciseEditor: false })}
            itemDurationMapping={itemDurationMapping}
            position={position || origin}
            exercise={selectExercise}
            programsId={program && program.id}
            personId={person && person.id}
            confirmCallback={(updatedItem) => {
              let updatedSelectExercise = selectExercise
              
              if (showItemDetail && updatedItem && selectExercise && updatedItem.id === selectExercise.id) {
                updatedSelectExercise = updatedItem
              }
              
              this.setState({ 
                showExerciseEditor: false,
                selectExercise: updatedSelectExercise
              })
              
              editItemCallback(updatedItem)
            }}
            addAttachSync={addAttachSync}
            removeAttachSync={removeAttachSync}
            editAttachTitleSync={editAttachTitleSync}
            aiWorkoutsDate={undefined}
          />
        )}
        
        {showExerciseEditor && selectExercise && selectExercise.isAiRecommendation && (
          <AiWorkoutEditor
            exercise={selectExercise}
            personId={person && person.id}
            aiWorkoutsDate={aiWorkoutsDate}
            itemDurationMapping={itemDurationMapping}
            cancel={() => this.setState({ showExerciseEditor: false })}
            confirmCallback={(updatedItem) => {
              let updatedSelectExercise = selectExercise
              
              if (showItemDetail && updatedItem && selectExercise && updatedItem.id === selectExercise.id) {
                updatedSelectExercise = updatedItem
              }
              
              this.setState({ 
                showExerciseEditor: false,
                selectExercise: updatedSelectExercise
              })
              
              if (selectExercise && selectExercise.isAiRecommendation) {
                this.handleAiRecommendationUpdate(
                  selectExercise.id, 
                  updatedItem || selectExercise
                )
              }
              
              editItemCallback(updatedItem)
            }}
          />
        )}

        {showItemDetail && (
          <ItemDetail
            item={selectExercise}
            position={position}
            personId={person.id}
            programItems={(program && program.items) || []}
            modalContainerStyle={modalContainerStyle}
            itemDurationMapping={itemDurationMapping}
            intensityRange={intensityRange}
            closeHandle={() => this.setState({ showItemDetail: false })}
            operateHandle={(state) => this.setState(state)}
            deleteAsync={(workoutEdited) => {
              this.setState({ showItemDetail: false })
              if (selectExercise && selectExercise.isAiRecommendation) {
                this.handleAiRecommendationUpdate(selectExercise.id, null)
              }
              editItemCallback(workoutEdited)
            }}
            updateAiRecommendation={this.handleAiRecommendationUpdate}
            deleteAdaptiveRecommendation={this.handleAiRecommendationUpdate}
            aiWorkoutsDate={selectExercise && selectExercise.isAiRecommendation ? aiWorkoutsDate : undefined}
          />
        )}
      </>
    )
  }
}

export default ProgramDataContainer
