import React, { useEffect, useState } from 'react'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { Button, Modal, Progress, message } from 'antd'
import { remoteImgToBase64, timeout } from '../../../utils/common'
import moment from 'moment'
import axios from 'axios'
import { formDataRequest } from '../../../api/axios'

export const RenderImageUrl = ({ url }) => {
  const [data, setData] = useState()
  useEffect(() => {
    if (!url) return
    remoteImgToBase64(url).then((e) => {
      setData(e)
    })
  }, [url])
  return (
    <>
      {' '}
      {data && (
        <img
          style={{ borderRadius: 8, width: 212, height: 220 }}
          src={data}
          alt=""
        />
      )}{' '}
    </>
  )
}

const GenericPdfDownloader = ({
  ids,
  filename,
  person,
  className,
  uploadRequest,
  isPublished,
  reportId
}) => {
  const [disabled, setDisabled] = useState(false)
  const [percent, setPercent] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (uploadRequest) {
      setIsModalOpen(true)
      uploadPDF()
        .then(() => {
          setIsModalOpen(false)
          message.destroy()
          message.success('Upload successful!')
          setTimeout(() => {
            window.close()
          }, 500)
        })
        .catch(() => {
          setIsModalOpen(false)
          message.destroy()
          message.error('Fail to upload!')
        })
    }
  }, [uploadRequest])

  const uploadPDF = async () => {
    message.open({
      type: 'loading',
      content: 'PDF is creating...',
      duration: 0
    })
    await timeout(400)
    const pdf = await generatorPDF()
    message.destroy()
    message.open({
      type: 'loading',
      content: 'PDF is uploading...',
      duration: 0
    })
    const title =
      'Assessment Review' +
      moment().format('MM/DD/YYYY') +
      person.first_name.substr(0, 2).toUpperCase() +
      person.last_name.substr(0, 2).toUpperCase() +
      String(person.id).padStart(4, '0')
    const file = pdf.output('blob')
    const path = `/files/ar_deck?person_id=${
      person.id
    }&file_name=AssessmentReview_${person.id}_${new Date().getTime()}.pdf`
    const formData = new FormData()
    formData.append('file', file)
    formData.append('size', file.size)
    formData.set('title', title)
    formData.set('size', file.size)
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    await formDataRequest({
      method: 'post',
      path,
      data: formData,
      onBeforeUpload: () => {
        setPercent(0)
      },
      onUploadProgress: (process) => {
        const percent = (
          (process.loaded / process.total).toFixed(2) * 100
        ).toFixed(0)
        return setPercent(percent)
      },
      cancelSource: source
    })
  }

  const generatorPDF = async () => {
    const footer = document.getElementById('footer-preview')
    const avatar = document.getElementById('avatar')
    const saveASPDF = document.getElementById('saveASPDF')
    const copyClient = document.getElementById('copyClient')

    if (footer && footer.style) footer.style.display = 'none'
    if (saveASPDF && saveASPDF.style) saveASPDF.style.display = 'none'
    if (copyClient && copyClient.style) copyClient.style.display = 'none'
    if (avatar && avatar.style) avatar.style.opacity = '0'

    try {
      const sections = document.querySelectorAll('section')
      if (sections.length === 0) {
        console.error('No sections found in the DOM')
        return
      }

      const pdf = new jsPDF('p', 'px')
      const hideMessage = message.loading('Processing page 1...', 0)
      const firstThreeSections = Array.from(sections).slice(0, 3)
      const canvases = []
      for (const section of firstThreeSections) {
        const canvas = await html2canvas(section, {
          useCORS: true,
          imageTimeout: 60000,
          allowTaint: false,
          backgroundColor: '#FFFFFF',
          scale: 1
        })
        canvases.push(canvas)
      }

      const totalHeight = canvases.reduce(
        (sum, canvas) => sum + canvas.height,
        0
      )
      const combinedCanvas = document.createElement('canvas')
      combinedCanvas.width = canvases[0].width
      combinedCanvas.height = totalHeight

      const ctx = combinedCanvas.getContext('2d')
      let currentHeight = 0
      canvases.forEach((canvas) => {
        ctx.drawImage(canvas, 0, currentHeight)
        currentHeight += canvas.height
      })

      const combinedDataUrl = combinedCanvas.toDataURL('image/jpeg')
      pdf.internal.pageSize.setWidth(combinedCanvas.width)
      pdf.internal.pageSize.setHeight(totalHeight)
      pdf.addImage(
        combinedDataUrl,
        'JPEG',
        0,
        0,
        combinedCanvas.width,
        totalHeight
      )
      hideMessage()

      for (let i = 3; i < sections.length; i++) {
        const hidePageMessage = message.loading(`Processing page ${i - 1}...`, 0)
        const section = sections[i]

        const canvas = await html2canvas(section, {
          useCORS: true,
          imageTimeout: 60000,
          allowTaint: false,
          backgroundColor: '#FFFFFF',
          scale: 1
        })

        const canvasHeight = canvas.height
        const canvasWidth = canvas.width

        pdf.addPage()
        pdf.internal.pageSize.setWidth(canvasWidth)
        pdf.internal.pageSize.setHeight(canvasHeight)

        const sectionDataUrl = canvas.toDataURL('image/jpeg')
        pdf.addImage(sectionDataUrl, 'JPEG', 0, 0, canvasWidth, canvasHeight)

        hidePageMessage()
      }

      message.success('PDF generation completed!')

      if (footer && footer.style) footer.style.display = 'flex'
      if (avatar && avatar.style) avatar.style.opacity = '1'
      if (saveASPDF && saveASPDF.style) saveASPDF.style.display = 'block'
      if (copyClient && copyClient.style) copyClient.style.display = 'block'

      return pdf
    } catch (error) {
      console.error('Error generating PDF:', error)
      message.error('Error generating PDF. Please try again.')
    }
  }

  const downloadPdfDocument = async () => {
    setDisabled(true)
    // message.open({
    //   type: 'loading',
    //   content: 'PDF in progress..',
    //   duration: 0
    // })
    const doc = await generatorPDF()
    doc.save(filename)
    message.destroy()
    setDisabled(false)
  }

  const copyClientViewLink = () => {
    const clientViewLink = `${window.location.origin}/app/ass-report/preview/${reportId}`
    navigator.clipboard
      .writeText(clientViewLink)
      .then(() => message.success('Assessment Link Copied!'))
      .catch(() => message.error('Failed to copy Assessment link.'))
  }

  return (
    <>
      {isPublished && (
        <Button
          type="primary"
          disabled={disabled}
          className={className}
          style={{
            position: 'absolute',
            right: 150,
            top: 15,
            border: '1px solid #ffffff',
            paddingTop: '6px'
          }}
          onClick={copyClientViewLink}
          id="copyClient"
        >
          COPY CLIENT LINK
        </Button>
      )}
      <Button
        type="primary"
        style={{
          paddingTop: '6px'
        }}
        disabled={disabled}
        className={className}
        onClick={downloadPdfDocument}
        id="saveASPDF"
      >
        SAVE AS PDF
      </Button>
      <Modal
        title="Please don't close this page until the PDF upload is complete!"
        open={isModalOpen}
        closable={false}
        footer={null}
        width={800}
      >
        <Progress percent={percent} status="active" />
      </Modal>
    </>
  )
}

export default GenericPdfDownloader
