import React, { useState } from 'react'
import { Button } from 'antd'
import { ReactComponent as EditIcon } from '../../../asserts/icon/client/ic_edit.svg'
import EditableText from '../comp/EditableText'

export default function StepItem({
  editabled,
  field,
  title,
  placeholder,
  icon,
  color,
  value,
  onChange,
  isAIGenerated
}) {
  const [editing, setEditing] = useState(false)
  return (
    <div className="step-item">
      <div className="top-section">
        <div className="left-section">
          {icon}
          <div className="title" style={{ color, fontSize: isAIGenerated ? '14px' : undefined }}>
            {title}
          </div>
        </div>

        {!editing && editabled && (
          <Button type="text" onClick={() => setEditing(true)}>
            <EditIcon className="icon" />
          </Button>
        )}
      </div>

      <EditableText
        className="editor-section"
        value={value}
        editing={editing}
        placeholder={editabled ? placeholder : ''}
        setEditing={setEditing}
        onSave={(text) => onChange(text, field)}
      />
    </div>
  )
}
