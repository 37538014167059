import React, { useState } from 'react'
import _, { assign } from 'lodash'
import { animateScroll as scroll } from 'react-scroll'
import { Checkbox, Spin } from 'antd'
import BasicForm from '../BasicForm'
import HalfPageModal from '../UI/halfPageModal'
import Confirm from '../UI/confirmModal'
import styled from 'styled-components'
import { getHealthStatus, saveScoreBulk } from '../../api/healthScore'
import { getRangeAgeScopes } from '../../api/rangeAgeScope'
import Activities from './comp/activities'
import axios from 'axios'
import {
  createAR,
  getARDetail,
  createNote,
  updateNote,
  deleteNote,
  publish,
  notifyClient,
  update,
  getDynamicDataV2,
  createReferral,
  updateReferral

} from '../../api/assessmentReview'
import { RangeColors, healthCardList as list } from '../../utils/constant'
import { getRole } from '../../utils/common'
import moment from 'moment'
import { getClientProfileInfo, getMetrics } from '../../api'

export const ARStatus = {
  published: 1,
  draft: 0
}

export default (WrappedComponent) =>
  class Wrapper extends React.Component {
    state = {
      previous: { snapshot: {} },
      latest: { snapshot: {} },
      editingCount: 0,
      infinityLoadCount: 1,
      maxLoadCount: 8,
      hideSection: [],
      loadingAIRecommendations: false,
      showAILoadingMessage: false
    }

    initial = async () => {
      const {
        match,
        getPerson,
        match: {
          params: { id },
          url
        },
        history,
        setBreadcrumb
      } = this.props
      const person = await getPerson()
      // const hash = match && match.params && match.params.hash
      const personId = match && match.params && match.params.personId
      const loginRole = getRole()
      if (id === 'new') {
        try {
          const ar = await createAR(person.id)
          history.replace(url.replace('new', ar.id))
        } catch (err) {
          console.error(err.message)
          this.setState({ createdFailed: err.message })
        }
      } else if (Number(id)) {
        const [arDetail, trendMetrics, ageScopeData] = await Promise.all([
          getARDetail(id),
          getMetrics(person.id),
          getRangeAgeScopes()
        ])
        const ageScopes = Object.entries(ageScopeData.age_scope).map(
          ([k, v]) => ({
            tag: k,
            label: v.label,
            scope: v.scope
          })
        )
        arDetail.detail = arDetail.detail || { hideSection: [] }
        let dynamicData, originDynamicData
        if (arDetail.status === ARStatus.published) {
          // published
          dynamicData = arDetail.detail.dynamicData
          const {
            latest_bmi,
            latest_height,
            latest_weight,
            device_info,
            residence
          } = arDetail.detail
          this.setState({
            latest_bmi,
            latest_height,
            latest_weight,
            maxLoadCount: 1,
            device_info,
            residence
          })
        } else {
          const [originDynamicData, information] = await Promise.all([
            getDynamicDataV2(person.id, 1, id),
            getClientProfileInfo(personId || (person && person.id))
          ])

          const {
            healthSpanDomainSummary: { latest_height, latest_weight }
          } = originDynamicData
          const { device_info, residence } = information

          this.setState({
            latest_height,
            latest_weight,
            residence,
            device_info,
            loading: false
          })
          dynamicData = {
            ...originDynamicData
          }
        }
        setBreadcrumb &&
          setBreadcrumb(person, {
            title: moment(arDetail.time_period).format('MMM YYYY')
          })

        const AI_LAUNCH_DATE = new Date('2025-03-18')
        let reportPublishedDate = null

        if (arDetail && arDetail.published_at) {
          reportPublishedDate = new Date(arDetail.published_at)
        } else {
          console.warn('Warning: Published date is undefined!')
        }

        const isOldPublishedReport =
          reportPublishedDate && reportPublishedDate <= AI_LAUNCH_DATE

        // For old reports or errors, use this base state
        const baseState = {
          person,
          arDetail,
          dynamicData,
          loginRole,
          ageScopes,
          originDynamicData,
          trendMetrics: trendMetrics.filter(
            (item) => item.category === 'Blood Metrics'
          ),
          hideSection: arDetail.detail.hideSection || []
        }

        if (isOldPublishedReport) {
          // For old reports, just set the base state
          this.setState(baseState)
        } else {
          // We want to see if there is an AI recommendations if >= AI_LAUNCH_DATE
          let aiLoadingTimer // Declare the timer here so it's accessible in catch block
          try {
            // Add loading state for AI recommendations
            this.setState({ loadingAIRecommendations: true })
            aiLoadingTimer = setTimeout(() => {
              this.setState({ showAILoadingMessage: true })
            }, 10000) // 10 seconds

            const assessmentData = await axios.get(
              `${process.env.REACT_APP_ML_API_ENDPOINT}/api/ml/assessment_review/${person.id}/${id}`,
              { timeout: 300000 }
            )

            // Clear the timer and loading states
            clearTimeout(aiLoadingTimer)
            this.setState({ loadingAIRecommendations: false, showAILoadingMessage: false })

            this.setState({
              ...baseState,
              arDetail: {
                ...arDetail,
                detail: {
                  ...arDetail.detail,
                  // Add new fields from AssessmentReviewResponse
                  exerciseGapAnalysis: assessmentData.data.exercise_gap_analysis,
                  nutritionGapAnalysis: assessmentData.data.nutrition_gap_analysis,
                  sleepGapAnalysis: assessmentData.data.sleep_gap_analysis,
                  intro: assessmentData.data.intro,
                  nutritionProtocol: assessmentData.data.nutrition_protocol,
                  sleepProtocol: assessmentData.data.sleep_protocol,
                  exerciseProtocol: assessmentData.data.exercise_protocol,
                  expectations: assessmentData.data.expectations,
                  support: assessmentData.data.support,
                  hideSection: arDetail.detail.hideSection || []
                }
              },
              exerciseRecommendationPlots: {
                weekly_schedule: assessmentData.data.weekly_schedule,
                hsd_intensity: assessmentData.data.hsd_intensity
              },
              copilotDashboard: {
                activities: assessmentData.data.activities,
                allActivities: assessmentData.data.all_activities,
                heartRateZones: assessmentData.data.heart_rate_zones
              }
            })
          } catch (error) {
            // Clear the timer and loading states on error
            // this is for 404 error OR 500 error (really any error)
            if (aiLoadingTimer) {
              clearTimeout(aiLoadingTimer)
            }
            this.setState({ loadingAIRecommendations: false, showAILoadingMessage: false })
            console.error('Error fetching AI recommendations:', error)
            // Fall back to base state if AI recommendations fail
            this.setState(baseState)
          }
        }
      }
    }

    next = async () => {
      const {
        match: {
          params: { id }
        }
      } = this.props
      const { infinityLoadCount, dynamicData, arDetail } = this.state

      // Early return if status condition is met
      if (arDetail.status === 1) {
        this.setState((prevState) => ({
          infinityLoadCount: prevState.infinityLoadCount + 1
        }))
        return
      }

      const person = await this.props.getPerson()

      // Calculate the next level based on infinityLoadCount
      const nextLevel = infinityLoadCount + 1
      if (infinityLoadCount > 0 && infinityLoadCount < 8) {
        const result = await getDynamicDataV2(person.id, nextLevel, id)
        const updatedDynamicData = { ...dynamicData, ...result }

        // Update state once with both updated values
        this.setState((prevState) => ({
          infinityLoadCount: prevState.infinityLoadCount + 1,
          dynamicData: updatedDynamicData
        }))

        return updatedDynamicData
      }
    }

    reloadSection = async (section) => {
      const {
        match: {
          params: { id }
        }
      } = this.props
      const { dynamicData } = this.state

      const person = await this.props.getPerson()

      // Calculate the next level based on infinityLoadCount
      const result = await getDynamicDataV2(person.id, section, id)
      const updatedDynamicData = { ...dynamicData, ...result }

      this.setState((prevState) => ({
        dynamicData: updatedDynamicData
      }))

      return updatedDynamicData
    }

    handleConfirmPublish = async () => {
      this.setState({ isShowPublishConfirm: false, publishLoading: true })
      const { history } = this.props
      const {
        arDetail,
        latest,
        previous,
        latest_bmi,
        person,
        latest_height,
        latest_weight,
        infinityLoadCount,
        maxLoadCount,
        device_info,
        residence
      } = this.state

      let dynamicData = this.state.dynamicData

      // Reload section 2
      if (infinityLoadCount > 1) {
        dynamicData = await this.reloadSection(2)
      }

      if (infinityLoadCount < maxLoadCount) {
        for (let i = infinityLoadCount; i < maxLoadCount; i++) {
          dynamicData = await this.next()
        }
      }

      await update({
        id: arDetail.id,
        detail: _.assign(arDetail.detail || {}, {
          latest,
          previous,
          latest_bmi,
          dynamicData,
          latest_height,
          latest_weight,
          device_info,
          residence
        })
      })
      await publish(arDetail.id)
      this.setState({ publishLoading: false })
      /* transition： hide phase 2  */
      // this.setState({ isShowNotifyModal: true })
      const path = `/client/${person.id}/detail/ass-report/preview/${arDetail.id}`
      const { protocol, hostname, port } = window.location
      window.open(
        `${protocol}//${hostname}${port ? ':' + port : ''}${path}?savePDF=true`
      )
      history.push(path)
    }

    cancelNotify = () => {
      const { history } = this.props
      const { person } = this.state
      this.setState({ isShowNotifyModal: false })
      history.push(`/client/${person.id}/detail/ass-report`)
    }

    handleNotify = async (message) => {
      const { history } = this.props
      const { arDetail, person } = this.state
      await notifyClient(arDetail.id, message)
      history.push(`/client/${person.id}/detail/ass-report`)
    }

    handlePreview = () => {
      const { match, history } = this.props
      history.push(match.url.replace('draft', 'preview'))
    }

    handleSave = async (params, target) => {
      const { arDetail, person } = this.state
      if (!params) {
        // draft
        let url = `/client/${person.id}/detail/ass-report`
        if (target === 'edit') {
          url = `/client/${person.id}/detail/ass-report/draft/${arDetail.id}`
        }
        setTimeout(() => {
          this.props.history.push(url)
        }, 1000)
      } else {
        arDetail.detail = arDetail.detail || {}
        if (params.detail) {
          params.detail = _.assign(arDetail.detail, params.detail)
        }
        // params
        await update({
          id: arDetail.id,
          ...params
        })
      }
    }

    handleCommendDelete = async (id) => {
      const { arDetail } = this.state
      await deleteNote(id)
      arDetail.notes = arDetail.notes.filter((item) => item.id !== id)
      this.setState({ arDetail })
    }

    handleCommendSave = async (value, pillar, status) => {
      const { arDetail } = this.state
      let target = arDetail.notes.find((item) => item.pillar === pillar)
      if (status === 'delete') {
        return this.handleCommendDelete(target.id)
      }
      const params = {
        pillar,
        memo: value
      }
      if (target) {
        await updateNote(target.id, params)
        target = _.assign(target, params)
      } else {
        const { id } = await createNote(arDetail.id, params)
        arDetail.notes.push({
          id,
          ...params
        })
      }
      this.setState({ arDetail })
    }

    HandleSaveScore = async (details) => {
      const person_id = this.state.person.id
      await saveScoreBulk({
        details,
        person_id
      })
      const {
        results,
        latest_bmi,
        latest_height,
        latest_weight
      } = await getHealthStatus(person_id)
      this.setState({
        latest: results.filter((item) => item.graph_type === 'latest')[0],
        previous: results.filter((item) => item.graph_type === 'previous')[0],
        latest_bmi,
        latest_height,
        latest_weight
      })
    }

    jumpToDataset = (type) => {
      const {
        history,
        match: { url }
      } = this.props
      const types = {
        bio_marker: 'blood-test',
        body_comp: 'body-comp',
        lifestyle_assessment: 'life-style',
        cardiorespiratory: 'cardiorespiratory',
        cognitive_health: 'cognitive-health',
        physical_assessment: 'physical'
      }

      const path = types[type]

      if (path) {
        history.push(`${url.split('detail/')[0]}detail/${path}`)
      }
    }

    updateEditingCount = (increment) => {
      const { editingCount } = this.state
      this.setState({ editingCount: editingCount + increment })
    }

    hideSectionHandle = (section, oprType) => {
      let { hideSection } = this.state
      if (oprType === 'show') {
        _.remove(hideSection, (item) => item === section)
      } else {
        hideSection.push(section)
      }
      this.setState({ hideSection })
      this.handleSave({
        detail: {
          hideSection
        }
      })
    }

    handleUpdateReferral = async (referralId, params) => {
      let itemId = null
      if (referralId) {
        itemId = referralId
        await updateReferral(referralId, params)
      } else {
        const { id } = await createReferral(this.state.arDetail.id, params)
        itemId = id
      }
      const id = Number(this.props.match.params.id)
      const arDetail = await getARDetail(id)      
      // this.setState({ arDetail })
      return _.find(arDetail.referrals, { id: itemId })
    }

    componentDidMount() {
      this.initial()
    }

    componentDidUpdate(prevProps) {
      if (this.props.match.params.id !== prevProps.match.params.id) {
        this.initial()
      }
    }

    render() {
      const {
        isShowActivity,
        isShowNotifyModal,
        isShowPublishConfirm,
        showAILoadingMessage
      } = this.state
      const {
        match: {
          params: { id }
        }
      } = this.props
      const childProps = {
        ...this.state,
        list: _.cloneDeep(list),
        jumpToDataset: this.jumpToDataset, // maybe preview
        routeTypes:
          'bio_marker,body_comp,lifestyle_assessment,cardiorespiratory,cognitive_health,physical_assessment',
        handlePublish: () => this.setState({ isShowPublishConfirm: true }),
        handlePreview: this.handlePreview,
        handleSave: this.handleSave,
        handleCommendSave: this.handleCommendSave,
        HandleSaveScore: this.HandleSaveScore,
        updateEditingCount: this.updateEditingCount,
        showActivity: () => {
          this.setState({ isShowActivity: true })
          scroll.scrollToTop({ ignoreCancelEvents: true, smooth: true })
        },
        handleNotify: () => this.setState({ isShowNotifyModal: true }),
        next: this.next,
        hideSectionHandle: this.hideSectionHandle,
        onUpdateReferral: this.handleUpdateReferral,
        updateArDetail: (arDetail) => this.setState({ arDetail })
      }
      // handleConfirmPublish
      return (
        <Spin spinning={!!this.state.publishLoading}>
          {showAILoadingMessage && (
            <div style={{ 
              position: 'fixed', 
              top: '50%', 
              left: '50%', 
              transform: 'translate(-50%, -50%)',
              zIndex: 1000,
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              padding: '20px',
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
            }}>
              <Spin tip="Generating AI recommendations... will take ~3 minutes" />
            </div>
          )}
          <WrappedComponent {...childProps} {...this.props} />
          {isShowActivity && (
            <HalfPageModal
              position="right"
              close={() => this.setState({ isShowActivity: false })}
              title="Activity"
              // containerStyle={{
              //   margin: '-30px 30px 0 30px',
              //   minHeight: 'calc(100% + 30px)' //'calc(100vh - 120px + 30px)' //'calc(100vh - 120px)'
              //   // height: 'calc(100% + 30px)'
              // }}
            >
              <Activities id={id} />
            </HalfPageModal>
          )}
          {isShowNotifyModal && (
            <NotifyModal
              onCancel={this.cancelNotify}
              handleNotify={this.handleNotify}
            />
          )}
          {isShowPublishConfirm && (
            <Confirm
              {...{
                modalWidth: 600,
                onCancel: () => this.setState({ isShowPublishConfirm: false }),
                title: 'Publish Comprehensive Report confirmation',
                description:
                  "Are you sure you want to publish and upload this PDF to this client's Files?",
                confirmText: 'PUBLISH',
                onConfirm: this.handleConfirmPublish
              }}
            />
          )}
        </Spin>
      )
    }
  }

const NotifyContainer = styled.div`
  .check-box {
    margin: 20px 0;
  }
  .form-container {
    position: relative;
    margin-bottom: -35px;
    .form-tip {
      position: absolute;
      color: #8d99a5;
      font-size: 12px;
      letter-spacing: 0.3px;
      right: 10px;
      margin-top: -52px;
    }
    textarea {
      min-height: 100px;
      padding-bottom: 25px;
    }
  }
`

function NotifyModal(props) {
  const [loading, setLoading] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [customMessage, setCustomMessage] = useState('')
  // let basicFormRef
  return (
    <Confirm
      {...{
        onCancel: props.onCancel,
        description: 'Do you want to notify the client?',
        title: 'Notify client',
        cancelText: "DON'T NOTIFY",
        confirmText: 'NOTIFY CLIENT',
        modalWidth: 600,
        onConfirm: async () => {
          setLoading(true)
          await props.handleNotify(customMessage)
          setLoading(false)
        },
        disabled: loading,
        loading: loading
      }}
    >
      <NotifyContainer>
        <div className="check-box">
          <Checkbox
            checked={showForm}
            onChange={(e) => setShowForm(e.target.checked)}
          >
            Custom message
          </Checkbox>
        </div>
        {showForm && (
          <div className="form-container">
            <BasicForm
              formData={[
                {
                  type: 'textarea',
                  label: 'MESSAGE',
                  dbField: 'message',
                  extraAttr: {
                    maxLength: 138,
                    autoSize: { minRows: 2 }
                  },
                  rules: [{ required: true, message: 'This field is required' }]
                }
              ]}
              onChange={(changedFields) => {
                const message = changedFields.message.value
                setCustomMessage(message)
              }}
              // wrappedComponentRef={(inst) => (basicFormRef = inst)}
            />
            <span className="form-tip">
              {customMessage.length}/138 character left
            </span>
          </div>
        )}
      </NotifyContainer>
    </Confirm>
  )
}

export function handleMetricRanges(ranges = [], person, ageScopes) {
  const gender = person.profile.gender
  const age = moment().diff(person.profile.date_of_birth, 'years')
  const targetScope = ageScopes.find(({ scope: [min, max] }) => {
    if (age >= min && age <= max) return true
    return false
  })

  const ageTag = targetScope && targetScope.tag

  let _ranges = _.cloneDeep(ranges)
  try {
    _ranges = ranges
      .map((range) => ({
        scope:
          range[`${gender}_age_scope`] && range[`${gender}_age_scope`][ageTag],
        name: range.name
      }))
      .filter((range) => range)
      .map(({ scope, name }) => {
        const [min, max, color] = scope
        return {
          min: min,
          max: max,
          color: RangeColors[color],
          name
        }
      })
      .sort((a, b) => (a.max || Number.MAX_VALUE) - (b.max || Number.MAX_VALUE))
  } catch (err) {
    _ranges = ranges
      .sort((a, b) => a.order - b.order)
      .map((range) => {
        range.color =
          range[`${gender}_age_scope`][ageTag] &&
          RangeColors[range[`${gender}_age_scope`][ageTag][2]]
        return range
      })
  }
  return _ranges
}
